import React from "react";
import baseStyles from "styles/auth/login.module.css";

const SignupCompleted:React.FC = () => {
 
  return (
    <div className={baseStyles.authContainer}>
      <h2>Thanks, we’re verifying your details</h2>
      <p className={baseStyles.text}>This might take about 5 minutes, but can sometimes take a few days.</p>
      <p className={baseStyles.text}>We’ll email you as soon as your account is ready.</p>
      <form className={baseStyles.form}>
        <div className={baseStyles.button}>
          <a href="/auth/login">Got it</a>
        </div>
      </form>
    </div>
  );
};

export default SignupCompleted;
