import React from "react";
import styles from '../../styles/company/intro.module.css'
import icon from '../../images/ourMissionLogo.svg';
import "animate.css";

export const Intro = () => {
  return (
    <div>
      <div className={styles.headerBg}>
        <h2 className={`${styles.h2} ${"animate__animated animate__zoomIn"}`}>About TruexGold</h2>
        <p data-aos="fade-up" data-aos-duration="1500" className={styles.p}>The crypto app that works for you.</p>
      </div>

      <div className={styles.aboutText}>
          <p data-aos="fade-up" data-aos-duration="1000">
          TruexGold is an easy-to-use cryptocurrency investment app for
          everyone. We make it safe and simple to explore, buy, and store
          cryptocurrency - no prior experience is required. Our mission is to
          give everyone access to the power of cryptocurrency.
          </p>
          <br/>
          <p data-aos="fade-up" data-aos-duration="1000">This entails cutting through the hype and assisting you in making your
          own decision. We simplify complex concepts and never use jargon. We're
          here to answer your questions, as there's a lot to take in. To be
          fair, a financial system must be understood by all. 
          </p>
          <br />
          <p data-aos="fade-up" data-aos-duration="1000">This involves working to make cryptocurrency truly inclusive. Over 10
          million people have chosen to invest in cryptocurrency with TruexGold
          so far. We have a presence in over 40 markets across North America,
          Africa, and Europe. 
          </p>
          <br />
          <p data-aos="fade-up" data-aos-duration="1000">That always includes ensuring the safety of our customers. We
          collaborate with regulators worldwide, and all of the cryptocurrency
          we store for you is audited by independent auditors. We understand
          that transformative ideas require time and trust to mature. We're in
          it for the long haul, and we'd love to have you along for the ride.
        </p>
      </div>

      <div className={styles.missionContainer}>
        <div className={styles.missionWrapper}>
          <div className={styles.missionHeader}>
            <h3 data-aos="fade-up" data-aos-duration="1000">Our <span className={styles.mission}>mission</span></h3>
            <div data-aos="fade-left" data-aos-duration="1000">
              <img className={styles.missionIcon} src={icon} alt="" />
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1500">
            Our mission is to empower billions of people around the world by
            transitioning to a more open, efficient, and inclusive financial
            system. To accomplish this, we created a platform that makes
            cryptocurrencies like Bitcoin and Ethereum easily accessible to
            everyone - whoever and wherever you are.
          </p>
        </div>
      </div>
    </div>
  );
};
