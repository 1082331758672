import React from 'react';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import wallet from 'images/dashboard/wallet.svg';

export const AddMoney: React.FC = () => {
  return (
    <div style={{ width: '100%'}}>
      <PrimaryPageLayout
        title={"Let's add money first"}
        desc={'To buy cryptocurrency you’ll need to top up your wallet with money'}
        icon={wallet}
        linkText={'Top up'}
        linkPath={'/dashboard/top_up'}
      ></PrimaryPageLayout>
    </div>
  );
};

export default AddMoney;
