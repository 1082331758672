import axios from 'axios';

//axios.defaults.withCredentials = true;

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(';').shift();
  }
};

/*
const xsrfToken = getCookie('XSRF-TOKEN');

axios.defaults.headers.common['X-XSRF-TOKEN'] = xsrfToken;
console.log('xsrfToken: ' + xsrfToken);
*/

const truexgoldRootAPI = axios.create({
  baseURL: `https://api.truexgold.app/`,
});

const getCSRFToken = () => {
  const name = 'XSRF-TOKEN';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(';').shift();
  }
  return null;
};

const truexgoldAPI = axios.create({
  baseURL: `https://api.truexgold.app/api/v1/`,
  withCredentials: true,
});

truexgoldAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

truexgoldAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem('ACCESS_TOKEN');
      // window.location.reload();
    } else if (response.status === 404) {
      //Show not found
    }

    throw error;
  }
);

export { truexgoldAPI, truexgoldRootAPI };
