import React from "react";
// import { Outlet } from "react-router-dom";

const Wallet = () => {
  return (
    <p
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 100,
      }}
    >
      Wallet Page
      {/* <Outlet /> */}
    </p>
  );
};
export default Wallet;
