import React from "react";
import styles from "../../styles/features/feature3.module.css";

export const Feature3 = () => {

  return (

      <section className={styles.feature3section}>
        <div className={styles.feature3Heading}>
          <h2 data-aos="fade-down" data-aos-duration="1000">Earn and Save</h2>
          <p data-aos="fade-up" data-aos-duration="500" className={styles.headingSubTextWhite}>
            Securely store your assets on the platform and receive substantial
            returns over time.
          </p>
        </div><br/>
        <div className={styles.feature3TextContainer}>
          <div data-aos="fade-right" data-aos-duration="1000" className={styles.feature3Image}></div>
          <div data-aos="fade-up" data-aos-duration="1000" className={styles.feature3TextWrapper}>
            <h5>Grow your wealth with TruexGOLD Savings</h5><br/>
            <p>
              TruexGold Savings makes it easier than ever to save for the
              future. Earn up to 7.6% per year in cryptocurrency with no fixed
              terms, no hidden fees, and unlimited deposits and withdrawals.
              <br /><br/>
              TruexGold Savings is available for Bitcoin, Ethereum, and
              USDC.Passive income? Monthly payments? It's your chance to make
              money, so get started right away.
            </p>
          </div>
        </div>
      </section>

  );
};
