import React, {useState, useEffect} from "react";
import styles from "styles/exchange/pricechart.module.css";
import {coinsService} from 'services/coinsService';
import priceChart from 'images/priceChart.svg';

export const Pricechart = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [coinData, setCoinData] = useState<any[]>([]);


  useEffect(() => {
    getCoinsData();
  }, []);

  const getCoinsData = async () => {
    setLoading(true);
    const req = await coinsService.getCoins();
    const res = req.data;
    // console.log(res);

    const coins: { id: string; lastPrice: number; _24hChange: number; _24hVolume: number; _24hHigh: number; _24hLow: number; }[] = []

    // res.map((coin: any, index: any) => {
    //   return(
    //     coins.push(
    //       {
    //         id:coin.id,
    //         lastPrice: coin.current_price,
    //         _24hChange: coin.price_change_percentage_24h,
    //         _24hVolume: coin.total_volume,
    //         _24hHigh: coin.high_24h,
    //         _24hLow: coin.low_24h,
    //       }
    //     )
    //   );
    // });
    // console.log(coins);
    setCoinData(coins);
    setLoading(false);
    // console.log(coinData);
  

      // return coinData;
  };

  // console.log(coinData);

  return (
    <div className={styles.chartContainer}>
      <div className={styles.priceHeading}>
        <h2 data-aos="fade-up" data-aos-duration="1000">Your local markets</h2>
        <p data-aos="fade-up" data-aos-duration="1500">Trading across 25 pairs including these local markets</p>
      </div>


      {/* <div data-aos="fade-up" data-aos-duration="1000" className={styles.priceContainer}>
        <img style={{width: "80%"}} src={priceChart} alt="" /> */}
        {/* <div className={styles.priceWrapper}>
          <div className={styles.marketDataHeadings}>
            <h6 className={styles.headings}>Your markets</h6>
            <h6 className={styles.headings}>Last price</h6>
            <h6 className={styles.headings}>24h change</h6>
            <h6 className={styles.headings}>24h Volume</h6>
            <h6 className={styles.headings}>24h high</h6>
            <h6 className={styles.headings}>24h low</h6>
          </div>
          {coinData.map((coin, index) => (
            // return(
              <div>
                <p>coin.lastPrice</p>
              </div>
            // );
          ))} */}
          {/* <div className={styles.marketDataHeadings}>
            <h6 className={styles.headings}>Your markets</h6>
            <h6 className={styles.headings}>Last price</h6>
            <h6 className={styles.headings}>24h change</h6>
            <h6 className={styles.headings}>24h Volume</h6>
            <h6 className={styles.headings}>24h high</h6>
            <h6 className={styles.headings}>24h low</h6>
          </div> */}
        {/* </div> */}
      {/* </div> */}
    </div>
  );
};
