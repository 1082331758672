import { authService } from 'services/authService';
import { User } from 'interfaces/User';
import { NavigateFunction } from 'react-router-dom';
import { notify } from 'utils/index';

interface VerifyContactOptions {
  user: User;
  setShowOverlay: (show: boolean) => void;
  navigate: NavigateFunction;
  setUseremail?: (email: string) => void;
  setUserphone?: (phone: string) => void;
  setErrorText?: (text: string) => void;
}

export const verifyUserContactInfo = async ({
  user,
  setShowOverlay,
  navigate,
  setUseremail = () => {},
  setUserphone = () => {},
  setErrorText = () => {},
}: VerifyContactOptions) => {
  // Check if the user's email is not verified
  if (user?.profile.is_complete) {
    navigate('/dashboard/home');
    return;
  }
  if (!user.email_verified_at) {
    setShowOverlay(true);
    try {
      setUseremail(user.email);
      // Send verification email
      const resendEmailResponse = await authService.resendEmailCode({ email: user.email });
      const emailResp = resendEmailResponse?.data;
      if (emailResp && emailResp.data && emailResp.data.success) {
        notify.success('Email verification code sent.');
      }
    } catch (error) {
      console.error('Error resending email code:', error);
      setErrorText('Failed to resend verification email.');
    } finally {
      console.log('verification finally step reached');
      navigate('/auth/verify_email');
    }
    return;
  }

  // Check if the user's phone is not verified

  /*if (false) {
      if (!user.phone_verified_at) {
        if (!user.phone) {
          // Navigate to enter phone number if phone is empty
          navigate('/auth/enter_phone');
        } else {
          setShowOverlay(true);
          try {
            setUserphone(user.phone);
            // Send verification SMS
            const resendPhoneResponse = await authService.resendPhoneCode({ phone: user.phone });
            const phoneResp = resendPhoneResponse?.data;
            if (phoneResp && phoneResp.data && phoneResp.data.success) {
              notify.success('Phone verification code sent.');
            } else {
              notify.error('Phone verification code NOT sent.');
            }
          } catch (error: any) {
            setShowOverlay(false);

            if (error.response) {
              if (error.response && error.response.status) {
                const status = error.response.status;
                const errorMessage = error.response.data.message || 'An error occurred';
                // Handling specific error codes
                if (status === 422) {
                  setErrorText(errorMessage);
                } else if (status === 403) {
                  setErrorText(errorMessage);
                } else {
                  setErrorText('An error occurred. Please try again later.');
                  notify.error('An error occurred. Please try again later.');
                }
              } else {
                setErrorText('An error occurred. Please try again later.');
                notify.error('An error occurred. Please try again later.');
              }
            } else if (error.request) {
              // The request was made, but no response was received (network issues, CORS, etc.)
              console.log('No response received:', error.request);
              setErrorText('No response from the server. Please check your network or try again later.');
              notify.error('No response from the server. Please check your network or try again later.');
            } else {
              // Something else happened in setting up the request
              console.log('Error:', error.message);
              setErrorText('An unexpected error occurred. Please try again later.');
              notify.error('An unexpected error occurred. Please try again later.');
            }
            console.error('Error resending phone code:', error);
            setErrorText('Failed to resend verification phone code.');
          } finally {
            console.log('verification finally step reached');
            navigate('/auth/verify_phone');
          }
        }
        return;
      }
    }*/
  if (user?.country === null) {
    navigate('/auth/country_setup');
    console.log('country is null');
    return;
  }

  if (user && !user.is_pinned) {
    navigate('/auth/create_pin');
    return;
  }

  navigate('/dashboard/home');
};
