import React from 'react';
import baseStyles from 'styles/dashboard/enterCardDetails.module.css';
import styles from 'styles/dashboard/sellIndex.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';


export const SendIndex: React.FC = () => {

  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'WHow much bitcoin would you like to send?'}
        linkText={'Next'}
        linkPath={'/dashboard/send'}
      >
        <div className={baseStyles.content}>
          <div className={baseStyles.inputWrapper}>
            <div className={`${baseStyles.halfInput} ${styles.halfInput}`}>
              <div className={styles.currencyInput}>
                <PrimaryInput label="Currency" value="NGN" InputLabelProps={{ shrink: true }} />
              </div>
              <div className={styles.input2}>
                <PrimaryInput label="Amount" placeholder="NGN 100.00" />
              </div>
            </div>
            <div className={baseStyles.input}>
                <PrimaryInput label="Amount" placeholder="NGN 100.00" />
              </div>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default SendIndex;
