import React from "react";
import styles from "styles/coins/coinHeader.module.css";

type Props = {
  coinName: string;
  coinAbbrv: string;
  coinActivity: any;
};

export const CoinHeader: React.FC<Props> = (props) => {
  const { coinName, coinAbbrv, coinActivity } = props;

  return (
    <div className={styles.container}>
      <div className={styles.priceHeading}>
        <div className={styles.content}>
          <img className={styles.img} src={coinActivity} alt={`${coinName}-price-activity`}/>
        </div>
      </div>

      <div className={styles.heading}>
        <h2>{coinName} Price</h2>
        <p>{coinAbbrv} to NGN</p>
      </div>
    </div>
  );
};