import React from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/extraDetails.module.css";
import userIcon from "images/userIcon.svg";
import idIcon from "images/idIcon.svg";
import addressIcon from "images/addressIcon.svg";
import cameraIcon from "images/cameraIcon.svg";

const ExtraDetails:React.FC = () => {
  const details = [
    { id: 1, icon: userIcon, text: "Add your personal details" },
    { id: 2, icon: addressIcon, text: "Home address and job details" },
    { id: 3, icon: idIcon, text: "Take a picture of your ID" },
    { id: 4, icon: cameraIcon, text: "Take a quick selfie" },
  ];

  return (
    <div className={baseStyles.authContainer}>
      <h2>Just a few checks to keep you safe</h2>
      <p className={baseStyles.text}>
        We need to do a few checks before you can buy cryptocurrency. It usually
        takes a few minutes to verify your details, but can take longer.
      </p>

      <form className={`${baseStyles.form} ${styles.form}`}>
        <div className={styles.detailsContainer}>
          {details.map((detail, index) => (
            <div key={index.toString()} className={styles.detailsWrapper}>
              <img src={detail.icon} alt="icon" className={styles.icon} />
              <p>{detail.text}</p>
            </div>
          ))}
        </div>

        <div className={`${baseStyles.button} ${styles.button}`}>
          <a href="/auth/personal_details">Next</a>
        </div>
      </form>
    </div>
  );
};

export default ExtraDetails;
