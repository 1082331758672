import styles from "styles/exchange/exFeatures.module.css";
import { Link } from 'react-router-dom';
import apiIcon from 'images/apiImg.svg';
import walletIcon from 'images/walletImg.svg';
import securityIcon from 'images/securityImg.svg';

export const ExchangeFeatures = () => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.priceHeading}>
        <h2 data-aos="fade-up" data-aos-duration="1000">Trading with TruexGold</h2>
        <p data-aos="fade-up" data-aos-duration="1500">Dynamic cryptocurrency that puts you in the driver's seat</p>
      </div>

      <div className={styles.featuresContainer}>
        <div data-aos="fade-up" data-aos-duration="800" className={styles.featuresWrapper}>
          <div data-aos="fade-right" data-aos-duration="1000" className={styles.featureIcon}>
            <img className={styles.icon} src={apiIcon} alt="Icon" />
          </div>
          <div className={styles.featureText}>
            <h4  data-aos="zoom-in" data-aos-duration="1000">API</h4>
            <p data-aos="fade-up" data-aos-duration="1000" className={styles.featureDesc}>The TruexGold API makes it simple to integrate into the Exchange platform, allowing you to manage your account, conduct trades, and receive market data programmatically.</p>
          </div>
          <Link data-aos="zoom-in" data-aos-duration="1000" className={styles.link} to="/">Explore the API</Link>
        </div>

        <div data-aos="fade-up" data-aos-duration="800" className={styles.featuresWrapper}>
          <div data-aos="fade-right" data-aos-duration="1000" className={styles.featureIcon}>
            <img className={styles.icon} src={walletIcon} alt="Icon" />
          </div>
          <div className={styles.featureText}>
            <h4  data-aos="zoom-in" data-aos-duration="1000">Fees</h4>
            <p data-aos="fade-up" data-aos-duration="1000" className={styles.featureDesc}>Get rewarded as you trade more.TruexGold has a dynamic, tiered pricing structure that is highly competitive and meticulously structured to ensure that you pay lesser costs based on your gross trading volume.</p>
          </div>
          <Link data-aos="zoom-in" data-aos-duration="1000" className={styles.link} to="/">See our fees</Link>
        </div>

        <div data-aos="fade-up" data-aos-duration="800" className={styles.featuresWrapper}>
          <div data-aos="fade-right" data-aos-duration="1000" className={styles.featureIcon}>
            <img className={styles.icon} src={securityIcon} alt="Icon" />
          </div>
          <div className={styles.featureText}>
            <h4  data-aos="zoom-in" data-aos-duration="1000">Security</h4>
            <p data-aos="fade-up" data-aos-duration="1000" className={styles.featureDesc}>TruexGold's industry-leading security mechanisms work to keep your crypto safe. TruexGold keeps 95% of their cryptocurrency in "deep freeze" wallets, which are multi-signature wallets protected by multiple layers of encryption.</p>
          </div>
          <Link data-aos="zoom-in" data-aos-duration="1000" className={styles.link} to="/">Learn More</Link>
        </div>
      </div>
    </div>
  );
};
