import React from "react";
import { Link } from 'react-router-dom';
import styles from "../../styles/footer/footer.module.css";
import facebookIcon from "../../images/facebookIcon.svg";
import twitterIcon from "../../images/twitterIcon.svg";
import linkedinIcon from "../../images/linkedinIcon.svg";
import { DownloadButtons } from "../buttons/buttons";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTextWrapper}>
        <h2 data-aos="fade-up" data-aos-duration="1000" className={styles.h2}>It's never too late to get started</h2>
        <p data-aos="fade-up" data-aos-duration="1500" className={styles.footerText}>
          Buy, store and learn about Bitcoin, Ethereum, Litecoin and Dash now
        </p>
        <div className={styles.downloadButtons}>
          <DownloadButtons/>
        </div>
      </div>
      <div className={styles.footerLinksContainer}>
        <div className={styles.footerLinks}>
          <div data-aos="fade-right" data-aos-duration="1000" className={styles.footerLogo}></div>
          <div className={styles.socialLinksContainer}>
            <a data-aos="fade-left" data-aos-duration="1000" href="https://web.facebook.com/?_rdc=1&_rdr" target="_blank" rel="noreferrer" className={styles.socialLinksWrapper}>
              <img
                className={styles.socialLinks}
                src={facebookIcon}
                alt="facebookIcon"
              ></img>
            </a>
            <a data-aos="fade-left" data-aos-duration="1300" href="https://twitter.com/" target="_blank" rel="noreferrer" className={styles.socialLinksWrapper}>
              <img
                className={styles.socialLinks}
                src={twitterIcon}
                alt="twitterIcon"
              ></img>
            </a>
            <a data-aos="fade-left" data-aos-duration="1600" href="https://www.linkedin.com/" target="_blank" rel="noreferrer" className={styles.socialLinksWrapper}>
              <img
                className={styles.socialLinks}
                src={linkedinIcon}
                alt="linkedinIcon"
              ></img>
            </a>
          </div>
        </div>
        <div className={styles.siteLinks}>
          <div data-aos="fade-up" data-aos-duration="1000" className={styles.siteLinksWrapper}>
            <h4>Resources</h4>
            <ul>
              <li><Link to="/" className={styles.links}>Our Process</Link></li>
              <li><Link to="/" className={styles.links}>Member Stories</Link></li>
              <li><Link to="/" className={styles.links}>Video</Link></li>
              <li><Link to="/" className={styles.links}>Free Trial</Link></li>
            </ul>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className={styles.siteLinksWrapper}>
            <h4>Company</h4>
            <ul>
              <li><Link to="/" className={styles.links}>Patnerships</Link></li>
              <li><Link to="/" className={styles.links}>Terms of Use</Link></li>
              <li><Link to="/" className={styles.links}>Privacy</Link></li>
              <li><Link to="/" className={styles.links}>Sitemap</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
