import React, { useState } from 'react';
import TextField from '@mui/material/TextField/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from '../../styles/input/primaryInput.module.css';
import IconButton from '@mui/material/IconButton';
import { IoEye, IoEyeOff } from 'react-icons/io5';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: '#ffffff !important',
            height: '40px',
            padding: '0 10px',
            margin: '5px 0 8px',
            flexDirection: 'row',
          },
          '& .MuiInputLabel-root': {
            color: '#ffffff !important',
            fontFamily: `'Poppins', sans-serif`,
            letterSpacing: '-.8px',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              color: '#ffffff !important',
              borderColor: '#ffffff !important',
            },
            '& input': {
              color: 'white',
              '&:-webkit-autofill': {
                // WebkitBoxShadow: "0 0 0 20px #070630 inset",
                // width: '90%',
                background: 'none !important',
                backgroundColor: '#00000000 !important',
              },
            },
          },
          '&:hover fieldset': {
            borderColor: '#ffffff !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff !important',
          },
          '&.MuiIconButton-root': {
            color: '#fff',
          },
        },
      },
    },
  },
});

type Props = {
  textFieldStyle?: any;
};

export const PasswordInput: React.FC<Props & TextFieldProps> = ({ textFieldStyle, ...props }) => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };


  return (
    <div>
      <ThemeProvider theme={theme}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          {...props}
          type={passwordVisibility ? 'text' : 'password'}
          InputProps={{
            endAdornment:
              <IconButton aria-label="toggle password visibility" onClick={handlePasswordVisibility} edge="end">
                {passwordVisibility ? <IoEyeOff className={styles.passwordIcon} /> : <IoEye  className={styles.passwordIcon}/>}
              </IconButton>
          }}
          className={`${styles.input} ${textFieldStyle}`}
        />
      </ThemeProvider>
    </div>
  );
};
