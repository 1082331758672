import React, { useState } from 'react';
import styles from 'styles/dashboard/p2p.module.css';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from 'components/input/SearchInput';
import filterIcon from 'images/dashboard/filterIcon.svg';
import { ListDropdown } from 'components/dropdown/HeaderDropdown';
import { TransactionCard } from 'components/dashboard/TransactionCard';
import { TransactionType } from 'interfaces/TransactionType';
import { MdOutlineClose } from 'react-icons/md';

const tabs = ['USDT', 'BTC', 'XRP', 'ETH', 'LINK'];
const currencies = ['USDT', 'BTC', 'XRP', 'ETH', 'LINK'];
const regions = ['Lagos', 'FCT Abuja', 'Port-Harcourt', 'Kano', 'Abia'];
const paymentMethods = ['Bank Transfer', 'Card', 'In-App'];

export const DashboardP2P: React.FC = () => {
  const navigate = useNavigate();
  const [showBuy, setShowBuy] = useState<boolean>(true);
  const [showSell, setShowSell] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [filterActive, setFilterActive] = useState<boolean>(false);

  const handleBuy = () => {
    setShowBuy(true);
    setShowSell(false);
  };

  const handleSell = () => {
    setShowSell(true);
    setShowBuy(false);
  };

  const buyTransactions = [
    {
      id: 1,
      name: 'TruexGold',
      numberOfTrades: 100,
      successRate: 80,
      currencyTraded: 'USDT',
      price: 850,
      quantity: 10000,
      transactionType: 'buy',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 2,
      name: 'Stone',
      numberOfTrades: 80,
      successRate: 75,
      currencyTraded: 'ETH',
      price: 900,
      quantity: 100000,
      transactionType: 'buy',
      paymentMethod: 'Cash',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 3,
      name: 'Mowo',
      numberOfTrades: 75,
      successRate: 70,
      currencyTraded: 'BTC',
      price: 950,
      quantity: 90000,
      transactionType: 'buy',
      paymentMethod: 'In-App',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'not verified',
    },
    {
      id: 4,
      name: 'David',
      numberOfTrades: 70,
      successRate: 70,
      currencyTraded: 'LINK',
      price: 1000,
      quantity: 5000,
      transactionType: 'buy',
      paymentMethod: 'Card',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 5,
      name: 'Kola',
      numberOfTrades: 65,
      successRate: 70,
      currencyTraded: 'XRP',
      price: 820,
      quantity: 8000,
      transactionType: 'buy',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'unverified',
    },
  ];

  const sellTransactions = [
    {
      id: 1,
      name: 'TruexGold',
      numberOfTrades: 100,
      successRate: 80,
      currencyTraded: 'USDT',
      price: 850,
      quantity: 10000,
      transactionType: 'sell',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 2,
      name: 'Stone',
      numberOfTrades: 80,
      successRate: 75,
      currencyTraded: 'USDT',
      price: 900,
      quantity: 100000,
      transactionType: 'sell',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 3,
      name: 'Mowo',
      numberOfTrades: 75,
      successRate: 70,
      currencyTraded: 'USDT',
      price: 950,
      quantity: 90000,
      transactionType: 'sell',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'not verified',
    },
    {
      id: 4,
      name: 'David',
      numberOfTrades: 70,
      successRate: 70,
      currencyTraded: 'USDT',
      price: 1000,
      quantity: 5000,
      transactionType: 'sell',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'verified',
    },
    {
      id: 5,
      name: 'Kola',
      numberOfTrades: 65,
      successRate: 70,
      currencyTraded: 'USDT',
      price: 820,
      quantity: 8000,
      transactionType: 'sell',
      paymentMethod: 'Bank Transfer',
      banks: ['GTB', 'UBA', 'FCMB'],
      status: 'unverified',
    },
  ];

  const handleBuyer = (item: TransactionType) => {
    navigate(`/dashboard/p2p/buyer/${item?.id}`, {state: item});
  };

  const handleSeller = (item: TransactionType) => {
    navigate(`/dashboard/p2p/seller/${item?.id}`, {state: item});
  };

  const handleCurrencyClick = (currency: string) => {
    setSelectedCurrency(currency);
  };

  const handleRegionClick = (region: string) => {
    setSelectedRegion(region);
  };

  const handlePaymentMethodClick = (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleFilterActive = () => {
    setFilterActive(true);
  };

  const handleFilterInactive = () => {
    setSelectedCurrency(null);
    setSelectedPaymentMethod(null);
    setSelectedRegion(null);
    setFilterActive(false);
  };

  const buyFilteredList = buyTransactions.filter((transaction) => (
    (transaction.currencyTraded === selectedCurrency) ||
    (transaction.paymentMethod === selectedPaymentMethod)
  ));

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <div className={styles.txnContainer}>
          <div className={styles.tabContainer}>
            <div className={showBuy ? `${styles.activeTab} ${styles.tab}` : styles.tab} onClick={handleBuy}>
              <p>Buy</p>
            </div>
            <div className={showSell ? `${styles.activeTab} ${styles.tab}` : styles.tab} onClick={handleSell}>
              <p>Sell</p>
            </div>
          </div>
          <ul className={styles.list}>
            {tabs.map((tab, index) => (
              <li
                key={index.toString()}
                className={activeTab === tab ? `${styles.active} ${styles.listItem}` : styles.listItem}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.filterContainer}>
          <div className={styles.search}>
            <SearchInput placeholder="Search" />
          </div>

          <ul className={styles.category}>
            <li className={styles.categoryItem}>
              <ListDropdown
                onclick={handleCurrencyClick}
                dropdownMenu={currencies}
                label={'Currency'}
                isFilterActive={filterActive}
              />
            </li>
            <li className={styles.categoryItem}>
              <ListDropdown
                onclick={handleRegionClick}
                dropdownMenu={regions}
                label={'Regions'}
                isFilterActive={filterActive}
              />
            </li>
            <li className={styles.categoryItem}>
              <ListDropdown
                onclick={handlePaymentMethodClick}
                dropdownMenu={paymentMethods}
                label={'Payment Method'}
                isFilterActive={filterActive}
              />
            </li>
            <li className={styles.categoryItem}>
              <p>Filter</p>
              <div className={styles.iconContainer}>
                {!filterActive && (
                  <div className={!filterActive ? styles.filterWrapper : styles.anim} onClick={handleFilterActive}>
                    <img src={filterIcon} alt="filter-icon" className={styles.filter} />
                  </div>
                )}
                {filterActive && (
                  <div className={filterActive ? styles.closeWrapper : styles.anim} onClick={handleFilterInactive}>
                    <MdOutlineClose className={styles.close} />
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>

      {showBuy && (
        <div className={styles.container}>
          {selectedCurrency == null || selectedPaymentMethod == null ? (
            <>
              {buyTransactions.map((item, index) => (
                <TransactionCard
                  data={item}
                  key={index.toString()}
                  txnTypeBgColor={'#1cd16f'}
                  onclick={() => handleBuyer(item)}
                />
              ))}
            </>
          ) : (
            <>
              { (selectedCurrency !== null || selectedPaymentMethod !== null)  ? ( 
                buyFilteredList.map((item, index) => (
                <TransactionCard
                  data={item}
                  key={index.toString()}
                  txnTypeBgColor={'#1cd16f'}
                  onclick={() => handleBuyer(item)}
                />
              ))
            ) : (
              <div className={styles.msg}>
                <p>Sorry, your search/filter query(ies) does not exist</p>
              </div>
            )}
            </>
          )}
        </div>
      )}

      {showSell && (
        <div className={styles.container}>
          {sellTransactions.map((item, index) => (
            <TransactionCard
              data={item}
              key={index.toString()}
              txnTypeBgColor={'#ff0100'}
              onclick={() => handleSeller(item)}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default DashboardP2P;
