import React, { useState, useEffect } from 'react';
import styles from 'styles/dashboard/home.module.css';
import { PrimaryBg } from 'components/dashboard/PrimaryBg';
import buyIcon from 'images/dashboard/buyIcon.svg';
import sellIcon from 'images/dashboard/sellIcon.svg';
import sendIcon from 'images/dashboard/sendIcon.svg';
import receiveIcon from 'images/dashboard/receiveIcon.svg';
import { formatCurrency } from 'utils/stringUtils';
import btcGraph from 'images/dashboard/btcGraph.svg';
import ethGraph from 'images/dashboard/ethGraph.svg';
import ltcGraph from 'images/dashboard/ltcGraph.svg';
import { WalletTab } from 'components/dashboard/WalletTab';
import { useOutletContext } from 'react-router-dom';
import { CoinChart } from 'components/coins/CoinChart';
import { BuyCoin } from 'components/dashboard/BuyCoin';
import { Transactions } from 'components/dashboard/Transactions';
import { BuyModal } from 'components/modal/BuyModal';
import { ChooseCryptoModal } from 'components/modal/ChooseCryptoModal';
import { useNavigate } from 'react-router-dom';
import { ReceiveModal } from 'components/modal/ReceiveModal';

import { truexgoldAPI } from 'api/truexgoldAPI';

export const Home: React.FC = () => {
  const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState<boolean>(false);
  const showSidebar: boolean = useOutletContext();
  const [showBuyModal, setShowBuyModal] = useState<boolean>(false);
  const [showChooseCryptoModal, setShowChooseCryptoModal] = useState<boolean>(false);
  const [showReceiveModal, setShowReceiveModal] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem('ACCESS_TOKEN');
        if (!token) {
            navigate('/auth/login'); // Redirect to login page if no token found
        }else{
            setAuthenticated(true);
        }
    }, []); // Empty dependency array ensures the effect runs only once on mount

    if (!authenticated) {
        return null; // Render nothing until authentication status is determined
    }

  const handleBuy = () => {
    setShowBuyModal(true);
  };

  const handleReceive = () => {
    setShowReceiveModal(true);
  };

  const handleRepeatBuy = () => {
    setShowBuyModal(!showBuyModal);
    navigate('/dashboard/repeat_buy_index');
  };

  const handleOneOff = () => {
    setShowBuyModal(false);
    setShowChooseCryptoModal(true);
  };

  const handleChooseCrypto = () => {
    setShowBuyModal(false);
    navigate('/dashboard/add_money');
  };

  const handleReceiveModal = () => {
    setShowReceiveModal(false);
    navigate('/dashboard/scan_to_receive');
  };

  const handleCreateAddress = () => {
    setShowReceiveModal(false);
    navigate('/dashboard/create_address');
  };

  const handleSell = () => {
    navigate('/dashboard/sell_index');
  }

  const handleSend = () => {
    navigate('/dashboard/send_index');
  }

  const data = {
    totalValue: 10000000,
    coins: {
      btc: {
        name: 'Bitcoin',
        shortName: 'BTC',
        value: 5000000,
        graph: btcGraph,
        change: '+6.00',
        price: 29705.5,
      },
      eth: {
        name: 'Ethereum',
        shortName: 'ETH',
        value: 4000000,
        graph: ethGraph,
        change: '+4.98',
        price: 1853.59,
      },
      ltc: {
        name: 'Litecoin',
        shortName: 'LTC',
        value: 1000000,
        graph: ltcGraph,
        change: '+0.94',
        price: 83.64,
      },
      dash: {
        name: 'Dash',
        shortName: 'DASH',
        value: 5000000,
        graph: ltcGraph,
        change: '+0.94',
        price: 32.82,
      },
    },
    transactions: [
      {
        id: 1,
        type: 'deposit',
        from: 'bitcoin',
        fromValue: 0.62844,
        to: 'ethereum',
        toValue: 0.00789,
        desc: 'TokennIon',
        amount: 20874.9,
        status: 'Failed',
      },
      {
        id: 2,
        type: 'withdrawal',
        from: 'litecoin',
        fromValue: 0.62844,
        to: 'dash',
        toValue: 0.00789,
        desc: 'TokennIon',
        amount: 20874.9,
        status: 'Successful',
      },
      {
        id: 3,
        type: 'deposit',
        from: 'ethereum',
        fromValue: 0.62844,
        to: 'bitcoin',
        toValue: 0.00789,
        desc: 'TokennIon',
        amount: 20874.9,
        status: 'Pending',
      },
    ],
  };

  return (
    <section className={styles.section}>
      <div className={styles.circle}></div>

      <div className={styles.topContent}>
        <div className={styles.balanceWrapper}>
          <h2 className={styles.h2}>{formatCurrency(data.totalValue)}</h2>
          <p className={styles.p}>Combined Wallet Value</p>
        </div>

        <div className={styles.actionsContainer}>
          <PrimaryBg bgStyle={styles.actionCard} onClick={handleBuy}>
            <div className={styles.cardWrapper}>
              <img src={buyIcon} alt="icon" className={styles.icon} />
              <p>Buy</p>
            </div>
          </PrimaryBg>

          <PrimaryBg bgStyle={styles.actionCard} onClick={handleSell}>
            <div className={styles.cardWrapper}>
              <img src={sellIcon} alt="icon" className={styles.icon} />
              <p>Sell</p>
            </div>
          </PrimaryBg>

          <PrimaryBg bgStyle={styles.actionCard} onClick={handleSend}>
            <div className={styles.cardWrapper}>
              <img src={sendIcon} alt="icon" className={styles.icon} />
              <p>Send</p>
            </div>
          </PrimaryBg>

          <PrimaryBg bgStyle={styles.actionCard} onClick={handleReceive}>
            <div className={styles.cardWrapper}>
              <img src={receiveIcon} alt="icon" className={styles.icon} />
              <p>Receive</p>
            </div>
          </PrimaryBg>
        </div>

        <button className={styles.merchant}>
          <a href="/auth/merchant_index">Become a Merchant</a>
        </button>
      </div>

      <div className={styles.walletsContainer}>
        <WalletTab data={data} expandSidebar={showSidebar} />
      </div>

      <div className={styles.coinContainer}>
        <h5 className={styles.h5}>Wallet Flow</h5>
        <div className={styles.chart}>
          <PrimaryBg bgStyle={styles.chart2}>
            <CoinChart
              chart1m={undefined}
              chart3m={undefined}
              chart6m={undefined}
              chart1y={undefined}
              chartAll={undefined}
              coinName={data.coins.btc.name}
              textColor={'white'}
              activeStyle={styles.activePeriod}
              expandSidebar={showSidebar}
            />
          </PrimaryBg>

          <div className={styles.buyCoin}>
            <BuyCoin coinPrice={data.coins.btc.price} />
          </div>
        </div>
      </div>

      <div className={styles.recentTransactions}>
        <h5 className={styles.h5}>Latest Activities</h5>
        <Transactions data={data} />
      </div>

      {/* Modals */}
      <div className={styles.modalContainer}>
        {showBuyModal && (
          <BuyModal
            onclose={handleRepeatBuy}
            expandSidebar={showSidebar}
            onPressRepeat={handleRepeatBuy}
            onPressOneOff={handleOneOff}
          />
        )}

        {showChooseCryptoModal && (
          <ChooseCryptoModal
            expandSidebar={showSidebar}
            onclose={() => setShowChooseCryptoModal(false)}
            onPressItem={handleChooseCrypto}
          />
        )}

        {showReceiveModal && (
          <ReceiveModal
            expandSidebar={showSidebar}
            onclose={() => setShowReceiveModal(false)}
            onclickAddress={handleReceiveModal}
            onclickCreate={handleCreateAddress}
          />
        )}
      </div>
    </section>
  );
};

export default Home;
