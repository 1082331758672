import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "'Poppins', sans-serif",
          fontSize: '.9rem',
        },
      },
    },
  },
});

type Props = {
  label: string;
  labelPosition?: 'end' | 'start' | 'top' | 'bottom' | undefined;
};

export const PrimaryCheckbox: React.FC<Props & CheckboxProps> = ({ label, labelPosition, ...props }) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              sx={{
                color: '#cea600',
                '&.Mui-checked': {
                  color: '#cea600',
                },
              }}
            />
          }
          label={label}
          labelPlacement={labelPosition ? labelPosition : "end"}
        />
      </ThemeProvider>
    </div>
  );
};
