import React from 'react';
import baseStyles from 'styles/modal/buyModal.module.css';
import itemStyle from 'styles/modal/receiveModal.module.css';
import styles from 'styles/dashboard/redeemVoucherIndex.module.css';
import scanStyles from 'styles/dashboard/scanStyles.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import qrCode from 'images/dashboard/qrCode.svg';
import { ModalListItem } from 'components/modal/ModalListItem';
import { BsCurrencyBitcoin } from 'react-icons/bs';

export const ScanToReceive: React.FC = () => {
  return (
    <div style={{ width: '100%', padding: '50px 0' }}>
      <PrimaryPageLayout
        title={'Scan to recieve'}
        desc={'You can only receive BTC with this address'}
      >
        <div className={styles.content}>
          <div className={scanStyles.scanWrapper}>
            <img src={qrCode} alt="qrCode" className={scanStyles.qrCode} />
            <div className={scanStyles.address}>
              <ModalListItem
                containerStyle={scanStyles.itemContainer}
                iconWrapperColor="#f8a137"
                icon={<BsCurrencyBitcoin className={`${baseStyles.icon2} ${itemStyle.rotateIcon}`} />}
                desc={'3gjskhfvdshvfivjjfjvsovvdbnvsnvopsdvnspojvnnvdnl47ryurrurhfiruf575789r4rj4944toit4u'}
                copyText={'Copy'}
                descStyle={scanStyles.desc}
              />
            </div>
          </div>
          <div className={`${styles.inputWrapper} ${scanStyles.inputWrapper}`}>
            <div className={scanStyles.inputs}>
              <div className={styles.currencyInput}>
                <PrimaryInput label="Currency" value="NGN" InputLabelProps={{ shrink: true }} />
              </div>
              <div className={styles.input}>
                <PrimaryInput label="NGN Wallet" placeholder="NGN 100.00" InputLabelProps={{ shrink: true }} />
              </div>
            </div>
            <p className={scanStyles.priceInfo}>Current BTC price NGN 13,253,457</p>
            <div className={scanStyles.info}>
              <p>Receive</p>
              <p>BTC 0.0001</p>
            </div>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default ScanToReceive;
