import React from "react";
import baseStyles from "styles/settings/profile.module.css";
import inputStyles from "styles/settings/inputStyles.module.css";
import styles from "styles/settings/help.module.css";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

export const Help:React.FC = () => {

  return (
    <div className={baseStyles.container}>
      <h3 className={inputStyles.h3}>Ticket</h3>
      <div className={inputStyles.content}>
      <div className={inputStyles.inputContainer}>
        <label className={`${inputStyles.label} ${styles.label}`}>Select Issue</label>
        <div className={`${inputStyles.inputField} ${styles.inputField}`}>
          <input value={'Payment'} className={inputStyles.input} />
          <IoIosArrowDown size={20} className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={`${inputStyles.label} ${styles.label}`}>Compose</label>
        <div className={`${inputStyles.inputField} ${styles.inputField}`}>
          {/* @ts-ignore */}
          <textarea style={{width: '100%'}} rows="10" className={inputStyles.input}></textarea>
        </div>
        <button className={styles.submit}>Submit</button>
      </div>

      <div className={inputStyles.inputContainer}>
        <div className={inputStyles.inputField}>
        <p style={{ fontWeight: '700'}} className={inputStyles.input}>FAQ</p>
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <div className={inputStyles.inputField}>
        <p style={{ fontWeight: '700'}} className={inputStyles.input}>Connect Live Support</p>
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>
      </div>
    </div>
  );
};

export default Help;