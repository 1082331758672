import { Outlet } from "react-router-dom";

const Products = () => {
  return (
    <section
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 100,
      }}
    >
      <Outlet />
    </section>
  );
};
export default Products;
