import React, { ReactNode } from 'react';
import styles from 'styles/modal/buyModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { ModalListItem } from './ModalListItem';
import repeatIcon from 'images/dashboard/repeatIcon.svg';
import { BsCurrencyBitcoin } from 'react-icons/bs';

type Props = {
  expandSidebar: boolean;
  onclose: () => void;
  onPressRepeat: () => void;
  onPressOneOff: () => void;
};

export const BuyModal: React.FC<Props> = (props) => {
  const { expandSidebar, onclose, onPressRepeat, onPressOneOff  } = props;

  return (
    <div className={expandSidebar ? styles.overlay2 : styles.overlay}>
      <div className={styles.container}>
        <div className={styles.closeIcon} onClick={onclose}>
          <IoCloseOutline className={styles.icon} />
        </div>
        <div className={styles.content}>
          <h4 className={styles.h4}>How do you want to buy your crypto?</h4>
          <ModalListItem onclick={onPressRepeat} icon={<img src={repeatIcon} alt="repeat-icon" />} title={'Repeat Buy'} desc={'Set up an order to buy crypto monthly, weekly or daily'}/>
          <ModalListItem onclick={onPressOneOff} icon={<BsCurrencyBitcoin className={styles.icon2} />} title={'One-off'} desc={'Make a once-off purchase'}/>
        </div>
      </div>
    </div>
  );
};
