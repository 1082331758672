import styles from "styles/features/features.module.css";
import { Feature1 } from "components/features/Feature1";
import { Feature2 } from "components/features/Feature2";
import { Feature3 } from "components/features/Feature3";
import { Feature4 } from "components/features/Feature4";
import { Feature5 } from "components/features/Feature5";
import { Hero } from "components/features/Hero";
import ScrollToTop from "components/ScrollToTop";

const Features = () => {
  return (
    <ScrollToTop>
      <main className={styles.main}>
        <Hero />
        <Feature1 />
        <Feature2 />
        <Feature3 />
        <Feature4 />
        <Feature5 />
      </main>
    </ScrollToTop>
  );
};
export default Features;
