export function validateEmail(email: string) 
{
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}
export function formatCurrency (num: any) {
    if (typeof num === 'undefined' || num === null || num.length === 0) num = 0;
    num = parseFloat(num);
   return "NGN "+num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
//    return "₦"+num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatTickerPrice (num: any) {
    if (typeof num === 'undefined' || num === null || num.length === 0) num = 0;
    num = parseFloat(num);
   return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatAmount (num: any) {
    if (typeof num === 'undefined' || num === null || num.length === 0) num = 0;
    num = parseFloat(num);
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}