import React, { useState } from 'react';
import styles from 'styles/dashboard/help.module.css';
import merchantDashboard from 'images/dashboard/merchantDashboard.svg';
import { useNavigate } from 'react-router-dom';

export const MerchantDashboard: React.FC = () => {
  const navigate = useNavigate();
  
  const handleNext = () => {
    navigate('/merchant/merchant_home');
  };

  return (
    <div className={styles.merchantContainer}>
          <img src={merchantDashboard} alt="reward" className={styles.header} onClick={handleNext}/>
        </div>
  );
};

export default MerchantDashboard;
