import React from "react";

export const Transaction = () => {
  return (
    <p
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      Transaction Page
    </p>
  );
};

export default Transaction;