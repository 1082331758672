import styles from "../../styles/company/team.module.css";
import founder from "../../images/avatarBlue.svg";
import teamMember1 from "../../images/avatarBlue2.svg";
import teamMember2 from "../../images/avatarRed.svg";
import teamMember3 from "../../images/avatarGreen.svg";
import teamMember4 from "../../images/avatarPurple.svg";
import { NameCard } from "./NameCard";

export const Team = () => {
  return (
    <div className={styles.teamWrapper}>
      <div className={styles.headingWrapper}>
        <h3 data-aos="fade-up" data-aos-duration="1000">
          Meet the Team
        </h3>
        <p data-aos="fade-up" data-aos-duration="1500">
          The TruexGold team comes from a wide range of backgrounds in
          engineering, finance and industry, but all united in our passion to
          revolutionise the way people use money. Most of our team have lived in
          or worked across many different countries, speak multiple languages,
          and have successful track records working at top global companies such
          as Google, Amazon, Morgan Stanley, Barclays and Ernst & Young.
        </p>
      </div>
      <div className={styles.teamPicturesContainer}>
        <div data-aos="zoom-in" data-aos-duration="1500" className={styles.founderImageWrapper}>
          <NameCard
            image={founder}
            name={"TruexGold"}
            role={"C.E.O"}
            nameStyle={styles.founderName}
            roleStyle={styles.founderRole}
            wrapperStyle={styles.founderCardWrapper}
            imageStyle={styles.founderImage}
          />
        </div>
        <div className={styles.teamImages}>
          <div data-aos="flip-right" data-aos-duration="1000" className={styles.teamImagesWrapper}>
            <NameCard
              image={teamMember1}
              name={"John Doe"}
              role={"Program Designer / Cordinator"}
              nameStyle={styles.teamName}
              roleStyle={styles.teamRole}
              wrapperStyle={styles.teamCardWrapper}
              imageStyle={styles.teamImage}
            />
          </div>

          <div data-aos="flip-left" data-aos-duration="1000" className={styles.teamImagesWrapper}>
            <NameCard
              image={teamMember2}
              name={"Doe John"}
              role={"Head, Techinal Training"}
              nameStyle={styles.teamName}
              roleStyle={styles.teamRole}
              wrapperStyle={styles.teamCardWrapper}
              imageStyle={styles.teamImage}
            />
          </div>

          <div data-aos="flip-right" data-aos-duration="1000" className={styles.teamImagesWrapper}>
            <NameCard
              image={teamMember3}
              name={"Ciroma Chukwudi"}
              role={"Student-Mentor Relations"}
              nameStyle={styles.teamName}
              roleStyle={styles.teamRole}
              wrapperStyle={styles.teamCardWrapper}
              imageStyle={styles.teamImage}
            />
          </div>

          <div data-aos="flip-left" data-aos-duration="1000" className={styles.teamImagesWrapper}>
            <NameCard
              image={teamMember4}
              name={"Tayo Nduka"}
              role={"Web2 Director"}
              nameStyle={styles.teamName}
              roleStyle={styles.teamRole}
              wrapperStyle={styles.teamCardWrapper}
              imageStyle={styles.teamImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
