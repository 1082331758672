import React, { useState } from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/personalDetails.module.css";
import { PrimaryInput } from "components/input/PrimaryInput";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown } from "components/input/Dropdown";

const MoreDetails: React.FC = () => {
  const states = [
    { id: "1", value: "FCT Abuja" },
    { id: "2", value: "Kano" },
    { id: "3", value: "Lagos" },
    { id: "4", value: "Rivers" },
  ];

  const status = [
    { id: "1", value: "Employed" },
    { id: "2", value: "Unemployed" },
  ];

  const industry = [
    { id: "1", value: "Education" },
    { id: "2", value: "Financial" },
    { id: "3", value: "IT/Technology" },
    { id: "4", value: "Engineering" },
    { id: "5", value: "Others" },
  ];

  const reasons = [
    { id: "1", value: "Trading" },
    { id: "2", value: "Investing" },
    { id: "3", value: "Others" },
  ];

  return (
    <div className={`${baseStyles.authContainer} ${styles.authContainer}`}>
      <h2>Tell us more about you</h2>
      <p className={baseStyles.text}>
        Help us understand who you are. Your answers will not affect how you use
        TruexGold
      </p>

      <form className={`${baseStyles.form} ${styles.form}`}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            <PrimaryInput label="Address Line 1" />
          </div>

          <div className={styles.detailsWrapper}>
            <PrimaryInput label="Address Line 2" />
          </div>

          <div style={{ marginBottom: '20px'}} className={styles.detailsWrapper}>
            <PrimaryInput label="Town/City" />
          </div>

          <div style={{ marginBottom: '20px'}} className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={states} label="State" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={status} label="Employment Status" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>

          <div style={{ marginTop: 0}} className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={status} label="Select industry" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={reasons} label="Reason for using TruexGold" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>
        </div>

        <div className={`${baseStyles.button} ${styles.button}`}>
          <a href="/auth/funding_options">Next</a>
        </div>
      </form>
    </div>
  );
};

export default MoreDetails;
