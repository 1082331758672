import React, { useState } from 'react';
import styles from 'styles/dashboard/help.module.css';
import p2pBuy from 'images/dashboard/txnListBuy.svg';
import header from 'images/dashboard/buyHeader.svg';
import p2pSell from 'images/dashboard/sellList.svg';
import sellHeader from 'images/dashboard/sellHeader.svg';
import { useNavigate } from 'react-router-dom';

export const MerchantP2P: React.FC = () => {
  const navigate = useNavigate();
  const [showBuy, setShowBuy] = useState<boolean>(true);
  const [showSell, setShowSell] = useState<boolean>(true);

  const handleBuyClick = () => {
    setShowBuy(false);
    setShowSell(true);
  };

  const handleSellClick = () => {
    setShowSell(false);
    setShowBuy(true);
  };

  const handleSellTxn = () => {
    navigate('/dashboard/p2pSell/txn');
  };

  const handleBuyTxn = () => {
    navigate('/dashboard/p2pBuy/txn');
  };

  return (
    <>
      {showBuy && (
        <div className={styles.p2pContainer}>
          <img src={header} alt="reward" className={styles.header} onClick={handleBuyClick}/>
          <img src={p2pBuy} alt="reward" className={styles.p2pList} onClick={handleBuyTxn} />
        </div>
      )}
       {showSell && (
        <div className={styles.p2pContainer}>
          <img src={sellHeader} alt="reward" className={styles.header} onClick={handleSellClick}/>
          <img src={p2pSell} alt="reward" className={styles.p2pList} onClick={handleSellTxn} />
        </div>
      )}
    </>
  );
};

export default MerchantP2P;
