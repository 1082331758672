import React, { HTMLAttributes, ReactNode } from "react";
import styles from "styles/dashboard/walletCard.module.css";
import { formatCurrency } from "utils/stringUtils";

type Props = {
  bgColor: string;
  textColor?: string;
  title?: string;
  changeBg: string;
  change: string;
  descColor?: string;
  walletName?: string;
  walletSymbol?: string;
  graph: string;
  value: string;
  share?: number;
  expandSidebar?: boolean;
};

export const WalletCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const {
    bgColor,
    textColor,
    title,
    changeBg,
    change,
    descColor,
    walletName,
    walletSymbol,
    graph,
    value,
    share,
    expandSidebar,
    ...divProps
  } = props;
 
  return (
    <div style={{ backgroundColor: bgColor }} className={ expandSidebar ? styles.cardContainer2 : styles.cardContainer}{...divProps}>
      <div className={styles.cardHeading}>
        <div className={styles.titleWrapper}>
          <h3 style={{ color: title ? 'white' : textColor }}>{walletSymbol ? walletSymbol : title}</h3>
          <div style={{ backgroundColor: changeBg }} className={styles.change}>
            <p style={{color: textColor}}>{change}%</p>
          </div>
        </div>
        {walletName && <p style={{ color: descColor}}>{walletName} to USD</p>}
      </div>

      <div className={styles.graphImg}>
        <img src={graph} alt={"coin-graph"} className={styles.img} />
      </div>

      <div className={expandSidebar ? styles.valueWrapper2 : styles.valueWrapper}>
        <h3 style={{ color: title ? 'white' : 'black'}}>{value}</h3>
        <p style={{ color : share ? '#9D9DAE' : '#EAE8EB'}}>{share ? share : '100'}% of your portfolio</p>
      </div>
    </div>
  );
};
