import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "images/truexgoldLogo2.svg"; 
import logo2 from "images/truexgoldLogo.svg"; 
import styles from "styles/header/mainHeader.module.css";
import { HeaderDropdown } from "../dropdown/HeaderDropdown";
import 'animate.css';
import { IoCloseOutline, IoMenuOutline } from "react-icons/io5";
import { MdExpandMore } from "react-icons/md";

export const Header:React.FC = () => {
  const [openNav, setOpenNav] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleClick = () => {
    setOpenNav(!openNav);
  };

  const handleArrowClick = () => {
    setIsDropdownVisible(true);
    console.log("Clicked");
  };
  
  const handleDropdownItemClick = () => {
    setIsDropdownVisible(false);
    setOpenNav(!openNav);
  }

  const products = [    
    { name: "Exchange", path: "products/exchange" },
    { name: "P2P", path: "products/p2p" },
    { name: "Wallet", path: "products/wallet" },
    { name: "Rewards", path: "products/rewards/rewards-home" },
  ];

  // const coins = [
  //   { name: "Bitcoin", path: "coins/bitcoin" },
  //   { name: "Ethereum", path: "coins/ethereum" },
  //   { name: "Litecoin", path: "coins/litecoin" },
  //   { name: "Dash", path: "coins/dash" },
  // ];

  return (
    <header className={styles.header}>
      <div data-aos="fade-right" data-aos-duration="500" className={styles.logoWrapper}>
        <img
          className={openNav ? styles.logo : styles.logo2}
          src={logo}
          alt="Truexgold Logo"
        />
        <img className={styles.logo_large} src={logo2} alt="Truexgold Logo" />
      </div>
      <nav 
        className={
          openNav
            ? `${styles.navlinksWrapper} ${styles.collapsed}  ${"animate__animated animate__slideInUp"}`
            : `${styles.navlinksWrapper} ${"animate__animated animate__slideInUp"}`
        }
      >
        <ul className={styles.navlinks}>
          <li className={styles.linksWrapper}>
            <NavLink className={styles.links} to="/" onClick={handleClick}>
              Features
            </NavLink>
          </li>
          <li
            className={`${styles.linksWrapper} ${styles.dropdownLinks}`}
          >
            <span className={styles.links}>Products&nbsp;<MdExpandMore className={styles.expandIcon} onClick={handleArrowClick} /></span>
            <div style={{ display: isDropdownVisible ? 'flex' : 'none'}} className={styles.dropdown}>
              <HeaderDropdown dropdownMenu={products} onclick={handleDropdownItemClick} />
            </div>
          </li>
          <li className={styles.linksWrapper}>
            <NavLink className={styles.links} to="about" onClick={handleClick}>
              Company
            </NavLink>
          </li>
          {/* <li className={`${styles.linksWrapper} ${styles.dropdownLinks}`}>
            <span className={styles.links} onClick={handleClick}>Our Coins <ExpandMoreIcon className={styles.expandIcon}/></span>
            <div className={styles.dropdown}>
              <HeaderDropdown dropdownMenu={coins} onClick={handleClick} />
            </div>
          </li> */}
          <li className={styles.linksWrapper}>
            <NavLink className={styles.links} to="/auth/login" onClick={handleClick}>
              Sign In
            </NavLink>
          </li>
          <li className={`${styles.linksWrapper} ${styles.signupBtn}`}>
            <NavLink to="/auth/signup" className={styles.links} onClick={handleClick}>
              Sign Up
            </NavLink>
          </li>
        </ul>
      </nav>
      <div data-aos="fade-left" data-aos-duration="500" onClick={handleClick} className={openNav ? `${styles.menu_icon} ` : styles.menu_iconOpen}>
        {openNav ? (
          <IoMenuOutline className={"animate__animated animate__slideInRight"} size={30} color={'black'} />
        ) : (
          <div data-aos="fade-right" data-aos-duration="500" className={styles.close_icon}>
            <IoCloseOutline size={30} color={'black'} />
          </div>
        )}
      </div>
    </header>
  );
};
