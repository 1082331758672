import React from "react";
import styles from 'styles/dashboard/help.module.css';
import help from 'images/dashboard/help.svg';

export const Help:React.FC = () => {
  return (
   <div className={styles.container}>
    <img src={help} alt="help" />
   </div>
  );
};

export default Help;