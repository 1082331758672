import React, { useState } from 'react';
import styles from 'styles/dashboard/enterCardDetails.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { TransactionReceiptModal } from 'components/modal/TransactionReceiptModal';
import { useNavigate, useOutletContext } from 'react-router-dom';

export const RedeemVoucher: React.FC = () => {
  const [ showTransactionReceiptModal, setShowTransactionReceiptModal ] = useState<boolean>(false);
  const showSidebar: boolean = useOutletContext();
  const navigate = useNavigate();

  const handleTransactionReceiptModal = () => {
    setShowTransactionReceiptModal(false);
    navigate("/dashboard/home");
  }
  
  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'Redeem your voucher'}
        desc={'To add money to your TruexGold wallet, enter the voucher details our payment partners sent to *******@gmail.com'}
        buttonText={'Redeem Voucher'}
        onclick={() => setShowTransactionReceiptModal(true)}
        buttonStyle={styles.button}
      >
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <div className={styles.input}>
              <PrimaryInput label="Voucher number" />
            </div>
            <div className={styles.input}>
              <PrimaryInput label="Voucher PIN" />
            </div>
            <div className={styles.input}>
              <PrimaryInput label="Security ID" />
            </div>
          </div>
        </div>
      </PrimaryPageLayout>

      {showTransactionReceiptModal && <TransactionReceiptModal expandSidebar={showSidebar} onclose={handleTransactionReceiptModal} />
      }
    </div>
  );
};

export default RedeemVoucher;
