import React from "react";
import inputStyles from "styles/settings/inputStyles.module.css";
import baseStyles from "styles/settings/profile.module.css";
import styles from "styles/settings/security.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const  Two_FA:React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={`${baseStyles.container} ${styles.container}`}>
      <h3 className={inputStyles.h3}>Select Method</h3>

      <div className={styles.content}>
        <div className={inputStyles.inputContainer}>
          <label className={inputStyles.label}>Email</label>
          <div className={inputStyles.inputField}>
            <input value={'Email authentication'} className={inputStyles.input} />
            <IoIosArrowForward className={inputStyles.icon} />
          </div>
        </div>

        <div className={inputStyles.inputContainer} onClick={() => navigate('/dashboard/settings/security/2FA/phone')}>
          <label className={inputStyles.label}>Phone</label>
          <div className={inputStyles.inputField}>
            <input value={'Sms authentication'} className={inputStyles.input} />
            <IoIosArrowForward className={inputStyles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Two_FA;