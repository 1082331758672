import { Route, Routes } from 'react-router-dom';
import Company from 'pages/Company';
import Features from 'pages/Features';
import Exchange from 'pages/Products/Exchange';
import P2P from 'pages/Products/P2P';
import Wallet from 'pages/Products/Wallet';
import MainLayout from 'pages/Layouts/MainLayout';
import RewardsLayout from 'pages/Layouts/RewardsLayout';
import RewardsHome from 'pages/Products/Rewards/RewardsHome';
import RewardsEarn from 'pages/Products/Rewards/RewardsEarn';
import RewardsGrow from 'pages/Products/Rewards/RewardsGrow';
import Bitcoin from 'pages/Coins/Bitcoin';
import Dash from 'pages/Coins/Dash';
import Ethereum from 'pages/Coins/Ethereum';
import Litecoin from 'pages/Coins/Litecoin';
import CoinsLayout from 'pages/Layouts/CoinsLayout';
import Products from 'pages/Products/Products';
import AuthLayout from 'pages/Layouts/AuthLayout';
import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup';
import SecurityVerification from 'pages/Auth/SecurityVerification';
import SignupSecurityVerification from 'pages/Auth/SignupSecurityVerification';
import VerifyEmail from 'pages/Auth/VerifyEmail';
import ConfirmPhone from 'pages/Auth/ConfirmPhone';
import VerifyPhone from 'pages/Auth/VerifyPhone';
import ForgotPasswordVerifyEmail from 'pages/Auth/ForgotPasswordVerifyEmail';
import CreateNewPassword from 'pages/Auth/CreateNewPassword';
import SignupVerifyEmail from 'pages/Auth/SignupVerifyEmail';
import CreatePin from 'pages/Auth/CreatePin';
import EnterPhone from 'pages/Auth/EnterPhone';
import SignupVerifyPhone from 'pages/Auth/SignupVerifyPhone';
import ExtraDetails from 'pages/Auth/ExtraDetails';
import PersonalDetails from 'pages/Auth/PersonalDetails';
import ConfirmDetails from 'pages/Auth/ConfirmDetails';
import ConfirmEmail from 'pages/Auth/ConfirmEmail';
import Confirmation from 'pages/Auth/Confirmation';
import MoreDetails from 'pages/Auth/MoreDetails';
import FundingOptions from 'pages/Auth/FundingOptions';
import TakePicture from 'pages/Auth/TakePicture';
import SignupCompleted from 'pages/Auth/SignupCompleted';
import CountrySetup from 'pages/Auth/CountrySetup';
import EnterPin from 'pages/Auth/EnterPin';
import DashboardLayout from 'pages/Layouts/DashboardLayout';
import Home from 'pages/Dashboard/Home';
import Transaction from 'pages/Dashboard/Transaction';
import DashboardRewards from 'pages/Dashboard/Rewards';
import DashboardP2P from 'pages/Dashboard/P2P';
import DashboardExchange from 'pages/Dashboard/Exchange';
import DashboardBitcoin from 'pages/Dashboard/Bitcoin';
import DashboardDash from 'pages/Dashboard/Dash';
import DashboardEthereum from 'pages/Dashboard/Ethereum';
import DashboardLitecoin from 'pages/Dashboard/Litecoin';
import About from 'pages/Dashboard/AboutUs';
import DashboardCompany from 'pages/Dashboard/Company';
import AddMoney from 'pages/Dashboard/AddMoney';
import Topup from 'pages/Dashboard/Topup';
import RedeemVoucherIndex from 'pages/Dashboard/RedeemVoucherIndex';
import EnterCardDetails from 'pages/Dashboard/EnterCardDetails';
import RedeemVoucher from 'pages/Dashboard/RedeemVoucher';
import RepeatBuyIndex from 'pages/Dashboard/RepeatBuyIndex';
import RepeatBuy from 'pages/Dashboard/RepeatBuy';
import ScanToReceive from 'pages/Dashboard/ScanToReceive';
import CreateAddress from 'pages/Dashboard/CreateAddress';
import SellIndex from 'pages/Dashboard/SellIndex';
import Sell from 'pages/Dashboard/Sell';
import TransactionPin from 'pages/Dashboard/TransactionPin';
import SendIndex from 'pages/Dashboard/SendIndex';
import Send from 'pages/Dashboard/Send';
import TransactionPinSend from 'pages/Dashboard/TransactionPinSend';
import CheckEmail from 'pages/Dashboard/CheckEmail';
import Help from 'pages/Dashboard/Help';
import BuyId from 'pages/Dashboard/BuyId';
import SellId from 'pages/Dashboard/SellId';
import MerchantIndex from 'pages/Auth/MerchantIndex';
import MerchantForm from 'pages/Auth/MerchantForm';
import MerchantLayout from 'pages/Layouts/MerchantLayout';
import MerchantHome from 'pages/Merchant/MerchantHome';
import MerchantP2P from 'pages/Merchant/MerchantP2P';
import MerchantSettings from 'pages/Merchant/MerchantSettings';
import MerchantTransaction from 'pages/Merchant/MerchantTransaction';
import SettingsLayout from 'pages/Layouts/SettingsLayout';
import Profile from 'pages/Settings/Profile';
import Security from 'pages/Settings/Security';
import Settings from 'pages/Settings/Settings';
import SettingsHelp from 'pages/Settings/Help';
import Two_FA from 'pages/Settings/Two_FA';
import PhoneVerification from 'pages/Settings/PhoneVerification';
import PhoneVerificationOTP from 'pages/Settings/PhoneVerificationOTP';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Features />} />
        <Route path="products" element={<Products />}>
          <Route path="exchange" element={<Exchange />} />
          <Route path="p2p" element={<P2P />} />
          <Route path="wallet" element={<Wallet />} />
        </Route>
        <Route path="about" element={<Company />} />
        <Route path="coins" element={<CoinsLayout />}>
          <Route path="bitcoin" element={<Bitcoin />} />
          <Route path="ethereum" element={<Ethereum />} />
          <Route path="litecoin" element={<Litecoin />} />
          <Route path="dash" element={<Dash />} />
        </Route>
      </Route>
      <Route element={<RewardsLayout />}>
        <Route index path="products/rewards/rewards-home" element={<RewardsHome />} />
        <Route path="products/rewards/rewards-earn" element={<RewardsEarn />} />
        <Route path="products/rewards/rewards-grow" element={<RewardsGrow />} />
      </Route>

      <Route path="auth" element={<AuthLayout />}>
        <Route index path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup_verify_email" element={<SignupVerifyEmail />} />{' '}
        {/* Screen to allow entering phone,  when signing up for an account */}
        <Route path="signup_verify_phone" element={<SignupVerifyPhone />} />{' '}
        {/* Screen to allow entering phone,  when signing up for an account */}
        <Route path="enter_phone" element={<EnterPhone />} />{' '}
        {/* Screen to allow entering phone,  when setting up account */}
        <Route path="verify_phone" element={<VerifyPhone />} />{' '}
        {/* Screen to enter otp sent to phone, when setting up account */}
        <Route path="verify_email" element={<VerifyEmail />} />{' '}
        {/* Screen to enter otp sent to email, when setting up account */}
        <Route path="country_setup" element={<CountrySetup />} />
        {/* Screen to select country, when setting up account */}
        <Route path="extra_details" element={<ExtraDetails />} />
        <Route path="personal_details" element={<PersonalDetails />} />
        <Route path="confirm_details" element={<ConfirmDetails />} />
        <Route path="confirmation" element={<Confirmation />} />
        <Route path="more_details" element={<MoreDetails />} />
        <Route path="funding_options" element={<FundingOptions />} />
        <Route path="signup_security_verification" element={<SignupSecurityVerification />} />
        <Route path="enter_pin" element={<EnterPin />} /> {/* Screen to enter pin when in 2fa enabled */}
        <Route path="confirm_email" element={<ConfirmEmail />} />{' '}
        {/* Screen to allow entering email if there is a need to verify email again */}
        <Route path="confirm_phone" element={<ConfirmPhone />} />{' '}
        {/* Screen to allow entering phone if there is a need to verify phone again */}
        <Route path="security_verification" element={<SecurityVerification />} />
        <Route path="forgot_password_verify_email" element={<ForgotPasswordVerifyEmail />} />
        <Route path="create_new_password" element={<CreateNewPassword />} />
        <Route path="create_pin" element={<CreatePin />} /> {/* Screen to create pin setting up account */}
        <Route path="take_picture" element={<TakePicture />} />
        <Route path="signup_completed" element={<SignupCompleted />} />
        <Route path="merchant_index" element={<MerchantIndex />} />
        <Route path="merchant_form" element={<MerchantForm />} />
      </Route>

      <Route path="dashboard" element={<DashboardLayout />}>
        <Route index path="home" element={<Home />} />
        <Route path="transaction" element={<Transaction />} />
        <Route path="exchange" element={<DashboardExchange />} />
        <Route path="rewards" element={<DashboardRewards />} />
        <Route path="p2p" element={<DashboardP2P />} />
        <Route path="p2p/buyer/:id" element={<BuyId />} />
        <Route path="p2p/seller/:id" element={<SellId />} />
        <Route path="bitcoin" element={<DashboardBitcoin />} />
        <Route path="Ethereum" element={<DashboardEthereum />} />
        <Route path="litecoin" element={<DashboardLitecoin />} />
        <Route path="dash" element={<DashboardDash />} />
        <Route path="company" element={<DashboardCompany />} />
        <Route path="about" element={<About />} />
        <Route path="add_money" element={<AddMoney />} />
        <Route path="top_up" element={<Topup />} />
        <Route path="redeem_voucher_index" element={<RedeemVoucherIndex />} />
        <Route path="enter_card_details" element={<EnterCardDetails />} />
        <Route path="redeem_voucher" element={<RedeemVoucher />} />
        <Route path="repeat_buy_index" element={<RepeatBuyIndex />} />
        <Route path="repeat_buy" element={<RepeatBuy />} />
        <Route path="scan_to_receive" element={<ScanToReceive />} />
        <Route path="create_address" element={<CreateAddress />} />
        <Route path="sell_index" element={<SellIndex />} />
        <Route path="sell" element={<Sell />} />
        <Route path="send_index" element={<SendIndex />} />
        <Route path="send" element={<Send />} />
        <Route path="enter_pin" element={<TransactionPin />} />
        <Route path="enter_pin_send" element={<TransactionPinSend />} />
        <Route path="check_email" element={<CheckEmail />} />
        <Route path="help_center" element={<Help />} />
        <Route path="settings" element={<SettingsLayout />}>
          <Route path="profile" element={<Profile />} />
          <Route path="security" element={<Security />} />
          <Route path="settings" element={<Settings />} />
          <Route path="help" element={<SettingsHelp />} />
          <Route path="security/2FA" element={<Two_FA />} />
          <Route path="security/2FA/phone" element={<PhoneVerification />} />
          <Route path="security/2FA/phone/otp" element={<PhoneVerificationOTP />} />
        </Route>
      </Route>

      <Route path="merchant" element={<MerchantLayout />}>
        <Route path="merchant_home" element={<MerchantHome />} />
        <Route path="merchant_p2p" element={<MerchantP2P />} />
        <Route path="merchant_settings" element={<MerchantSettings />} />
        <Route path="merchant_transaction" element={<MerchantTransaction />} />
      </Route>
    </Routes>
  );
};
export default AppRoutes;
