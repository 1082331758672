import React from "react";
import { CoinHeader } from "components/coins/CoinHeader";
import styles from 'styles/coins/coin.module.css';
import {formatCurrency} from 'utils/stringUtils';
import bitcoin from 'images/bitcoin.svg';
import ethereum from 'images/ethereum.svg';
import litecoin from 'images/litecoin.svg';
import dash from 'images/dash.svg';
import coinActivity from 'images/coinActivity.svg'
import { ActiveCoinCard, CoinCard } from "components/coins/CoinCard";
import { Link } from "react-router-dom";
import { CalculateCoinPrice } from "components/coins/CalculateCoinPrice";
import { CoinChart } from "components/coins/CoinChart";
import { CoinFaq } from "components/coins/CoinFaq";



const Ethereum = () => {

  const coin = {
    name: "Ethereum",
    abbrv: "ETH",
    symbol: ethereum,
    price: "1,860.59",
    activity: coinActivity,
    chart: {
      _1m: "1-month chart",
      _3m: "3-month chart",
      _6m: "6-month chart",
      _1y: "1-year chart",
      all: "All chart",
    },
  }

  return (
    <div className={styles.wrapper}>
      <CoinHeader coinName={coin.name} coinAbbrv={coin.abbrv} coinActivity={coin.activity} />

      <div className={styles.cardWrapperMobile}>
        <ul className={styles.coinList}>
          <Link className={styles.coinRoute} to="/coins/bitcoin"><li>BTC</li></Link>
          <Link className={`${styles.coinRoute} ${styles.isActive}`} to="/coins/ethereum"><li>ETH</li></Link>
          <Link className={styles.coinRoute} to="/coins/litecoin"><li>LTE</li></Link>
          <Link className={styles.coinRoute} to="/coins/dash"><li>DASH</li></Link>
        </ul>
        <div className={styles.activeCardContainer}>
          <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
        </div>
      </div>

      <div className={styles.cardWrapperDesktop}>
        <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
        <CoinCard coinName={"Ethereum"} coinAbbrv={"Ethereum"} coinSymbol={ethereum} coinPrice={"1,860.59"} />
        <CoinCard coinName={"Litecoin"} coinAbbrv={"LTE"} coinSymbol={litecoin} coinPrice={"90.31 "} />
        <CoinCard coinName={"Dash"} coinAbbrv={"DASH"} coinSymbol={dash} coinPrice={"55.56 "} />
      </div>

      <div className={styles.content}>
        {/* <CalculateCoinPrice coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={Number(coin.price)} /> */}
        <CoinChart chart1m={coin.chart._1m} chart3m={coin.chart._3m} chart6m={coin.chart._6m} chart1y={coin.chart._1y} chartAll={coin.chart.all} coinName={coin.name}/>

        <div className={styles.aboutCoin}>
          <h2>About Ethereum</h2>
          <p>Launched in 2015, Ethereum is a blockchain platform designed for executing smart contracts and enables anyone to build decentralised applications (dApps) on top of it without third party involvement. Developers can also use the Ethereum blockchain to issue new cryptocurrencies, known as Ethereum tokens.</p>
          <br/>
          <p>Like Bitcoin, Ethereum is an open-source project not owned or controlled by any individual or entity. This means that anyone can download the software and connect to the network. Ether (ETH) is the native cryptocurrency for the Ethereum platform that can be traded on exchanges like just like Bitcoin and also functions as the transaction fees for miners on the Ethereum network.
          </p>
        </div>

        <CoinFaq coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={coin.price} />
      </div>
    </div>
  );
};
export default Ethereum;
