import React, { useState } from "react";
import styles from "styles/auth/login.module.css";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { PrimaryInput } from "components/input/PrimaryInput";

const CreateNewPassword = () => {
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!email || !password) {
      setError(true);
      setErrorText("Please input all fields");
    }
    if (password !== "1111") {
      setError(true);
      setErrorText("Incorrect Password");
    }
    setError(false);
    setErrorText("");
  };
  return (
    <div className={styles.authContainer}>
      <h2>Create a new password</h2>
      <p className={styles.text}>
        Create a new strong password for your Truexgold account
      </p>
      <form className={styles.form}>
        <div className={styles.password}>
          <PrimaryInput
            label="Enter Password"
            type="password"
            error
          />
          <div className={styles.iconWWrapper}>
            <div className={styles.icon}>
              <IoEye />
            </div>
            <div className={styles.icon}>
              <IoEyeOff />
            </div>
          </div>
        </div>

        <div className={styles.password}>
          <PrimaryInput
            label="Confirm Password"
            type="password"
            error
          />
          <div className={styles.iconWWrapper}>
            <div className={styles.icon}>
              <IoEye />
            </div>
            <div className={styles.icon}>
              <IoEyeOff />
            </div>
          </div>
        </div>
        
        <button type="submit" className={styles.button}>
          <a href="/auth/login">Next</a>
        </button>
        
      </form>
    </div>
  );
};
export default CreateNewPassword;
