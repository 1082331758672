import { coingeckoAPI } from "api/coingeckoAPI";

export class coinsService {
    public static async getCoins() {
      try {
        return await coingeckoAPI.get('/coins/markets?vs_currency=ngn&ids=bitcoin%2Cethereum%2Clitecoin%2Cdash&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=24h&locale=en');
      } catch (e: any) {
        console.log(e.response);
        return e.response;
      }
    }
}
