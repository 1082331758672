import styles from "styles/features/feature1.module.css";

export const Feature1 = () => {
  const article = [
    {
      heading: "The most popular cryptocurrencies",
      desc: "Easy access to major cryptocurrencies including; Bitcoin, Litecoin, Ethereum, and Dash",
    },
    {
      heading: "Trade Instantly",
      desc: "Using the p2p connect system, deposit your fiat or crypto into your secure wallet on TurexGold. Buy, sell and Send assets within minutes, seamlessly.",
    },
    {
      heading: "Fast Deposit and Withdrawal",
      desc: "Our wide range of payment patners means you get your crypto as fast as possible.",
    },
    {
      heading: "Schedule Repeat Buys",
      desc: "Set up the repeat buy to automatically buy crypto based on your terms. Repeat buys use the buy price on TurexGold at the time of execution.",
    },
  ];


  return (
    <section className={styles.section}>
      <div className={styles.sectionHeading}>
        <h2 data-aos="fade-up" data-aos-duration="500">Buy and Sell Crypto</h2>
        <p data-aos="fade-up" data-aos-duration="1000" className={styles.headingSubText}>
          Experience the most convenient platform for instant trading of your
          Bitcoin and crypto assests.
        </p>
      </div>
      <article className={styles.article}>
        <div className={styles.overlay}>
          <div className={styles.articleContainer}>
            {article.map((item, index) => (
              <div key={index.toString()} className={styles.articleWrapper}>
                <span className={styles.listStyle}>
                  {/* <img src={listStyle} alt="" /> */}
                </span>
                <div data-aos="fade-up" data-aos-duration="1000" className={styles.articleText}>
                  <p className={styles.headingText}>{item.heading}: </p>
                  <span className={styles.descText}>{item.desc}</span>
                </div>
              </div>
            ))}
          </div>
          <div data-aos="fade-down" data-aos-duration="1500" className={styles.articleImage}></div>
        </div>
      </article>
    </section>
  );
};
