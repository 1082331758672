import React, { ReactNode } from 'react';
import styles from 'styles/modal/modalListItem.module.css';
import copyIcon from 'images/dashboard/copyIcon.svg';
import { IoMdCopy } from 'react-icons/io';

type Props = {
  containerStyle?: string;
  onclick?: () => void;
  icon: ReactNode;
  title?: string;
  desc?: string;
  descStyle?: string;
  copyText?: string;
  iconWrapperColor?: string;
  onCopy?: () => void;
};

export const ModalListItem: React.FC<Props> = (props) => {
  const { containerStyle, onclick, icon, title, desc, descStyle, copyText, iconWrapperColor, onCopy } = props;

  return (
    <div className={`${styles.wrapper} ${containerStyle}`}>
      <div className={styles.container} onClick={onclick}>
        <div
          style={{ backgroundColor: iconWrapperColor ? iconWrapperColor : '#CEA600' }}
          className={styles.iconWrapper}
        >
          {icon}
        </div>
        <div className={styles.textWrapper}>
          {title && <h5 className={styles.h5}>{title}</h5>}
          {desc && <p className={`${styles.p} ${descStyle}`}>{desc}</p>}
        </div>
      </div>
      {copyText && (
        <div className={styles.copyWrapper} onClick={onCopy}>
          <p className={styles.copyText}>{copyText}</p>
          <IoMdCopy size={20} className={styles.copyIcon} />
        </div>
      )}
    </div>
  );
};
