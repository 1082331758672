import React from 'react';
import baseStyles from 'styles/modal/buyModal.module.css';
import styles from 'styles/modal/successfulModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import thumbsUp from 'images/dashboard/thumbsUp.svg';

type Props = {
  expandSidebar: boolean;
  text?: string;
  onclose: () => void;
};

export const SuccessfulModal: React.FC<Props> = (props) => {
  const { expandSidebar, text, onclose } = props;

  return (
    <div className={expandSidebar ? baseStyles.overlay2 : baseStyles.overlay}>
      <div className={baseStyles.container}>
        <div className={baseStyles.closeIcon} onClick={onclose}>
          <IoCloseOutline className={baseStyles.icon} />
        </div>
        <div className={styles.content}>
          <img src={thumbsUp} alt="thumbs-up" className={styles.success} />
          <h4 className={styles.h4}>Successful</h4>
          <p className={styles.p}>{text || 'Your payment was successful'}</p>
        </div>
      </div>
    </div>
  );
};
