import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "styles/header/dashboardHeader.module.css";
import "animate.css";
import { IoSettingsOutline } from "react-icons/io5";
import { BsFillBellFill } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";

type Props = {
  expandSidebar: boolean;
}

export const DashboardHeader: React.FC<Props> = ({ expandSidebar }) => {
    const navigate = useNavigate();
  const notificationCount = 109;
    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('ACCESS_TOKEN');
        // Redirect to the login page
        navigate('/auth/login');
    };
  return (
    <header className={expandSidebar ? styles.header : styles.header2}>
      <div className={styles.menuWrapper}>
        <p className={styles.nameText}>TruexGold</p>
        <div className={styles.line}></div>
        <div className={styles.utilities}>
          <Link
            to={"/dashboard/notifications"}
            className={`${styles.bellWrapper} ${styles.link}`}
          >
            <BsFillBellFill className={styles.icon} />
            <div
              className={
                notificationCount > 100
                  ? styles.notification2
                  : styles.notification
              }
            >
              <p className={styles.text}>
                {notificationCount > 100 ? "100+" : notificationCount}
              </p>
            </div>
          </Link>
          <Link to={"/dashboard/settings/profile"} className={styles.link}>
            <IoSettingsOutline className={styles.icon} />
          </Link>
          <button className={styles.link} onClick={handleLogout}>
            <MdOutlineLogout className={styles.icon} />
          </button>
        </div>
      </div>
    </header>
  );
};
