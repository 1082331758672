import React from "react";
import styles from '../../styles/company/divider.module.css';

export const Divider = () => {
  return (
    <div className={styles.dividerContainer}>
      <div className={styles.line}></div>
      <div className={styles.circle}></div>
    </div>
  );
};
