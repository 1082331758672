import React, {useState} from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField/TextField";
import styles from "styles/input/dropdownInput.module.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "#ffffff !important",
            padding: "10px !important",
            margin: "5px 0 3px",
          },
          "& .MuiInputLabel-root": {
            color: "#ffffff !important",
            fontFamily: `'Poppins', sans-serif`,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ffffff !important",
              height: '58px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          },
          },
          "&:hover fieldset": {
            borderColor: "#ffffff !important",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#ffffff !important",
          },
          '& .MuiSelect-icon': {
            color: '#ffffff',
          },
          '& .MuiSelect-nativeInput': {
            color: '#ffffff',
            height: '5px'
          }
        },
      },
    },
  },
});
interface CountryCodeDropdownProps extends Omit<TextFieldProps, 'onChange'> {
    value: string;
    onChange: (value: string) => void;
}
export const CountryCodeDropdown: React.FC<CountryCodeDropdownProps> = ({
    value,
    onChange,
    ...props
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const currencies = [
    {
      label: "NGA",
      value: "+234",
    },
    {
      label: "USA",
      value: "+1",
    },
    {
      label: "CAN",
      value: "+1",
    },
    {
      label: "AUS",
      value: "+61",
    },
    {
      label: "CHN",
      value: "+86",
    },
    {
      label: "DEU",
      value: "+49",
    },
  ];

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };


  return (
    <div className={styles.inputWrapper}>
      <ThemeProvider theme={theme}>
        <TextField
        // @ts-ignore
        label={selectedOption ? currencies?.find(option => option.value === selectedOption).label : 'NGA'}
          variant="outlined"
          margin="normal"
          select
          value={selectedOption || '+234'}
          onChange={handleChange}
          inputProps={{
            autoComplete: "off",
          }}
          SelectProps={{
            MenuProps: {
              classes: { paper: styles.select }
            },
          }}
          fullWidth
          {...props}
          >
          {currencies.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
};
