import React, { ReactNode } from 'react';
import styles from 'styles/dashboard/primaryPageLayout.module.css';

type Props = {
  containerStyle?: string;
  icon?: string | ReactNode;
  title: string;
  desc?: string;
  descStyle?: string;
  children?: ReactNode;
  buttonStyle?: string;
  buttonText?: string;
  onclick?: () => void;
  linkText?: string;
  linkPath?: string;
  linkText2?: string;
  linkPath2?: string;
};

export const PrimaryPageLayout: React.FC<Props> = (props) => {
  const { containerStyle, children, icon, title, desc, descStyle, buttonStyle, buttonText, onclick, linkText, linkPath, linkText2, linkPath2 } =
    props;
console.log(typeof(icon))

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.heading}>
        {icon && (
          <div className={styles.iconWrapper}>
            {typeof(icon) == 'string' && <img src={icon} alt="icon" className={styles.icon} />}
            {typeof(icon) == 'object' && <div className={styles.iconObject}>{icon}</div>}
          </div>
        )}
        <h2 className={styles.h2}>{title}</h2>
        {desc && <p style={{ maxWidth: descStyle ? descStyle : "550px"}} className={styles.p}>{desc}</p>}
      </div>
      <div style={{width: '100%'}}>
        {children}
      </div>
      <div className={`${styles.linkWrapper} ${buttonStyle}`}>
        {buttonText && (
          <button className={styles.button} onClick={onclick}>
            <p>{buttonText}</p>
          </button>
        )}
        {linkText && <a href={linkPath} className={styles.button}>
          {linkText}
        </a>}
        {linkText2 && <a href={linkPath2} className={styles.link}>{linkText2}</a>}
      </div>
    </div>
  );
};
