import {truexgoldAPI} from 'api/truexgoldAPI';

export class globalService {


    public static async getCountries() {
        try {
            return await truexgoldAPI.get('/global/countries');
        } catch (e: any) {
            console.log(e.response);
            return e.response;
        }
    }

}
