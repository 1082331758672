import React from 'react';
import { TraderInfo } from 'components/dashboard/TraderInfo';
import { useLocation } from 'react-router-dom';

export const SellIndex: React.FC = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <TraderInfo data={data} step1={'Pending payment'} step2={'Release Crypto'} step3={'Completed'}  />
  );
};

export default SellIndex;
