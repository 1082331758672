import React from 'react';
import styles from 'styles/dashboard/buyId.module.css';
import { useLocation } from 'react-router-dom';
import { IoMdSend } from 'react-icons/io';
import dayjs from 'dayjs';
import { FaCheckCircle } from 'react-icons/fa';
import { formatAmount } from 'utils/stringUtils';
import { PrimaryCheckbox } from 'components/input/PrimaryCheckbox';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAttachFile, MdVerified } from 'react-icons/md';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { useOutletContext } from 'react-router-dom';
import { Copy } from 'components/Copy';

type Props = {
    data: any;
    step1: string;
    step2: string;
    step3: string;
}

export const TraderInfo: React.FC<Props> = (props) => {
  const { data, step1, step2, step3 } = props;
  const confrimationCode = '5689';
  const orderNumber = '5832010101011818181811711111';
  const referenceNumber = '5039383322525272790890';
  const bankAccountNumber = '7444858479';
  const orderDate = dayjs().format('YYYY-MM-DD. HH:MM');
  const paymentMethods = ['Bank Transfer', 'Card', 'In-App'];
  const showSidebar: boolean = useOutletContext();
  const traderType = data.transactionType == 'buy' ? 'seller' : 'buyer';

  return (
    <main className={styles.container}>
      <header className={showSidebar ? `${styles.header} ${styles.header2}` : styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.headerLeftTop}>
            <div style={{ backgroundColor: data.transactionType == 'buy' ? '#1cd16f' : '#ff0100'}} className={styles.txnTypeText}>
              <p>{data.transactionType == 'buy' ? 'BUY' : 'SELL'}</p>
            </div>
            &nbsp;&nbsp;
              {data.transactionType == 'buy' && <p style={{ fontSize: '1.2rem' }}> Buy {data.currencyTraded} from {data.name}</p>}
              {data.transactionType == 'sell' && <p style={{ fontSize: '1.2rem' }}>Sell {data.currencyTraded} to {data.name}</p>}
          </div>
          <div className={styles.headerLeftBottom}>
            <p className={styles.greyText}>This order is created, please wait for system confirmation&nbsp;&nbsp;</p>
            <p className={styles.code}>
              {confrimationCode.split('').map((code: string, index: number) => (
                <span key={index.toString()}>{code}</span>
              ))}
            </p>
          </div>
        </div>

        <div className={showSidebar ? `${styles.headerRight} ${styles.headerRight2}` : styles.headerRight}>
          <div className={showSidebar ? `${styles.headerRightTop} ${styles.headerRightTop2}` : styles.headerRightTop}>
            <p className={styles.greyText}>Order Number:&nbsp;&nbsp;</p>
            <div className={styles.info}>
              <p>{orderNumber}</p>&nbsp;
              <Copy copyText={orderNumber} size={18} iconStyle={styles.copyIcon} />
            </div>
          </div>
          <div
            className={
              showSidebar ? `${styles.headerRightBottom} ${styles.headerRightBottom2}` : styles.headerRightBottom
            }
          >
            <p className={styles.greyText}>Time created:&nbsp;&nbsp;</p>
            <p>{orderDate}</p>
          </div>
        </div>
      </header>

      <section className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.txnProcess}>
            <div className={styles.process}>
            <p>{step1}</p>&nbsp;&nbsp;
              <FaCheckCircle color={'#17791B'} />
            </div>
            <div className={styles.line}></div>
            <div className={styles.process}>
              <p style={{ color: '#ffffff50' }}>{step2}</p>&nbsp;&nbsp;
              <FaCheckCircle color={'#ffffff50'} />
            </div>
            <div className={styles.line}></div>
            <div className={styles.process}>
              <p style={{ color: '#ffffff50' }}>{step3}</p>&nbsp;&nbsp;
              <FaCheckCircle color={'#ffffff50'} />
            </div>
          </div>

          <div className={styles.orderInfo}>
            {data.transactionType == 'buy' && <p>Confirm Order Info</p>}
            <div className={data.transactionType == 'buy' ? `${styles.detailsWrapper} ${styles.detailsWrapperBuy}` : `${styles.detailsWrapper} ${styles.detailsWrapperSell}`}>
              <div className={styles.details}>
                <p className={styles.greyTextSmall}>Amount</p>
                <p style={{ color: data.transactionType == 'buy' ? '#1cd16f' : '#ff0100'}} className={styles.detailsText}>N {formatAmount(17949)}</p>
              </div>

              <div className={styles.details}>
                <p className={styles.greyTextSmall}>Price</p>
                <p className={styles.detailsText}>N {formatAmount(17949)}</p>
              </div>

              <div className={styles.details}>
                <p className={styles.greyTextSmall}>Quantity</p>
                <p className={styles.detailsText}>
                  {formatAmount(20)}&nbsp;{data.currencyTraded}
                </p>
              </div>
            </div>
          </div>

          <div style={{ maxWidth: data.transactionType == 'buy' ? '' : '582px'}} className={styles.paymentInfo}>
            { data.transactionType == 'buy' && <div style={{ margin: '10px 0' }}>
              <p style={{ lineHeight: '25px' }}>Transfer funds to the seller account below</p>
              <p style={{ marginTop: '10px' }} className={styles.greyTextSmall}>
                Please transfer from an account that has your TruexGold name
              </p>
            </div>}

            { data.transactionType == 'sell' && <div className={styles.sellTxferHeading}>
              <p style={{ lineHeight: '25px' }} className={styles.greyTextSmall}>Payment Method</p>
              <p className={`${styles.greyTextSmall} ${styles.viewText}`}>View all</p>
            </div>}
            <div className={data.transactionType == 'buy' ? `${styles.detailsWrapper2} ${styles.detailsWrapper2Buy}` : `${styles.detailsWrapper2} ${styles.detailsWrapper2Sell}`}>
              {data.transactionType == 'buy' && <div className={styles.paymentMethod}>
                {paymentMethods.map((paymentMethod, index) => (
                  <PrimaryCheckbox key={index.toString()} label={paymentMethod} />
                ))}
              </div>}

              <div className={data.transactionType == 'buy' ? `${styles.accountInfoWrapper} ${styles.accountInfoWrapperBuy}` : `${styles.accountInfoWrapper} ${styles.accountInfoWrapperSell}`}>
                <div className={data.transactionType == 'buy' ? `${styles.accountInfo} ${styles.accountInfoBuy}` : `${styles.accountInfo} ${styles.accountInfoSell}`}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className={styles.greyTextSmall}>Reference number&nbsp;</p>
                    <AiOutlineInfoCircle />
                  </div>
                  <div className={styles.info2}>
                    <p>{referenceNumber} &nbsp;</p>
                    <Copy copyText={referenceNumber} iconStyle={styles.copyIcon2} />
                  </div>
                </div>

                <div className={data.transactionType == 'buy' ? `${styles.accountInfo} ${styles.accountInfoBuy}` : `${styles.accountInfo} ${styles.accountInfoSell}`}>
                  <p className={styles.greyTextSmall}>Bank account number</p>
                  <div className={styles.info2}>
                    <p>{bankAccountNumber}&nbsp;</p>
                    <Copy copyText={bankAccountNumber} iconStyle={styles.copyIcon2} />
                  </div>
                </div>

                {data.transactionType == 'sell' && <div className={data.transactionType == 'buy' ? `${styles.accountInfo} ${styles.accountInfoBuy}` : `${styles.accountInfo} ${styles.accountInfoSell}`}>
                  <p className={styles.greyTextSmall}>Payment Method</p>
                  <div className={styles.info2}>
                    <p>{bankAccountNumber}&nbsp;</p>
                    <Copy copyText={bankAccountNumber} iconStyle={styles.copyIcon2} />
                  </div>
                </div>}

                <div className={data.transactionType == 'buy' ? `${styles.accountInfo} ${styles.accountInfoBuy}` : `${styles.accountInfo} ${styles.accountInfoSell}`}>
                  <p className={styles.greyTextSmall}>Bank name</p>
                  <div className={styles.info2}>
                    <p>{data.banks[1]}&nbsp;</p>
                    <Copy copyText={data.banks[1]} iconStyle={styles.copyIcon2} />
                  </div>
                </div>

                <div className={data.transactionType == 'buy' ? `${styles.accountInfo} ${styles.accountInfoBuy}` : `${styles.accountInfo} ${styles.accountInfoSell}`}>
                  <p className={styles.greyTextSmall}>Name</p>
                  <div className={styles.info2}>
                    <p>{data.name}&nbsp;</p>
                    <Copy copyText={data.name} iconStyle={styles.copyIcon2} />
                  </div>
                </div>

                

                
              </div>
            </div>
          </div>
        </div>

        <div className={styles.contentRight}>
          <div className={styles.msgContentTop}>
            <div className={styles.msgHeading}>
              <div className={styles.initial}>
                <p>{data.name[0]}</p>
              </div>
              <div className={styles.headingContent}>
                <div className={styles.topHeading}>
                  <p style={{ fontSize: '1.1rem' }}>{data.name}</p>
                  <div className={styles.buyerInfo}>
                    <p className={styles.buyerDDess}>
                      Verified Merchant &nbsp;
                      <MdVerified className={styles.verifiedIcon} />
                      &nbsp;
                    </p>

                    <a target="_blank" href="#">
                      Report
                    </a>
                  </div>
                </div>

                <div className={styles.bottomHeading}>
                  <div className={styles.tradeInfo}>
                    <p>30d Trades</p>
                    <p>{data.numberOfTrades}</p>
                  </div>

                  <div className={styles.tradeInfo}>
                    <p>Completion Rates</p>
                    <p>{data.successRate}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.notice}>
              <p>
                <span style={{ fontWeight: 600 }}>Notice:&nbsp;</span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum
              </p>
            </div>
          </div>

          <div className={styles.msgContentBottom}>
            <PrimaryInput placeholder="Type in your message..." textFieldStyle={styles.msgInput} />
            <div className={styles.msgButtons}>
              <MdAttachFile color={'#fff'} size={30} className={styles.msgIcon1} />
              <IoMdSend color={'#fff'} size={30} className={styles.msgIcon2} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.footNote}>
        <p>After transferring the funds, click on the “Transferred, notify {traderType}” button.</p>
        <div className={styles.buttonContainer}>
          <button className={styles.filledButton}>
            <p>Transferred, notify {traderType}</p>
          </button>
          <button className={styles.linkButton}>
            <p>Cancel Transaction</p>
          </button>
        </div>
      </section>
    </main>
  );
};
