import React from 'react';
import TextField from '@mui/material/TextField/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from '../../styles/input/primaryInput.module.css';
import InputAdornment from '@mui/material/InputAdornment';
import { LuSearch } from 'react-icons/lu';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black !important',
            height: '30px',
            padding: '0 10px',
            margin: '5px 0 8px',
          },
          '& .MuiInputLabel-root': {
            color: '#86858B !important',
            fontFamily: `'Poppins', sans-serif`,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              color: 'black !important',
              borderColor: '#ffffff !important',
              backgroundColor: 'white',
            },
            '& input': {
              color: 'black',
              padding: '5px 0',
              backgroundColor: 'white',
              zIndex: 3,
              '&:-webkit-autofill': {
                width: '90%',
              },
            },
          },
          '&:hover fieldset': {
            borderColor: '#ffffff !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff !important',
          },
          '&.MuiIconButton-root': {
            color: '#fff',
          },
        },
      },
    },
  },
});

type Props = {
  textFieldStyle?: any;
};

export const SearchInput: React.FC<Props & TextFieldProps> = ({ textFieldStyle, ...props }) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          {...props}
          className={`${styles.input} ${textFieldStyle}`}
          InputProps={{
            startAdornment: <InputAdornment position="start">
                <LuSearch color={'#86858B'} size={25} style={{ zIndex: 2, paddingBottom: '5px'}} />
            </InputAdornment>,
          }}
        />
      </ThemeProvider>
    </div>
  );
};
