import React from "react";
import styles from "styles/rewards/rewardsGrow.module.css";
import "animate.css";
import { Link } from "react-router-dom";
import image from 'images/reward2.svg';

const RewardsGrow = () => {
  return (
    <section className={styles.wrapper}>
          <h3 className={`${styles.bold} ${"animate__animated animate__zoomIn"}`}>
          Watch your rewards grow</h3>
          <div className={`${styles.rewardsImage} ${"animate__animated animate__slideInRight"}`}> 
            <img className={styles.img} src={image} alt="reward-earn" />
          </div>     
          <div className={styles.info}>   
            <p className="animate__animated animate__slideInUp">
            Track the progress of the rewards you've earned and redeemed.

            </p>
            <p className="animate__animated animate__slideInUp">
              After 30 days, your rewards will be automatically added to your Bitcoin wallet.
            </p>
            <p className="animate__animated animate__slideInUp">
              We’ll let you know as soon as they’re available.
            </p>
          </div>
          <div className={styles.buttons}>
            <Link to="/products/rewards/rewards-home" className={`${styles.colouredLink} ${"animate__animated animate__slideInRight"}`}>Got It</Link>
            <div className={styles.dotWrapper}>
              <div className={styles.dot}></div>
              <div style={{backgroundColor:'#070630'}} className={styles.dot}></div>
            </div>
          </div>
    </section>
  );
};

export default RewardsGrow;
