import React, {useEffect, useState} from 'react';
import {SettingsMenu} from 'components/settings/SettingsMenu';
import {Outlet, useOutletContext} from 'react-router-dom';
import styles from 'styles/settings/layout.module.css';
import {User} from 'interfaces/User';
import {userService} from "services/userService";
import Overlay from "../../Overlay";

const SettingsLayout = () => {
  const showSidebar = useOutletContext();
    const [user, setUser] = useState<User | null>(null);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setShowOverlay(true);
                const response = await userService.userDetails();
                setUser(response.data);
                setShowOverlay(false);
            } catch (err) {
                setShowOverlay(false);
            }
        };

        fetchUserData();
    }, []);



  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.menu}>
          <SettingsMenu
            expandSidebar={false}
            handleExpandedSidebar={function (toggleState: any): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>
        <main className={styles.main}>
          <Outlet context={user} />
        </main>
      </div>
        {showOverlay && <Overlay />}
    </div>
  );
};
export default SettingsLayout;
