import React from "react";
import styles from 'styles/dashboard/help.module.css';
import reward from 'images/dashboard/reward.svg';

export const DashboardRewards:React.FC = () => {
  return (
   <div className={styles.rewardContainer}>
    <img src={reward} alt="reward" />
   </div>
  );
};

export default DashboardRewards;