import React from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/confirmDetails.module.css";
import { PrimaryCheckbox } from "components/input/PrimaryCheckbox";

const FundingOptions: React.FC = () => {

  return (
    <div className={`${baseStyles.authContainer} ${styles.authContainer}`}>
      <h2>How will you fund your TruexGold account?</h2>
      <p className={baseStyles.text}>Commomly known as source of funds. Select all that apply to you</p>

      <form className={`${baseStyles.form} ${styles.checkboxWrapper}`}>
        <PrimaryCheckbox label={'Allowance'} />
        <PrimaryCheckbox label={'Interest or dividends'} />
        <PrimaryCheckbox label={'Loans'} />
        <PrimaryCheckbox label={'Employment income'} />
        <PrimaryCheckbox label={'Pension or social benefits'} />
        <PrimaryCheckbox label={'Rental income'} />
        <PrimaryCheckbox label={'Savings'} />

        <div className={`${baseStyles.button} ${styles.button}`}>
          <a href="/auth/take_picture">Next</a>
        </div>
      </form>
    </div>
  );
};

export default FundingOptions;
