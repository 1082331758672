import React from 'react';
import styles from 'styles/modal/buyModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { ModalListItem } from './ModalListItem';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCurrencyLitecoin } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { SiDash } from 'react-icons/si';

type Props = {
  expandSidebar: boolean;
  onclose: () => void;
  onPressItem: () => void;
};

export const ChooseCryptoModal: React.FC<Props> = (props) => {
  const { expandSidebar, onclose, onPressItem } = props;

  return (
    <div className={expandSidebar ? styles.overlay2 : styles.overlay}>
      <div className={styles.container}>
        <div className={styles.closeIcon} onClick={onclose}>
          <IoCloseOutline className={styles.icon} />
        </div>
        <div className={styles.content}>
          <h4 className={styles.h4}>Choose Crypto</h4>
          <ModalListItem
            onclick={onPressItem} descStyle={styles.coinDesc}
            icon={<BsCurrencyBitcoin className={styles.coinIcon} />}
            desc={'Bitcoin'}
          />
          <ModalListItem onclick={onPressItem} descStyle={styles.coinDesc} icon={<FaEthereum className={styles.coinIcon} />} desc={'Ethereum'} />
          <ModalListItem
            onclick={onPressItem} descStyle={styles.coinDesc}
            icon={<TbCurrencyLitecoin className={styles.coinIcon} />}
            desc={'Litecoin'}
          />
          <ModalListItem onclick={onPressItem} descStyle={styles.coinDesc} icon={<SiDash className={styles.coinIcon} />} desc={'Dash'} />
        </div>
      </div>
    </div>
  );
};
