import React from "react";
import styles from "../../styles/features/feature4.module.css";
import  featureImage from "../../images/bitcoin-screenshot.svg"

export const Feature4 = () => {
  
  const feature4Outline = [
    {
      heading: "Securely store",
      desc: 'For industry-leading security, keep your cryptocurrency in a TruexGold Wallet. TruexGold stores 95% of its crypto in "deep freeze" wallets, which are multi-signature wallets protected by multiple layers of encryption. This assures that your coin remains yours.',
    },
    {
      heading: "Send and receive with no fees",
      desc: "Sending crypto? If you have a friend or family member who has a TruexGold account, you can send bitcoin or your preferred cryptocurrency to them for free.",
    },
  ];

  return (
    <section className={styles.feature4section}>
      <div className={styles.featureContainer}>
        {feature4Outline.map((item, index) => (
          <div data-aos="fade-right" data-aos-duration="1000" key={index.toString()} className={styles.featureWrapper}>
            <span className={styles.feature4ListIcon}></span>
            <div className={styles.featureText}>
              <p className={styles.feature4HeadingText}>{item.heading}: </p>
              <span className={styles.feature4DescText}>{item.desc}</span>
            </div>
          </div>
        ))}
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" className={styles.feature4ImageWrapper}>
        <img className={styles.feature4Image} src={featureImage} alt="" />
      </div>
    </section>
  );
};
