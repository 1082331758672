import { truexgoldAPI, truexgoldRootAPI } from 'api/truexgoldAPI';
import { SignupData } from 'interfaces/SignupData';
import { LoginData } from 'interfaces/LoginData';

export class authService {
  public static async getCsrfToken(): Promise<void> {
    try {
      await truexgoldRootAPI.get('/sanctum/csrf-cookie');
    } catch (e: any) {
      console.error('Error fetching CSRF token:', e.response?.data || e.message);
      throw e; // Rethrow to allow further handling if necessary
    }
  }

  public static async signUp(signUpData: SignupData) {
    try {
      return await truexgoldAPI.post('/auth/register/', signUpData);
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }

  public static async verifyEmailCode(tokenData: { token: any }) {
    try {
      return await truexgoldAPI.post('/user/verify-email-with-token', tokenData);
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }

  public static async resendEmailCode(emailData: { email: string }) {
    try {
      return await truexgoldAPI.post('/user/token/send-email', emailData);
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }

  public static async resendPhoneCode(phoneData: { phone: string }) {
    try {
      return await truexgoldAPI.post('/user/token/send-phone', phoneData);
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }

  public static async verifyPhoneCode(tokenData: { token: any }) {
    try {
      return await truexgoldAPI.post('/user/verify-phone-with-token', tokenData);
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }

  public static async login(loginData: LoginData) {
    try {
      //  await authService.getCsrfToken();

      // await new Promise((resolve) => setTimeout(resolve, 1000));

      console.log('All Cookies:', document.cookie);
      const xsrfToken = document.cookie.match(/XSRF-TOKEN=([^;]*)/)?.[1] || '';
      console.log('CSRF: ' + xsrfToken);
      return await truexgoldAPI.post('/auth/login/', loginData, {
        /* headers: {
                   'X-XSRF-TOKEN': xsrfToken,
                 },
                 withCredentials: true,*/
      });
    } catch (e: any) {
      console.log(e.response);
      return e.response;
    }
  }
}
