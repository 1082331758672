import React, { useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import styles from 'styles/auth/login.module.css';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { PasswordInput } from 'components/input/PasswordInput';
import { authService } from 'services/authService';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';
import Overlay from '../../Overlay';
import { truexgoldAPI } from 'api/truexgoldAPI';

const Signup = () => {
  const navigate = useNavigate();
  const { setUser, setToken } = useStateContext();
  const [errorText, setErrorText] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password_confirmation, setConfirmpassword] = useState<string>('');
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z]).{6,}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrorText(null);
    setShowOverlay(true);

    if (!username || !email || !password || !password_confirmation) {
      setErrorText('Please input all fields');
      setShowOverlay(false);
      return;
    }

    if (!emailRegex.test(email)) {
      setErrorText('Please enter a valid email');
      setShowOverlay(false);
      return;
    }

    if (!passwordRegex.test(password)) {
      setErrorText('Password does not meet the requirement');
      setShowOverlay(false);
      return;
    }
    setErrorText(null);

    // If both fields are not empty and in valid formats, proceed with form submission
    const data = { username, email, password, password_confirmation };

    const response = await authService.signUp(data);

    const resp = response?.data;
    try {
      if (resp && resp.data && resp.data.success) {
        await localStorage.setItem('@userEmail', JSON.stringify(resp.data.email));
        setShowOverlay(true);
        const loginresponse = await truexgoldAPI.post('/auth/login', { email, password });
        const logindata = loginresponse.data.data;
        const token = logindata.token;
        const expires_in = logindata.expires_in;
        const expirationTime = Date.now() + expires_in * 1000;
        localStorage.setItem('ACCESS_TOKEN', token);
        localStorage.setItem('TOKEN_EXPIRATION', expirationTime.toString());
        setShowOverlay(false);
        navigate('/auth/signup_verify_email');
      } else if (resp.message) {
        setErrorText(resp?.message);
      } else {
        setErrorText('Error creating user, please try again');
      }
    } catch (error: any) {
      setError(true);
      setShowOverlay(false);
      if (error.response && error.response.status === 422) {
        setErrorText(error.response.data.message || 'An error occurred');
      } else if (error.response && error.response.status === 403) {
        setErrorText(error.response.data.message || 'An error occurred');
      } else {
        setErrorText('An error occurred. Please try again later.');
      }
    }
    setShowOverlay(false);
  };

  return (
    <div className={baseStyles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Create your account'}</h2>
      <form className={baseStyles.form} onSubmit={(e) => handleSubmit(e)}>
        <PrimaryInput label="Username" onChange={(e) => setUsername(e.target.value)} error={error} />
        <PrimaryInput label="Email" onChange={(e) => setEmail(e.target.value)} error={error} />
        <div className={baseStyles.password}>
          <PasswordInput
            label="Password"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            error={error}
          />
        </div>
        <div className={baseStyles.password}>
          <PasswordInput
            label="Confirm Password"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => setConfirmpassword(e.target.value)}
            type="password"
            error={error}
          />
        </div>
        <p className={baseStyles.info}>Use at least 8 characters, 1 number & 1 upper case letter</p>
        <button disabled={showOverlay} type="submit" className={baseStyles.button}>
          <p className={styles.buttonText}>Next</p>
        </button>

        <div className={baseStyles.legal}>
          <p className={baseStyles.info}>
            By signing up, I confirm I accept the{' '}
            <a href="/legal/terms_of_use" className={baseStyles.colouredText}>
              terms of use
            </a>
            , and consent to the processing of my personal and biometric data as stated in the{' '}
            <a href="/legal/privacy_policy" className={baseStyles.colouredText}>
              privacy policy
            </a>
          </p>
        </div>

        <div className={baseStyles.textWrapper}>
          <p>
            Already have an account?&nbsp;
            <a href="/auth/login" className={baseStyles.colouredText}>
              Login
            </a>
          </p>
          {/* <div className={baseStyles.emailLogin}>
            <FcGoogle />
            <a href="https:/www.gmail.com/login">Create account with Gmail</a>
          </div> */}
        </div>
      </form>
      {showOverlay && <Overlay />}
    </div>
  );
};
export default Signup;
