import React from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/confirmPhone.module.css";
import { PrimaryInput } from "components/input/PrimaryInput";


const ConfirmEmail:React.FC = () => {
  return (
    <div className={baseStyles.authContainer}>
      <h2>Confirm Email</h2>
      <p className={baseStyles.text}>Enter the email you use to login to your Truexgold account</p>
      <form className={baseStyles.form}>
          <PrimaryInput 
          label="Email"
          type="email" 
          />
        <div className={baseStyles.button}>
          <a href="/auth/forgot_password_verify_email">Next</a>
        </div>

        <div className={baseStyles.textWrapper}>
          <p>Don't have an account?<a href="/auth/signup" className={baseStyles.colouredText}> Sign up</a></p>
          {/* <div className={baseStyles.emailLogin}>
            <FcGoogle />
            <a href="https:/www.gmail.com/login">Login using Gmail</a>
          </div> */}
        </div>
      </form>
    </div>
  );
};
export default ConfirmEmail;
