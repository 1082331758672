import React, { useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { CountryCodeDropdown } from 'components/input/CountryCodeDropdown';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { User } from 'interfaces/User';
import Overlay from '../../Overlay';
import { authService } from 'services/authService';
import { userService } from 'services/userService';
import { notify } from 'utils/index';

const EnterPhone: React.FC = () => {
  const navigate = useNavigate();
  const user: User = useOutletContext<User>();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [userphone, setUserphone] = useState<string>('');
  const [usercountrycode, setUsercountrycode] = useState<string>('+234');

  const handleSubmit = async () => {
    setErrorText(null);
    setShowOverlay(true);
    if (!userphone) {
      setErrorText('Please enter a number');
      notify.error('Error: Please enter a number');
      setShowOverlay(false);
      return;
    }
    try {
      const intuserphone = usercountrycode + userphone.replace(/^0/, '');

      const response = await userService.updateUser({ phone: intuserphone });
      const respC = response?.data;
      if (respC && respC.data && respC.data.success) {
        if (user?.profile.is_complete) {
          navigate('/dashboard/home');
        } else {
          try {
            const responsePhoneSendCode = await authService.resendPhoneCode({ phone: intuserphone });
            const resp = responsePhoneSendCode?.data;
            if (resp && resp.data && resp.data.success) {
              if (user?.profile.is_complete) {
                navigate('/dashboard/home');
              } else {
                if (!user?.phone_verified_at) {
                  navigate('/auth/signup_verify_phone');
                }
              }
            } else if (resp.message) {
              setErrorText(resp?.message);
              notify.error(resp?.message);
            } else {
              setErrorText('Error sending phone code.');
              notify.error('Error sending phone code.');
            }
          } catch (error) {
            console.error('Failed to send phone code', error);
            setErrorText('Failed to send phone code');
            notify.error('Failed to send phone code');
          }
        }
      } else if (respC.message) {
        setErrorText(respC?.message);
        notify.error(respC?.message);
      } else {
        setErrorText('Error updating phone number.');
        notify.error('Error updating phone number.');
      }
    } catch (error) {
      console.error('Failed updating phone number', error);
      setErrorText('Failed updating phone number');
      notify.error('Failed updating phone number');
    }

    setShowOverlay(false);
  };

  return (
    <div className={baseStyles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Enter your phone number'}</h2>
      <p className={baseStyles.text}>We will use this to send you security alerts</p>
      <form style={{ flexDirection: 'row' }} className={baseStyles.form}>
        <div className={baseStyles.inputs}>
          <div className={baseStyles.dropdown}>
            <CountryCodeDropdown onChange={(value) => setUsercountrycode(value)} error={error} value={''} />
          </div>
          <div className={baseStyles.input}>
            <PrimaryInput
              label="Mobile Number"
              type="phone"
              onChange={(e) => setUserphone(e.target.value)}
              error={error}
            />
          </div>
        </div>
        <div className={baseStyles.button} onClick={handleSubmit}>
          Next
        </div>
      </form>
      {showOverlay && <Overlay />}
    </div>
  );
};
export default EnterPhone;
