import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from 'styles/auth/layout.module.css';
import logo from 'images/truexgoldLogo.svg';
import 'animate.css';
import { ContextProvider } from 'context/ContextProvider';
import { userService } from 'services/userService';
import Overlay from '../../Overlay';
import { User } from 'interfaces/User';

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [useremail, setUseremail] = useState<string>('');
  const [userphone, setUserphone] = useState<string>('');
  const [errorText, setErrorText] = useState<string | null>(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    const tokenExpiration = localStorage.getItem('TOKEN_EXPIRATION');

    if (accessToken && tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration);
      if (Date.now() < expirationTime) {
        const fetchUserData = async () => {
          try {
            setShowOverlay(true);
            const response = await userService.userDetails();
            setUser(response.data);
            console.log('User Object: ' + user);
          } catch (err) {
            console.log('Err Object: ' + err);
            setShowOverlay(false);
          } finally {
            console.log('Finaly reached: ');
            setShowOverlay(false);
          }
        };

        fetchUserData();
      }
    }
  }, []);

  return (
    <ContextProvider>
      <div className={styles.container}>
        <div className={styles.logo}>
          <a href="/">
            <img src={logo} alt="truexgold-logo" className="animate__animated animate__fadeInLeft" />
          </a>
        </div>
        <div className={styles.outlet}>
          <Outlet context={user} />
        </div>
        {showOverlay && <Overlay />}
      </div>
    </ContextProvider>
  );
};
export default AuthLayout;
