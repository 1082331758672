import React, { ReactNode } from "react";
import styles from "styles/dashboard/primaryBg.module.css";

type Props = {
  children: ReactNode;
  bgStyle?: any;
};


export const PrimaryBg: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({ children, bgStyle, ...props }) => {
  return (
    <div className={`${styles.bg} ${bgStyle}`} {...props}>
      {children}
    </div>
  );
};
