import React from "react";
import { CoinHeader } from "components/coins/CoinHeader";
import styles from 'styles/coins/coin.module.css';
import {formatCurrency} from 'utils/stringUtils';
import bitcoin from 'images/bitcoin.svg';
import ethereum from 'images/ethereum.svg';
import litecoin from 'images/litecoin.svg';
import dash from 'images/dash.svg';
import coinActivity from 'images/coinActivity.svg'
import { ActiveCoinCard, CoinCard } from "components/coins/CoinCard";
import { Link } from "react-router-dom";
import { CalculateCoinPrice } from "components/coins/CalculateCoinPrice";
import { CoinChart } from "components/coins/CoinChart";
import { CoinFaq } from "components/coins/CoinFaq";



const Litecoin = () => {

  const coin = {
    name: "Litecoin",
    abbrv: "LTE",
    symbol: litecoin,
    price: "90.31",
    activity: coinActivity,
    chart: {
      _1m: "1-month chart",
      _3m: "3-month chart",
      _6m: "6-month chart",
      _1y: "1-year chart",
      all: "All chart",
    },
  }

  return (
    <div className={styles.wrapper}>
      <CoinHeader coinName={coin.name} coinAbbrv={coin.abbrv} coinActivity={coin.activity} />

      <div className={styles.cardWrapperMobile}>
        <ul className={styles.coinList}>
          <Link  className={styles.coinRoute} to="/coins/bitcoin"><li>BTC</li></Link>
          <Link className={styles.coinRoute} to="/coins/ethereum"><li>ETH</li></Link>
          <Link className={`${styles.coinRoute} ${styles.isActive}`} to="/coins/litecoin"><li>LTE</li></Link>
          <Link className={styles.coinRoute} to="/coins/dash"><li>DASH</li></Link>
        </ul>
        <div className={styles.activeCardContainer}>
          <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
        </div>
      </div>

      <div className={styles.cardWrapperDesktop}>
        <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
        <CoinCard coinName={"Ethereum"} coinAbbrv={"Ethereum"} coinSymbol={ethereum} coinPrice={"1,860.59"} />
        <CoinCard coinName={"Litecoin"} coinAbbrv={"LTE"} coinSymbol={litecoin} coinPrice={"90.31 "} />
        <CoinCard coinName={"Dash"} coinAbbrv={"DASH"} coinSymbol={dash} coinPrice={"55.56 "} />
      </div>

      <div className={styles.content}>
        {/* <CalculateCoinPrice coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={Number(coin.price)} /> */}
        <CoinChart chart1m={coin.chart._1m} chart3m={coin.chart._3m} chart6m={coin.chart._6m} chart1y={coin.chart._1y} chartAll={coin.chart.all} coinName={coin.name}/>

        <div className={styles.aboutCoin}>
          <h2>About Litecoin</h2>
          <p>Litecoin <span style={{fontWeight: 400}}>(LTC)</span> is a decentralized peer-to-peer cryptocurrency and open-source software project released under the MIT/X11 license.</p>
          <br/>
          <p>Inspired by Bitcoin, Litecoin was among the earliest altcoins, starting in October 2011. In technical details, the Litecoin main chain shares a slightly modified Bitcoin codebase. The practical effects of those codebase differences are lower transaction fees, faster transaction confirmations, and faster mining difficulty retargeting. Due to its underlying similarities to Bitcoin, Litecoin has historically been referred to as the "silver to Bitcoin's gold." In 2022, Litecoin added optional privacy features via soft fork through the MWEB (MimbleWimble extension block) upgrade.
          </p>
        </div>

        <CoinFaq coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={coin.price} />
      </div>
    </div>
  );
};
export default Litecoin;
