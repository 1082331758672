import React from 'react';
import styles from 'styles/dashboard/transactionCard.module.css';
import verified from 'images/dashboard/verified.svg';
import { TransactionType } from 'interfaces/TransactionType';

type Props = {
  data: TransactionType;
  txnTypeBgColor: string;
  onclick: (data: TransactionType) => void;
};

export const TransactionCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { data, txnTypeBgColor, onclick, ...divProps } = props;

  return (
    <div {...divProps} className={styles.txnWrapper}>
      <div className={`${styles.info} ${styles.cardItem}`}>
        <div className={styles.nameInfo}>
          <div className={styles.initial}>
            <p>{data.name.charAt(0).toUpperCase()}</p>
          </div>
          <p className={styles.name}>{data.name}</p>
          {data.status === 'verified' && <img src={verified} alt="verified-icon" className={styles.verified} />}
        </div>
        <div className={styles.tradeInfo}>
          <p>
            Trade(s) {data.numberOfTrades} | {data.successRate}%
          </p>
        </div>
      </div>
      <div className={`${styles.priceInfo} ${styles.cardItem}`}>
        <p className={styles.title}>Price₦&nbsp;</p>
        <p className={styles.infoDesc}>{data.price}</p>
      </div>

      <div className={`${styles.details} ${styles.details1}`}>
        <div className={styles.desc}>
          <p className={`${styles.title} ${styles.title2}`}>Quantity</p>
          <p className={styles.infoDesc}>
            {data.quantity} {data.currencyTraded}
          </p>
        </div>

        <div className={styles.desc}>
          <p className={`${styles.title} ${styles.title2}`}>Limit</p>
          <p className={styles.infoDesc}>₦100,000.00 - ₦5,000,000.00</p>
        </div>
      </div>

      <div className={`${styles.details} ${styles.details2}`}>
        <div className={styles.desc}>
          <p className={styles.infoDesc}>
            <span className={styles.span}></span>
            {data.paymentMethod}
          </p>
        </div>

        <div className={styles.desc}>
          <p style={{ color: '#74728c' }} className={`${styles.title} ${styles.title2}`}>
            Banks
          </p>
          <p className={styles.infoDesc}>{data.banks.join(', ')}</p>
        </div>
      </div>

      <button  className={styles.type} onClick={() => onclick(data)}>
        <p style={{ backgroundColor: txnTypeBgColor }}>{data.transactionType}</p>
      </button>
    </div>
  );
};
