import React, { useState } from 'react';
import styles from 'styles/auth/login.module.css';
import { useNavigate } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { DownloadButtons } from 'components/buttons/buttons';
import Overlay from './../../Overlay';
import { authService } from 'services/authService';
import { notify } from 'utils/index';

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false); // State to handle redirection

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        setError(true);
        setErrorText('Please input all fields');
        return;
      }

      setShowOverlay(true);
      const data = { email, password };
      const response = await authService.login(data);

      // Handle successful login
      const logindata = response.data.data;
      const resp = response?.data;
      if (resp && resp.data && !resp.error) {
        if (resp.data.success) {
          const token = logindata.token;
          const expires_in = logindata.expires_in;
          const expirationTime = Date.now() + expires_in * 1000; // Convert seconds to milliseconds
          // Store token and expiration time in localStorage
          localStorage.setItem('ACCESS_TOKEN', token);
          localStorage.setItem('TOKEN_EXPIRATION', expirationTime.toString());

          // Redirect on success
          navigate('/auth/signup_security_verification');
        }
      } else {
        // Handle non-successful response
        const errorMessage = resp?.message || 'Error logging in. Please try again.';
        setErrorText(errorMessage);
        notify.error(errorMessage);
      }
      setShowOverlay(false);
    } catch (error: any) {
      setError(true);
      setShowOverlay(false);
      console.log('Error: ' + error.toString());

      if (error.response) {
        if (error.response && error.response.status) {
          const status = error.response.status;
          const errorMessage = error.response.data.message || 'An error occurred';
          // Handling specific error codes
          if (status === 422) {
            setErrorText(errorMessage);
          } else if (status === 403) {
            setErrorText(errorMessage);
          } else {
            setErrorText('An error occurred. Please try again later.');
            notify.error('An error occurred. Please try again later.');
          }
        } else {
          setErrorText('An error occurred. Please try again later.');
          notify.error('An error occurred. Please try again later.');
        }
      } else if (error.request) {
        // The request was made, but no response was received (network issues, CORS, etc.)
        console.log('No response received:', error.request);
        setErrorText('No response from the server. Please check your network or try again later.');
        notify.error('No response from the server. Please check your network or try again later.');
      } else {
        // Something else happened in setting up the request
        console.log('Error:', error.message);
        setErrorText('An unexpected error occurred. Please try again later.');
        notify.error('An unexpected error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className={styles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Login into your account'}</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <PrimaryInput label="Email" type="email" onChange={(e) => setEmail(e.target.value)} error />
        <div className={styles.password}>
          <PrimaryInput
            label="Password"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'} // Show password if showPassword is true
          />
          <div className={styles.iconWWrapper}>
            {showPassword ? (
              <div className={styles.icon} onClick={() => setShowPassword(false)}>
                <IoEyeOff />
              </div>
            ) : (
              <div className={styles.icon} onClick={() => setShowPassword(true)}>
                <IoEye />
              </div>
            )}
          </div>
        </div>
        <div className={styles.text}>
          <a href="/auth/confirm_email" className={styles.text}>
            Forgot Password?
          </a>
        </div>
        <button type="submit" className={styles.button}>
          Login
        </button>
        <div className={styles.textWrapper}>
          <p>
            Don't have an account?
            <a href="/auth/signup" className={styles.colouredText}>
              {' '}
              Sign up
            </a>
          </p>
        </div>
      </form>
      {showOverlay && <Overlay />}
      <div className={styles.buttonContainer}>
        <DownloadButtons
          containerStyle={styles.buttonsWrapper}
          button1style={'animate__animated animate__fadeInLeft'}
          button2style={'animate__animated animate__fadeInRight'}
        />
      </div>
    </div>
  );
};
export default Login;
