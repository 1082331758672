import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import styles from 'styles/auth/verifyEmail.module.css';
import { authService } from 'services/authService';
import Overlay from '../../Overlay';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { User } from 'interfaces/User';

const maskEmail = (email: string) => {
  const [localPart, domain] = email.split('@');
  if (localPart.length <= 2) {
    // For emails with local part of length 1 or 2
    return `${localPart[0]}*@${domain}`;
  } else {
    // For emails with local part longer than 2
    const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
    return `${maskedLocalPart}@${domain}`;
  }
};

const SignupVerifyEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);
  const user: User = useOutletContext<User>();
  useEffect(() => {
    const storedEmail = localStorage.getItem('@userEmail');
    if (storedEmail) {
      setEmail(JSON.parse(storedEmail));
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pastedData = e.clipboardData.getData('text');
    if (!/^\d+$/.test(pastedData)) {
      return; // Only allow numerical digits
    }

    const updatedOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }
    setOtp(updatedOtp);

    // Move focus to the last filled input field
    const lastFilledIndex = Math.min(index + pastedData.length - 1, otpFields.current.length - 1);
    otpFields.current[lastFilledIndex].focus();
  };
  const handleSubmit = async () => {
    setErrorText(null);
    setShowOverlay(true);
    try {
      const otpValue = otp.join('');
      const response = await authService.verifyEmailCode({ token: otpValue });

      const resp = response?.data;

      if (resp && resp.data && resp.data.success) {
        navigate('/auth/signup_security_verification');
      } else if (resp.message) {
        setErrorText(resp?.message);
      } else {
        setErrorText('Error creating user, please try again');
      }
    } catch (error) {
      // @ts-ignore
      console.error('Error verifying email:', error.response); // Handle error
      // Show error message to the user
    }
    setShowOverlay(false);
  };
  const handleResend = async () => {
    setShowOverlay(true);
    try {
      const response = await authService.resendEmailCode({ email });
      setMessage(response.data.message);
      setShowOverlay(false);
    } catch (error) {
      console.error('Error resending email:', error);
    }
  };
  return (
    <div className={baseStyles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Check your Email'}</h2>

      <p className={baseStyles.text}>Enter the 4 digit code we sent to {maskEmail(email)}</p>
      <div className={baseStyles.form}>
        <div className={styles.otpWrapper}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(ref) => (otpFields.current[index] = ref)}
              className={styles.input}
            />
          ))}
        </div>
        <div className={baseStyles.textWrapper}>
          <p>
            {message ? '' : "Didn't get code?"}
            <a href="javascript:void(0)" onClick={handleResend} className={baseStyles.colouredText}>
              {message ? <p className={baseStyles.text}>{message}</p> : ' Resend'}
            </a>
          </p>
        </div>
        <div className={baseStyles.button}>
          <button onClick={handleSubmit}>Next</button>
        </div>

        {
          <div className={styles.resend}>
            <button>Resend Email</button>
            <a href="/auth/verify_phone">Try Another Way</a>
          </div>
        }
      </div>

      {showOverlay && <Overlay />}
    </div>
  );
};
export default SignupVerifyEmail;
