import React, { ReactNode, useState } from 'react';
import baseStyles from 'styles/dashboard/enterCardDetails.module.css';
import styles from 'styles/dashboard/sellIndex.module.css';
import dropdownStyles from 'styles/dashboard/createAddress.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'components/input/Dropdown';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCurrencyLitecoin } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { SiDash } from 'react-icons/si';

export const SellIndex: React.FC = () => {
  const navigate = useNavigate();
  const [icon, setIcon] = useState<ReactNode>(<BsCurrencyBitcoin />);
  const [value, setValue] = useState<string>('Bitcoin');
  const [symbol, setSymbol] = useState<string>('BTC');

  const data = [
    { id: '1', value: 'Bitcoin' },
    { id: '2', value: 'Ethereum' },
    { id: '3', value: 'Litecoin' },
    { id: '4', value: 'Dash' },
  ];

  const getIcon = (coin: string) => {
    setValue(coin);
    switch (coin.toLowerCase()) {
      case 'bitcoin':
        setIcon(<BsCurrencyBitcoin className={styles.icon} />);
        setSymbol('BTC');
        break;
      case 'ethereum':
        setIcon(<FaEthereum className={styles.icon} />);
        setSymbol('ETH');
        break;
      case 'litecoin':
        setIcon(<TbCurrencyLitecoin className={styles.icon} />);
        setSymbol('LTC');
        break;
      case 'dash':
        setIcon(<SiDash className={styles.icon} />);
        setSymbol('DASH');
        break;
    }
  };

  const handleSell = () => {
    navigate('/dashboard/sell', {state: {value:  value }});
  }

  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout title={'Which currency would you like to sell?'} buttonText={'Next'} onclick={handleSell} buttonStyle={baseStyles.button}>
        <div className={baseStyles.content}>
          <div className={baseStyles.inputWrapper}>
            <div className={`${baseStyles.input} ${dropdownStyles.inputWrapper}`}>
              <Dropdown
                data={data}
                label="Currency"
                onChange={(e) => getIcon(e.target.value)}
                defaultValue={''}
                InputLabelProps={{ shrink: true }}
              />
              <input value={`${value} wallet`} className={dropdownStyles.input} />
              <div className={`${dropdownStyles.iconWrapper} ${styles.iconWrapper}`}>{icon}</div>
            </div>
            <div className={`${baseStyles.halfInput} ${styles.halfInput}`}>
              <div className={styles.currencyInput}>
                <PrimaryInput label="Currency" value="NGN" InputLabelProps={{ shrink: true }} />
              </div>
              <div className={styles.input2}>
                <PrimaryInput label="Amount" placeholder="NGN 100.00" InputLabelProps={{ shrink: true }} />
              </div>
            </div>
          </div>

          <div className={baseStyles.inputWrapper}>
            <div className={styles.priceInfo}>
              <div className={`${dropdownStyles.iconWrapper} ${styles.sellIconWrapper}`}>{icon}</div>
              <div className={styles.price}>
                <p>{symbol} wallet</p>
                <p>Available: {symbol} 0.000078956</p>
              </div>
            </div>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default SellIndex;
