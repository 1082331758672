import React from "react";
import styles from "styles/exchange/benefitCard.module.css";

type Props = {
  img: any;
  heading: string;
  desc: string;
  wrapperStyle: {};
  imgAnim: string;
  headingAnim: string;
  descAnim: string;
};

export const BenefitCard: React.FC<Props> = (props) => {
  const { img, heading, desc, wrapperStyle, imgAnim, headingAnim, descAnim } =
    props;

  return (
    <div className={`${wrapperStyle} ${styles.benefitsWrapper}`}>
      <div data-aos={imgAnim} data-aos-offset="0" data-aos-duration="1500" className={styles.benefitImg}>
        <img className={styles.img} src={img} alt={heading} />
      </div>
      <div className={styles.benefitsText}>
        <h2 data-aos={headingAnim} data-aos-offset="0" data-aos-duration="1500">{heading}</h2>
        <p data-aos={descAnim} data-aos-offset="0" data-aos-duration="1500">{desc}</p>
      </div>
    </div>
  );
};
