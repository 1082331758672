import styles from "../../styles/features/hero.module.css";
import { DownloadButtons } from "../buttons/buttons";
import "animate.css";

export const Hero = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.backdrop}></div>
      <div className={styles.overlay}>
        <div className={styles.heroText}>
          <h1 className="animate__animated animate__zoomIn">
            Cryptocurrency is for everyone
          </h1>
          <p className="animate__animated animate__slideInUp">
            TruexGold has made investing simple for millions around the world.
          </p>
          <div className={styles.buttons}>
            <DownloadButtons />
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={`${styles.phoneScreen}`}
        >
        </div>
        <div className={styles.buttons2}>
          <DownloadButtons />
        </div>
      </div>
    </section>
  );
};
