import React from 'react';
import styles from 'styles/dashboard/redeemVoucherIndex.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';

export const RepeatBuy: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'How much are you willing to pay for your bitcoin?'}
        linkText={'Continue'}
        linkPath={'/dashboard/home'}
      >
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <div className={styles.currencyInput}>
              <PrimaryInput label="Currency" value="NGN" InputLabelProps={{ shrink: true }} />
            </div>
            <div className={styles.input}>
              <PrimaryInput label="NGN Wallet" placeholder="NGN 100.00" InputLabelProps={{ shrink: true }} />
            </div>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default RepeatBuy;
