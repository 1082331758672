import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "animate.css";
import styles from "styles/dashboard/dashboardlayout.module.css";
import { PricechartHeader } from "components/header/PricechartHeader";
import { MerchantHeader } from "components/merchant/MerchantHeader";
import { IoCloseOutline, IoMenuOutline } from "react-icons/io5";
import { MerchantSidebar } from "components/merchant/MerchantSidebar";

const MerchantLayout = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const handleExpandedSidebar = (
    newValue: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowSidebar(newValue);
  };

  return (
    <div className={styles.container}>
      <div
        className={showSidebar ? styles.menu2 : styles.menu}
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <IoMenuOutline
          className={showSidebar ? styles.menuIcon : styles.menuIcon2}
        />
        <IoCloseOutline
          className={showSidebar ? styles.closeIcon : styles.closeIcon2}
        />
      </div>

      <div className={showSidebar ? styles.sideNav : styles.sideNavClose}>
        <MerchantSidebar
          expandSidebar={showSidebar}
          handleExpandedSidebar={handleExpandedSidebar}
        />
      </div>

      <div className={showSidebar ? styles.pageContent : styles.pageContent2}>
        <div className={showSidebar ? styles.header : styles.header2}>
          <MerchantHeader expandSidebar={showSidebar} />
          <div className={styles.ticker}>
            <PricechartHeader expandSidebar={showSidebar} />
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.outletBg1}></div>
          <div className={styles.outletBg2}></div>
          <div className={styles.outlet}>
            <Outlet context={showSidebar} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MerchantLayout;
