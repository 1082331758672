import {truexgoldAPI} from 'api/truexgoldAPI';

export class userService {


    public static async userDetails() {
        try {
            return await truexgoldAPI.get('/user');
        } catch (e: any) {
            console.log(e.response);
            return e.response;
        }
    }


    public static async updateUser(userData: { }) {
        try {
            return await truexgoldAPI.post('/user', userData);
        } catch (e: any) {
            console.log(e.response);
            return e.response;
        }
    }

    public static async setPin(pinData: { pin: string }) {
        try {
            // @ts-ignore
            return await truexgoldAPI.post('/user/set-pin', pinData);
        } catch (e: any) {
            console.log(e.response);
            return e.response;
        }
    }
}
