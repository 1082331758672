import styles from 'styles/company/overview.module.css';
import bitcoinOrange from 'images/bitcoinOrange.svg';
import wallet from 'images/cryptocurrency-wallet.svg';

export const Overview = () => {
  return (
    <div className={styles.overviewMain}>
      <div className={styles.overviewWrapper}>
        <div className={styles.overviewHeader}>
          <h3 data-aos="fade-down" data-aos-duration="1000">Why Cryptocurrency?</h3>
          <p data-aos="fade-right" data-aos-duration="1000">
            People's attitudes toward money and how they use it are constantly
            changing. The current financial system was designed for a
            pre-digital era, with many unnecessary inefficiencies and
            gatekeepers. The world now has access to new technologies such as
            decentralized cryptocurrencies, which are rapidly displacing these
            outdated systems and ways of thinking. Money is finally catching up
            to other information revolutions, much like communication evolved
            from landlines to mobile phones or post to email. <br />
            <br />
            Decentralized cryptocurrencies allow us to reimagine the financial
            system in order to improve the world.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className={styles.overviewImage}>
          <img className={styles.image} src={bitcoinOrange} alt="Bitcoin" />
        </div>
      </div>

      <div className={styles.visionWrapper}>
        <div className={styles.overviewHeader}>
          <h3 data-aos="fade-down" data-aos-duration="1000">Our vision</h3>
          <p data-aos="fade-up" data-aos-duration="1000">
            We want to empower billions of people around the world by
            transitioning to a better, more open financial system that
            prioritizes fairness and transparency. <br />
            <br />
            To that end, we've created a platform that makes cryptocurrencies
            like Bitcoin and Ethereum available to as many people as possible.
            We're developing products and services that make it safe and simple
            to buy, store, use, and learn about cryptocurrencies, so that
            everyone, no matter where they are, can reap the benefits.
          </p>
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className={styles.overviewImage}>
          <img className={styles.image} src={wallet} alt="Bitcoin-in-wallet" />
        </div>
      </div>
    </div>
  );
};
