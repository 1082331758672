import React, { useState } from "react";
import { CoinHeader } from "components/coins/CoinHeader";
import styles from 'styles/coins/coin.module.css';
import { formatCurrency } from 'utils/stringUtils';
import bitcoin from 'images/bitcoin.svg';
import ethereum from 'images/ethereum.svg';
import litecoin from 'images/litecoin.svg';
import dash from 'images/dash.svg';
import coinActivity from 'images/coinActivity.svg'
import { ActiveCoinCard, CoinCard } from "components/coins/CoinCard";
import { Link } from "react-router-dom";
import { CalculateCoinPrice } from "components/coins/CalculateCoinPrice";
import { CoinChart } from "components/coins/CoinChart";
import { CoinFaq } from "components/coins/CoinFaq";



const Bitcoin = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  }

  const coin = {
    name: "Bitcoin",
    abbrv: "BTC",
    symbol: bitcoin,
    price: "27,964.80",
    activity: coinActivity,
    chart: {
      _1m: "1-month chart",
      _3m: "3-month chart",
      _6m: "6-month chart",
      _1y: "1-year chart",
      all: "All chart",
    },
  }

  return (
    <div className={styles.wrapper}>
      <CoinHeader coinName={coin.name} coinAbbrv={coin.abbrv} coinActivity={coin.activity} />

      <div className={styles.main}>

        <div className={styles.mainCardContainer}>
          <div className={styles.cardWrapperMobile}>
            <ul className={styles.coinList}>
              <Link className={`${styles.coinRoute} ${styles.isActive}`} to="/coins/bitcoin"><li>BTC</li></Link>
              <Link className={styles.coinRoute} to="/coins/ethereum"><li>ETH</li></Link>
              <Link className={styles.coinRoute} to="/coins/litecoin"><li>LTE</li></Link>
              <Link className={styles.coinRoute} to="/coins/dash"><li>DASH</li></Link>
            </ul>
            <div className={styles.activeCardContainer}>
              <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
            </div>
          </div>

          <div className={styles.cardWrapperDesktop}>
            <Link to="/coins/bitcoin">
              <ActiveCoinCard coinName={coin.name} coinAbbrv={coin.abbrv} coinSymbol={coin.symbol} coinPrice={coin.price} />
            </Link>
            <Link to="/coins/ethereum">
              <CoinCard coinName={"Ethereum"} coinAbbrv={"ETH"} coinSymbol={ethereum} coinPrice={"1,860.59"} />
            </Link>
            <Link to="/coins/litecoin">
              <CoinCard coinName={"Litecoin"} coinAbbrv={"LTE"} coinSymbol={litecoin} coinPrice={"90.31 "} />
            </Link>
            <Link to="/coins/dash">
              <CoinCard coinName={"Dash"} coinAbbrv={"DASH"} coinSymbol={dash} coinPrice={"55.56 "} />
            </Link>
          </div>
        </div>

        <div className={styles.content}>
          <CalculateCoinPrice coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={coin.price} />
          <CoinChart chart1m={coin.chart._1m} chart3m={coin.chart._3m} chart6m={coin.chart._6m} chart1y={coin.chart._1y} chartAll={coin.chart.all} coinName={coin.name} />

          <div className={styles.aboutCoin}>
            <h2>About Bitcoin</h2>
            <p>Bitcoin (BTC) is the world’s first decentralized cryptocurrency. It was created in 2009 by Satoshi Nakamoto, whose true identity is still yet to be confirmed. It follows a set of ideas first introduced in Nakamoto's white paper, 'Bitcoin: A Peer-to-Peer Electronic Cash System', published on October 31, 2008. </p>
            <p className={!isExpanded ? styles.hideText : styles. showText}><br/>Bitcoin uses peer-to-peer technology to facilitate payments that require no central authority to operate. Individuals who contribute computing power to the Bitcoin network, also known as ‘miners’, are incentivized by rewards and transaction fees paid in Bitcoin. One Bitcoin is divisible to eight decimal places, and the smallest unit is known as a satoshi.
            </p>
            <span onClick={handleExpand} className={styles.expand}>{!isExpanded ? "Read More >>" : "Read Less <<"}</span>
          </div>

          <CoinFaq coinName={coin.name} coinAbbrv={coin.abbrv} coinPrice={coin.price} />
        </div>
      </div>
    </div>
  );
};
export default Bitcoin;
