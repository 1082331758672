import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import styles from "styles/dashboard/dashboardSidebar.module.css";
import "animate.css";
import {IoMenuOutline} from "react-icons/io5";
import homeIcon from "images/dashboard/homeIcon.svg";
import walletIcon from "images/dashboard/walletIcon.svg";
import mobileIcon from "images/dashboard/mobileIcon.svg";
import exchangeIcon from "images/dashboard/exchangeIcon.svg";
import rewardsIcon from "images/dashboard/rewardsIcon.svg";
import p2pIcon from "images/dashboard/p2pIcon.svg";
import btcIcon from "images/dashboard/btcIcon.svg";
import ltcIcon from "images/dashboard/ltcIcon.svg";
import ethIcon from "images/dashboard/ethIcon.svg";
import dashIcon from "images/dashboard/dashIcon.svg";
import helpIcon from "images/dashboard/helpIcon.svg";
import aboutIcon from "images/dashboard/aboutIcon.svg";

type Props = {
  expandSidebar: boolean;
  handleExpandedSidebar: (toggleState: any) => void;
};

export const MerchantSidebar: React.FC<Props> = ({
  expandSidebar,
  handleExpandedSidebar,
}) => {
  const navigate = useNavigate();

  const handleExpandSidebar = () => {
    handleExpandedSidebar(!expandSidebar);
  };

  const handleRoute = (e: any, path: string) => {
    e.preventDefault();
    navigate(path);
    handleExpandedSidebar(!expandSidebar);
  };

  const account = [
    {
      id: "1",
      name: "Dashboard",
      path: "/merchant/merchant_home",
      icon: walletIcon,
    },
    {
      id: "2",
      name: "Transaction",
      path: "/merchant/merchant_transaction",
      icon: mobileIcon,
    },
    {
      id: "3",
      name: "Exchange",
      path: "/dashboard/exchange",
      icon: exchangeIcon,
    },
    {
      id: "4",
      name: "Rewards",
      path: "/dashboard/rewards",
      icon: rewardsIcon,
    },
    {
      id: "5",
      name: "P2P",
      path: "/merchant/merchant_p2p",
      icon: p2pIcon,
    },
  ];

  const priceChart = [
    {
      id: "1",
      name: "Bitcoin",
      path: "/dashboard/bitcoin",
      icon: btcIcon,
    },
    {
      id: "2",
      name: "Ethereum",
      path: "/dashboard/ethereum",
      icon: ethIcon,
    },
    {
      id: "3",
      name: "Litecoin",
      path: "/dashboard/litecoin",
      icon: ltcIcon,
    },
    {
      id: "4",
      name: "Dash",
      path: "/dashboard/dash",
      icon: dashIcon,
    },
  ];

  const about = [
    {
      id: "1",
      name: "Company",
      path: "/dashboard/company",
      icon: aboutIcon,
    },
    {
      id: "2",
      name: "Help centerx",
      path: "/dashboard/help_center",
      icon: helpIcon,
    },
  ];

  return (
    <nav className={styles.nav}>
      <div
        className={expandSidebar ? styles.menu : styles.menu2}
        onClick={handleExpandSidebar}
      >
        <IoMenuOutline className={expandSidebar ? styles.menuIcon : styles.menuIcon2} />
      </div>
      <div className={styles.sidebarContent}>
      <div className={expandSidebar ? styles.homeWrapper : styles.homeWrapper2}>
        <NavLink
          to={"/dashboard/home"}
          className={expandSidebar ? styles.home : styles.home2}
        >
          <img src={homeIcon} alt="home-icon" className={styles.icon1} />
          <p>Home</p>
        </NavLink>
      </div>

      <div className={styles.navGroup}>
        <p className={expandSidebar ? styles.groupTitle : styles.groupTitle2}>Account</p>
        {account.map((item, index) => (
          <NavLink
            to={item.path}
            onClick={(e) => handleRoute(e, item.path)}
            key={index.toString()}
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.navItem}` : styles.navItem
            }
          >
            <img
              src={item.icon}
              alt={`${item.name.toLowerCase()}-icon`}
              className={styles.icon}
            />
            <p className={expandSidebar ? styles.text : styles.text2}>
              {item.name}
            </p>
          </NavLink>
        ))}
      </div>

      <div className={styles.navGroup}>
        <p className={expandSidebar ? styles.groupTitle : styles.groupTitle2}>Price Chart</p>
        {priceChart.map((item, index) => (
          <NavLink
            key={index.toString()}
            to={item.path}
            onClick={(e) => handleRoute(e, item.path)}
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.navItem}` : styles.navItem
            }
          >
            <img
              src={item.icon}
              alt={`${item.name.toLowerCase()}-icon`}
              className={styles.icon}
            />
            <p className={expandSidebar ? styles.text : styles.text2}>
              {item.name}
            </p>
          </NavLink>
        ))}
      </div>

      <div className={styles.navGroup}>
        <p className={expandSidebar ? styles.groupTitle : styles.groupTitle2}>About us</p>
        {about.map((item, index) => (
          <NavLink
            key={index.toString()}
            to={item.path}
            onClick={(e) => handleRoute(e, item.path)}
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.navItem}` : styles.navItem
            }
          >
            <img
              src={item.icon}
              alt={`${item.name.toLowerCase()}-icon`}
              className={styles.icon}
            />
            <p className={expandSidebar ? styles.text : styles.text2}>
              {item.name}
            </p>
          </NavLink>
        ))}
      </div>
      </div>
    </nav>
  );
};
