import React, { useCallback, useRef, useState } from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/takePicture.module.css";
import camera from "images/cameraI.svg";
import Webcam from "react-webcam";

const TakePicture: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: 'user',
  };

  const handleStartCamera = () => {
    setIsCameraActive(true);
  };

  const capture = useCallback(() => {
    setIsCameraActive(true);
    if (!webcamRef.current) {
      return;
    } else if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
      setIsCameraActive(false);
    }
  }, [webcamRef]);

  const handleRecapture = () => {
    setImgSrc(null);
    setIsCameraActive(true);
  }

  return (
    <div className={`${baseStyles.authContainer} ${styles.authContainer}`}>
      <h2>Take a clear, well-lit photo of your face</h2>
      <p className={baseStyles.text}>
        Look <span className={baseStyles.colouredText}>directly</span> at the
        camera,&nbsp;<span className={baseStyles.colouredText}>remove</span>{" "}
        your glasses, ensure your&nbsp;
        <span className={baseStyles.colouredText}>face</span> is easy to see,
        make sure the photo&nbsp;
        <span className={baseStyles.colouredText}>isn't blurry</span>
      </p>

      <form className={baseStyles.form}>
        {!isCameraActive && !imgSrc && (
          <>
            <div className={styles.cameraIconWrapper}>
              <img src={camera} alt="camera-icon" />
            </div>
            <div className={baseStyles.button}>
              <button className={styles.start} onClick={handleStartCamera}>Start Camera</button>
            </div>
          </>
        )}

        {isCameraActive && !imgSrc && (
          <>
            <div className={styles.cameraIconWrapper}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.8}
                imageSmoothing={true}
                videoConstraints={videoConstraints}
                className={styles.webcam}
              />
            </div>
            <div className={baseStyles.button}>
              <button className={styles.start} onClick={capture}>Capture</button>
            </div>
          </>
        )}

        {!isCameraActive && imgSrc && (
          <>
            <div className={styles.imageWrapper}>
              {imgSrc && <img src={imgSrc} alt="webcam" className={styles.image} />}
            </div>
            <div className={`${baseStyles.button} ${styles.button}`}>
              <button onClick={handleRecapture}>Re-capture</button>
            </div>
            <div className={baseStyles.button}>
              <a href="/auth/signup_completed">Next</a>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default TakePicture;
