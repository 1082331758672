import React, { ChangeEvent, useRef, useState } from 'react';
import inputStyles from 'styles/settings/inputStyles.module.css';
import baseStyles from 'styles/settings/profile.module.css';
import styles from 'styles/settings/security.module.css';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

export const PhoneVerificationOTP: React.FC = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };

  return (
    <div className={`${baseStyles.container} ${styles.container}`}>
      <h3 className={inputStyles.h3}>Sms OTP</h3>
      <div className={styles.content}>
        <div className={inputStyles.inputField}>
        <div className={styles.inputText2}>
          {otp.map((digit: string, index: number) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => (otpFields.current[index] = ref)}
              className={`${inputStyles.input} ${styles.otp}`}
            />
          ))}
          </div>
          <span
            style={{ border: '1px solid #fff', backgroundColor: '#ffffff00' }}
            className={styles.iconWrapper}
            onClick={() => navigate('/dashboard/settings/security/2FA/phone/otp')}
          >
            <IoIosArrowForward className={inputStyles.icon} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerificationOTP;
