import React, { ReactNode, useEffect, useState } from 'react';
import baseStyles from 'styles/dashboard/enterCardDetails.module.css';
import styles from 'styles/dashboard/sellIndex.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCurrencyLitecoin } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { SiDash } from 'react-icons/si';

export const Send: React.FC = () => {
  const [icon, setIcon] = useState<ReactNode>(<BsCurrencyBitcoin />);
  const [symbol, setSymbol] = useState<string>('BTC');


  const data = [
    { id: '1', value: 'Bitcoin' },
    { id: '2', value: 'Ethereum' },
    { id: '3', value: 'Litecoin' },
    { id: '4', value: 'Dash' },
  ];

  const getIcon = (coin: string) => {
    switch (coin.toLowerCase()) {
      case 'bitcoin':
        setIcon(<BsCurrencyBitcoin className={styles.icon} />);
        setSymbol('BTC');
        break;
      case 'ethereum':
        setIcon(<FaEthereum className={styles.icon} />);
        setSymbol('ETH');
        break;
      case 'litecoin':
        setIcon(<TbCurrencyLitecoin className={styles.icon} />);
        setSymbol('LTC');
        break;
      case 'dash':
        setIcon(<SiDash className={styles.icon} />);
        setSymbol('DASH');
        break;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout icon={icon} title={`Send ${symbol} 0.00000806 to 3DtZBG3DtZBGZ7nFrwZBGZ7nFrw`} linkText={'Confirm'} linkPath={'/dashboard/enter_pin_send'}>
        <div style={{ marginTop: '20px'}} className={baseStyles.content}>
          <div className={baseStyles.inputWrapper}>
            <p className={styles.priceDesc}>You Send</p>
            <div style={{ justifyContent: 'center'}} className={styles.priceInfo}>
              <div className={styles.price}>
                <p>{symbol} 0.000078956</p>
              </div>
            </div>
          </div>

          <div className={baseStyles.inputWrapper}>
            <p className={styles.priceDesc}>Charge</p>
            <div style={{ justifyContent: 'center'}} className={styles.priceInfo}>
              <div className={styles.price}>
                <p>NGN 0.7</p>
              </div>
            </div>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default Send;
