import React from 'react';
import styles from 'styles/dashboard/redeemVoucherIndex.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';

export const RedeemVoucherIndex: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'How much would you like to add to your voucher?'}
        linkText={'Continue'}
        linkPath={'/dashboard/enter_card_details'}
      >
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <div className={styles.currencyInput}>
              <PrimaryInput label="Currency" value="NGN" InputLabelProps={{ shrink: true }} />
            </div>
            <div className={styles.input}>
              <PrimaryInput label="Amount"/>
            </div>
          </div>

          <div className={styles.limit}>
            <p>Minimum Amount</p>
            <p>NGN 100.00</p>
          </div>
          <div className={styles.limit}>
            <p>Maximum Amount</p>
            <p>NGN 5,000,000.00</p>
          </div>
        </div>
      </PrimaryPageLayout>
    </div>
  );
};

export default RedeemVoucherIndex;
