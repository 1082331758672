import React from "react";
import styles from 'styles/dashboard/help.module.css';
import settings from 'images/dashboard/merchantSettings.svg';

export const Help:React.FC = () => {
  return (
   <div className={styles.container}>
    <img src={settings} alt="help" />
   </div>
  );
};

export default Help;