import React from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/securityVerification.module.css";
import puzzleBox from 'images/puzzleBox.svg';


const SecurityVerification = () => {
  return (
    <div className={baseStyles.authContainer}>
      <h2>Security Verification</h2>
      <a href="#" className={styles.puzzle}>
        <img src={puzzleBox} alt="puzzle" />
      </a>
    </div>
  );
};
export default SecurityVerification;
