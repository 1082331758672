import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from 'styles/dropdown/headerDropdown.module.css';
import { MdOutlineArrowDropDown } from 'react-icons/md';

type Props = {
  onclick: any;
  dropdownMenu: Array<{ name: string; path: string }>;
};

export const HeaderDropdown: React.FC<Props> = (props) => {
  const { onclick, dropdownMenu } = props;

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.pointerArrow}></div>
      <ul className={styles.listContainer}>
        {dropdownMenu.map((menuItem, index) => (
          <li className={styles.listWrapper} key={index.toString()}>
            <NavLink className={styles.listItem} to={menuItem.path} onClick={onclick}>
              {menuItem.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

type ListDropdownProps = {
  label: string;
  onclick: any;
  dropdownMenu: Array<any>;
  isFilterActive?: boolean;
};

export const ListDropdown: React.FC<ListDropdownProps> = (props) => {
  const { label, onclick, dropdownMenu, isFilterActive } = props;
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    onclick(item);
  }


  return (
    <div className={styles.dropdownContainer2}>
      <div className={styles.textWrapper}>
        <p>{label}</p>
        <MdOutlineArrowDropDown className={styles.arrow} />
      </div>

      <div className={styles.dropdownWrapper}>
        <ul className={styles.listContainer2}>
          {dropdownMenu.map((menuItem, index) => (
            <li className={styles.listWrapper2} key={index.toString()} onClick={() => handleItemClick(menuItem)}>
              <p className={ selectedItem === menuItem && isFilterActive ? styles.selected : styles.listItem2}>{menuItem}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
