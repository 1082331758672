import React, { useEffect, useState } from 'react';
import styles from 'styles/coins/coinChart.module.css';
import { chartService } from 'services/chartService';
import Chart from 'react-apexcharts';

type Props = {
  bgStyle?: string;
  chart1m: any;
  chart3m: any;
  chart6m: any;
  chart1y: any;
  chartAll: any;
  coinName: string;
  activeStyle?: string;
  textColor?: string;
  expandSidebar?: boolean;
};

export const CoinChart: React.FC<Props> = (props) => {
  const { bgStyle, chart1m, chart3m, chart6m, chart1y, chartAll, coinName, activeStyle, textColor, expandSidebar } =
    props;
  const [isActive, setIsActive] = useState<any>('3m');
  const [chart, setChart] = useState<any>(chart3m);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getChart(90);
  }, []);

  const getChart = async (days: number) => {
    setLoading(true);
    const req = await chartService.getChart(days);
    const res = req.data.prices;
    setLoading(false);
    // console.log(res);

    if (req.data?.error) {
      setError(req.data?.error);
      return;
    } else {
      const formattedData = res.map((price: any) => ({
        x: new Date(price[0]),
        y: Math.ceil(price[1]),
      }));

      setChartData(formattedData);
    }
  };

  const options: any = {
    chart: {
      id: `${coinName}-chart`,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
      tickAmount: 'dataPoints',
      tickPlacement: 'between',
    },
    yaxis: {
      labels: {
        formatter: (value: number) => Math.ceil(value),
      },
    },
  };

  const series = [
    {
      name: '',
      data: chartData,
    },
  ];

  const handleChart1m = (e: any) => {
    setChart(chart1m);
    setIsActive(e.target.innerHTML);
  };

  const handleChart3m = (e: any) => {
    setChart(chart3m);
    setIsActive(e.target.innerHTML);
  };

  const handleChart6m = (e: any) => {
    setChart(chart6m);
    setIsActive(e.target.innerHTML);
  };

  const handleChart1y = (e: any) => {
    setChart(chart1y);
    setIsActive(e.target.innerHTML);
  };

  const handleChartAll = (e: any) => {
    setChart(chartAll);
    setIsActive(e.target.innerHTML);
  };

  return (
    <div style={{ color: textColor ? textColor : 'black' }} className={styles.container}>
      <div className={styles.chartContent}>
        <div className={styles.heading}>
          <h5 style={{ color: textColor ? textColor : 'black' }}>{coinName} Chart</h5>
          <ul className={styles.chartPeriodWrapper}>
            <li
              onClick={handleChart1m}
              className={
                isActive === '1m' ? `${styles.chartPeriod} ${styles.active} ${activeStyle}` : styles.chartPeriod
              }
            >
              1m
            </li>
            <li
              onClick={handleChart3m}
              className={
                isActive === '3m' ? `${styles.chartPeriod} ${styles.active} ${activeStyle}` : styles.chartPeriod
              }
            >
              3m
            </li>
            <li
              onClick={handleChart6m}
              className={
                isActive === '6m' ? `${styles.chartPeriod} ${styles.active} ${activeStyle}` : styles.chartPeriod
              }
            >
              6m
            </li>
            <li
              onClick={handleChart1y}
              className={
                isActive === '1y' ? `${styles.chartPeriod} ${styles.active} ${activeStyle}` : styles.chartPeriod
              }
            >
              1y
            </li>
            <li
              onClick={handleChartAll}
              className={
                isActive === 'All' ? `${styles.chartPeriod} ${styles.active} ${activeStyle}` : styles.chartPeriod
              }
            >
              All
            </li>
          </ul>
        </div>

        {loading ? (
          <div className={styles.loadingText}>
            <p>Loading Chart...</p>
          </div>
        ) : (
          <>
            {error ? (
              <div className={styles.loadingText}>
                <p className={styles.errorText}>{error}</p>
              </div>
            ) : (
              <div className={styles.chartWrapper}>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height={400}
                  className={expandSidebar ? styles.chart2 : styles.chart}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
