import React, { useState } from 'react';
import { IoIosCopy } from 'react-icons/io';
import styles from 'styles/copy.module.css';

type Props = {
  copyText: string;
//   handleCopy: () => void;
  iconStyle?: any;
  size?: number;
};

export const Copy: React.FC<Props> = (props) => {
    const { copyText, iconStyle, size} = props;
    const [showCopied, setShowCopied] = useState<boolean>(false);

    const handleCopy = () => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    };

  return (
    <div className={styles.copyWrapper}>
      <span className={styles.info2} onClick={handleCopy}>
        <IoIosCopy size={size} className={iconStyle} />
      </span>
      { showCopied && <p>{copyText} copied to clipboard</p>}
    </div>
  );
};
