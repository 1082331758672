import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from 'styles/settings/menu.module.css';
import 'animate.css';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import profile from 'images/settings/profileSettingsIcon.svg';
import security from 'images/settings/securitySettingsIcon.svg';
import settings from 'images/settings/settingsIcon.svg';
import help from 'images/settings/helpSettingsIcon.svg';

type Props = {
  expandSidebar: boolean;
  handleExpandedSidebar: (toggleState: any) => void;
};

export const SettingsMenu: React.FC<Props> = ({ expandSidebar, handleExpandedSidebar }) => {
  const asideigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 800); // Adjust the breakpoint as needed
  };

  const handleExpandSidebar = () => {
    handleExpandedSidebar(!expandSidebar);
  };

  const handleRoute = (e: any, path: string) => {
    e.preventDefault();
    asideigate(path);
    if (expandSidebar && isSmallScreen) {
      handleExpandedSidebar(!expandSidebar);
    } else if (expandSidebar && !isSmallScreen) {
      handleExpandedSidebar(true);
    } else if (!expandSidebar && !isSmallScreen) {
      handleExpandedSidebar(false);
    }
  };

  const account = [
    {
      id: '1',
      name: 'Profile',
      path: '/dashboard/settings/profile',
      icon: profile
    },
    {
      id: '2',
      name: 'Security',
      path: '/dashboard/settings/security',
      icon: security
    },
    {
      id: '3',
      name: 'Settings',
      path: '/dashboard/settings/settings',
      icon: settings
    },
    {
      id: '4',
      name: 'Help',
      path: '/dashboard/settings/help',
      icon: help
    },
  ];

  return (
    <aside className={styles.aside}>
      <div className={styles.asideGroup}>
        {account.map((item, index) => (
          <NavLink
            to={item.path}
            onClick={(e) => handleRoute(e, item.path)}
            key={index.toString()}
            className={({ isActive }) => (isActive ? `${styles.active} ${styles.asideItem}` : styles.asideItem)}
          >
            <img src={item.icon} alt={`${item.name.toLowerCase()}-icon`} className={styles.icon} />
            <div className={styles.menuDesc}>
              <p className={expandSidebar ? styles.text : styles.text2}>{item.name}</p>
              <IoIosArrowForward className={styles.arrow}/>
            </div>
            
            {/* <p className={!expandSidebar ? styles.hoverText : styles.hoverText2}>{item.name}</p> */}
          </NavLink>
        ))}
      </div>
    </aside>
  );
};
