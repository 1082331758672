import React from 'react';
import {PrimaryPageLayout} from 'components/dashboard/PrimaryPageLayout';
import bank from 'images/dashboard/bank.svg';

export const Topup: React.FC = () => {
  return (
    <div style={{ width: '100%'}}>
      <PrimaryPageLayout
        title={'Add money with a voucher'}
        desc={'To buy cryptocurrency you’ll need to top up your wallet with money'}
        icon={bank}
        linkText={'Create Voucher'}
        linkPath={'https://vouchersystem.codesett.com/client/buy/1/NGN/voucher/new'}
        linkText2={'Redeem Voucher'}
        linkPath2={'/dashboard/redeem_voucher_index'}
      ></PrimaryPageLayout>
    </div>
  );
};

export default Topup;
