import styles from "styles/rewards/rewardsHome.module.css";
import "animate.css";
import { Link } from "react-router-dom";

const RewardsHome = () => {
  return (
    <section className={styles.heroSection}>
        <div className={styles.heroText}>
          <h2 className="animate__animated animate__zoomIn">
            Get rewarded <br/> with <span className={styles.bold}>TruexGold</span>
          </h2>
          <p className="animate__animated animate__slideInUp">
            Refer your friends and family, redeem promo codes and earn Bitcoins.
          </p>
          <div className={styles.buttons}>
            <Link to="/products/rewards/rewards-earn" className={`${styles.colouredLink} ${"animate__animated animate__slideInRight"}`}>Get Started</Link>
            <Link to="/auth/login" className={`${styles.link} ${"animate__animated animate__slideInRight"}`}>Sign in</Link>
          </div>
        </div>

        <div
          className={`${styles.rewardsImage} ${"animate__animated animate__slideInRight"}`}
        >
        </div>

        <div className={styles.buttons2}>
        <Link to="/products/rewards/rewards-earn" className={`${styles.colouredLink} ${"animate__animated animate__slideInRight"}`}>Get Started</Link>
            <Link to="/auth/signin" className={`${styles.link} ${"animate__animated animate__slideInRight"}`}>Sign in</Link>
        </div>
    </section>
  );
};

export default RewardsHome;
