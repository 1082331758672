import React, { useState } from 'react';
import styles from 'styles/dashboard/help.module.css';
import merchantIndex from 'images/dashboard/merchantIndex.svg';
import merchantText from 'images/dashboard/merchantText.svg';
import { useNavigate } from 'react-router-dom';

export const MerchantIndex: React.FC = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/auth/merchant_form');
  };

  const handleDashboard = () => {
    navigate('/dashboard/home');
  };

  return (
    <div className={styles.merchantContainer}>
      <img src={merchantText} alt="reward" className={styles.text} onClick={handleDashboard} />
      <img src={merchantIndex} alt="reward" className={styles.header} onClick={handleNext} />
    </div>
  );
};

export default MerchantIndex;
