import React from "react";

export const DashboardDash = () => {
  return (
    <p
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      DashboardDash Page
    </p>
  );
};

export default DashboardDash;
