import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import AOS from 'aos';
import "aos/dist/aos.css";



function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
