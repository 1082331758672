import React, { ReactNode, useState } from 'react';
import baseStyles from 'styles/dashboard/enterCardDetails.module.css';
import fontStyles from 'styles/dashboard/primaryPageLayout.module.css';
import styles from 'styles/dashboard/createAddress.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { TransactionReceiptModal } from 'components/modal/TransactionReceiptModal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Dropdown } from 'components/input/Dropdown';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCurrencyLitecoin } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { SiDash } from 'react-icons/si';
import { SuccessfulModal } from 'components/modal/SuccessfulModal';

export const CreateAddress: React.FC = () => {
  const navigate = useNavigate();
  const [showSuccessfulModal, setShowSuccessfulModal] = useState<boolean>(false);
  const showSidebar: boolean = useOutletContext();
  const [icon, setIcon] = useState<ReactNode>(<BsCurrencyBitcoin />);
  const [value, setValue] = useState<string>('Bitcoin');

  const handleSuccessfulModal = () => {
    setShowSuccessfulModal(false);
    navigate('/dashboard/home');
  };

  const data = [
    { id: '1', value: 'Bitcoin' },
    { id: '2', value: 'Ethereum' },
    { id: '3', value: 'Litecoin' },
    { id: '4', value: 'Dash' },
  ];

  const getIcon = (coin: string) => {
    setValue(coin);
    switch (coin.toLowerCase()) {
      case 'bitcoin':
        return setIcon(<BsCurrencyBitcoin className={styles.icon} />);
      case 'ethereum':
        return setIcon(<FaEthereum className={styles.icon} />);
      case 'litecoin':
        return setIcon(<TbCurrencyLitecoin className={styles.icon} />);
      case 'dash':
        return setIcon(<SiDash className={styles.icon} />);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'Which wallet would you like to create an address for?'}
        buttonText={'Create'}
        onclick={() => setShowSuccessfulModal(true)}
        linkText2={'Cancel'}
        linkPath2={'/dashboard/home'}
      >
        <div className={baseStyles.content}>
          <div className={baseStyles.inputWrapper}>
            <div className={`${baseStyles.input} ${styles.inputWrapper}`}>
              <Dropdown data={data} label="Currency" onChange={(e) => getIcon(e.target.value)} defaultValue={value} />
              <input value={value} className={styles.input} />
              <div className={styles.iconWrapper}>{icon}</div>
            </div>
          </div>

          <div className={styles.addressName}>
            <h2 className={fontStyles.h2}>Enter your {value} address name</h2>

            <div className={baseStyles.inputWrapper}>
              <div className={baseStyles.input}>
                <PrimaryInput label="Name" />
              </div>
            </div>
          </div>
        </div>
      </PrimaryPageLayout>

      {showSuccessfulModal && (
        <SuccessfulModal expandSidebar={showSidebar} onclose={handleSuccessfulModal} text={`Your ${value} address creation was successful`} />
      )}
    </div>
  );
};

export default CreateAddress;
