import React, { useState } from "react";
import styles from 'styles/coins/calculateCoinPrice.module.css';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
// import { formatCurrency} from 'utils/stringUtils';
import { Link } from "react-router-dom";


type Props = {
  coinName?: string;
  coinAbbrv?: string;
  coinPrice?: string;
};

export const CalculateCoinPrice: React.FC<Props> = (props) => {
  const { coinName, coinAbbrv, coinPrice } = props;
  const _coinPrice = Number(coinPrice?.replaceAll(',', ''));
  const exchangeRate = _coinPrice * 750;
  const [btcAmount, setBtcAmount] = useState<number>(1);
  const [ngnPrice, setNgnPrice] = useState<number>(btcAmount * exchangeRate);

  const handleBitcoinChange = (event: any) => {
    const bitcoin = parseFloat(event.target.value) || 0;
    const naira = bitcoin * exchangeRate;
    setBtcAmount(bitcoin);
    setNgnPrice(naira);
  };

  const handleNairaChange = (event: any) => {
    const naira = parseFloat(event.target.value) || 0;
    const bitcoin = naira / exchangeRate;
    setNgnPrice(naira);
    setBtcAmount(bitcoin);
  };


  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h2>Calculate the price of <br /> <span className={styles.bold}>{coinName} in your currency</span></h2>
      </div>

      <div className={styles.inputContainer}>
        <div className={styles.inputWrapper}>
          <TextField
            className={styles.input}
            variant="outlined"
            margin="normal"
            id="amountBTC"
            type="number"
            label="Amount"
            value={btcAmount}
            onChange={handleBitcoinChange}
            inputProps={{ step: "0.00000001", pattern: '[0-9]*' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{coinAbbrv}</InputAdornment>,
              inputMode: "numeric"
            }}
          />
          <span className={styles.hideSpinner}></span>

        </div>

        <div className={styles.inputWrapper}>
          <TextField
            className={styles.input}
            margin="normal"
            id="amountNGN"
            label="Amount"
            type="number"
            value={ngnPrice}
            onChange={handleNairaChange}
            inputProps={{ step: "0.01" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">NGN</InputAdornment>,
            }}
          />
          <span className={styles.hideSpinner}></span>
        </div>

        <Link to="/auth/signin" className={styles.colouredLink}>Buy</Link>
      </div>

      <div className={styles.priceInfo}>
        <p>This tool helps you to calculate the price of {coinName} in your local currency</p>
        <p>The dynamic calculation you see are our latest {coinAbbrv} rates from the TruexGold Exchange</p>
      </div>
    </div>
  );
};


