import React from 'react';
import styles from 'styles/dashboard/transactions.module.css';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCurrencyLitecoin } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { SiDash } from 'react-icons/si';
import { VscArrowSwap } from 'react-icons/vsc';
import { formatCurrency } from 'utils/stringUtils';

type Props = {
  data: any;
};

export const Transactions: React.FC<Props> = (props) => {
  const { data } = props;
  const transactions = data.transactions;

  const getIcon = (coin: string) => {
    switch (coin) {
      case 'bitcoin':
        return <BsCurrencyBitcoin className={styles.icon} />;
      case 'ethereum':
        return <FaEthereum className={styles.icon} />;
      case 'litecoin':
        return <TbCurrencyLitecoin className={styles.icon} />;
      case 'dash':
        return <SiDash className={styles.icon} />;
    }
  };

  const getAbbrv = (coin: string) => {
    switch (coin) {
      case 'bitcoin':
        return 'BTC';
      case 'ethereum':
        return 'ETH';
      case 'litecoin':
        return 'LTC';
      case 'dash':
        return 'DASH';
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case 'pending':
        return <span style={{color: 'orange'}}>spanending</span>;
      case 'successful':
        return <span style={{color: '#12ce6c'}}>Successful</span>;
      case 'failed':
        return <span style={{color: '#fb2c2e'}}>Failed</span>;
      case 'dash':
        return 'DASH';
    }
  };

  return (
    <div className={styles.container}>
      {transactions.map((item: any, index: any) => (
        <div key={index.toString()} className={styles.wrapper}>
          <div className={styles.icons}>
            <div className={styles.fromIcon}>{getIcon(item.from.toLowerCase())}</div>
            <div className={styles.toIcon}>{getIcon(item.to.toLowerCase())}</div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <div className={styles.title}>
                <p>
                  {item.fromValue}
                  {getAbbrv(item.from.toLowerCase())}
                </p>
                <div className={styles.arrow}>
                  &nbsp;<VscArrowSwap />&nbsp;
                </div>
                <p>
                  {item.toValue}
                  {getAbbrv(item.to.toLowerCase())}
                </p>
              </div>
              <div className={styles.desc}>
                <p className={styles.descText}>TokennIon - 6 minutes ago</p>
              </div>
            </div>
            
            <div className={styles.amount}>
              <p>{formatCurrency(item.amount)}</p>
            </div>

            <div className={styles.status}>
              <p>{getStatus(item.status.toLowerCase())}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
