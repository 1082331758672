import React from "react";
import styles from "styles/coins/coinFaq.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FcExpand } from "react-icons/fc";

type Props = {
  coinName: string;
  coinAbbrv: string;
  coinPrice: string;
};

export const CoinFaq: React.FC<Props> = (props) => {
  const { coinName } = props;

  const faqs = [
    {
      faq: `How is  the price of ${coinName} calculated?`,
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    },
    {
      faq: `Why is the price of ${coinName} different on Google?`,
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    },
    {
      faq: `Do I need to buy a whole ${coinName}?`,
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    },
    {
      faq: `How do I buy ${coinName}?`,
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    },
  ];

  return (
    <div className={styles.faqSection}>
      <h2>Frequently asked questions</h2>
      <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <div key={index.toString()} className={styles.accordion}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<FcExpand />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{faq.faq}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={styles.answer}>{faq.answer}</p>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};
