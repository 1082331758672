import React from "react";
import baseStyles from "styles/auth/login.module.css";

const Confirmation:React.FC = () => {
 
  return (
    <div className={baseStyles.authContainer}>
      <h2>Thanks for confirming your details!</h2>
      <p className={baseStyles.text}>Now please answer a few questions and add your proof of identity so we know it’s really you</p>
      <form className={baseStyles.form}>
       
        <div className={baseStyles.button}>
          <a href="/auth/more_details">Answer Questions</a>
        </div>

      </form>
    </div>
  );
};
export default Confirmation;
