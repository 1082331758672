import React, { useState } from 'react';
import styles from 'styles/dashboard/enterCardDetails.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import { PrimaryCheckbox } from 'components/input/PrimaryCheckbox';
import { SuccessfulModal } from 'components/modal/SuccessfulModal';
import { useNavigate, useOutletContext } from 'react-router-dom';

export const EnterCardDetails: React.FC = () => {
  const [ showSuccessfulModal, setShowSuccessfulModal ] = useState<boolean>(false);
  const showSidebar: boolean = useOutletContext();
  const navigate = useNavigate();

  const handleSuccessfulModal = () => {
    setShowSuccessfulModal(false);
    navigate("/dashboard/redeem_voucher");
  }
  
  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
        title={'Enter your card details'}
        buttonText={'Pay Now'}
        onclick={() => setShowSuccessfulModal(true)}
        buttonStyle={styles.button}
      >
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <div className={styles.input}>
              <PrimaryInput label="Name on Card" />
            </div>
            <div className={styles.input}>
              <PrimaryInput label="Card number" />
            </div>
            <div className={styles.halfInput}>
              <div className={styles.input2}>
                <PrimaryInput label="Expiry Date" />
              </div>
              <div className={styles.input2}>
                <PrimaryInput label="CV2" />
              </div>
            </div>
          </div>

          <div className={styles.checkbox}>
            <PrimaryCheckbox label={'Remember my card'} labelPosition={'start'} />
          </div>
        </div>
      </PrimaryPageLayout>

      <div className={styles.terms}>
        <p className={styles.termsText}>
          By adding your card details, you agree to our <span className={styles.colouredText}>Terms&nbsp;</span>and
          <span className={styles.colouredText}>&nbsp;Conditions</span>
        </p>
      </div>

      {showSuccessfulModal && <SuccessfulModal expandSidebar={showSidebar} onclose={handleSuccessfulModal} />
      }
    </div>
  );
};

export default EnterCardDetails;
