import React from "react";
import styles from "styles/overlay.module.css";
import loadingImage from "images/truexgoldLogo.svg"; // Import your loading image

const Overlay = () => {
    return (
        <div className={styles.overlay}>
            <img src={loadingImage} alt="Loading" className={styles.image} />
        </div>
    );
};

export default Overlay;
