import styles from "styles/exchange/hero.module.css";
import { Link } from 'react-router-dom';
import heroImg from 'images/exHeroImg.svg';
import 'animate.css';

export const Hero = () => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heading}>
        <h2 className="animate__animated animate__zoomIn">High-performance <br/> crypto exchange</h2>
        <p data-aos="fade-up" data-aos-duration="1500">Easy-to-use interface, industry-leading<br/> security, deep liquidity</p>
        <Link data-aos="fade-right" data-aos-duration="1500" to="/coins/bitcoin" className={styles.button}>View Exchange</Link>
      </div>

      <div className={`${styles.imgWrapper} ${"animate__animated animate__slideInUp"}`}>
        <img className={styles.img} src={heroImg} alt="friends-on-a-bench"/>
      </div>
    </div>
  );
};
