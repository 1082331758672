import React from "react";
import inputStyles from "styles/settings/inputStyles.module.css";
import baseStyles from "styles/settings/profile.module.css";
import {useOutletContext} from 'react-router-dom';
import {IoIosArrowForward} from "react-icons/io";
import {User} from "interfaces/User";

export const Settings:React.FC = () => {
    const user: User = useOutletContext<User>();
    if (!user) {
        return <div>Loading...</div>; // or any loading spinner
    }
  return (
    <div className={`${baseStyles.container} ${inputStyles.container}`}>
      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Name</label>
        <div className={inputStyles.inputField}>
          <input value={user.profile.full_name}   placeholder="Full name" className={inputStyles.input} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Email</label>
        <div className={inputStyles.inputField}>
          <input value={user.email}  placeholder="Email"  className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Mobile Number</label>
        <div className={inputStyles.inputField}>
          <input value={`${user.country} ${user.phone}`}   placeholder="+23480xxxxxxxx"  className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Country</label>
        <div className={inputStyles.inputField}>
          <input value={`${user.country_name}`}    placeholder="Country" className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Language</label>
        <div className={inputStyles.inputField}>
          <input value={user.profile.langauge}   placeholder="Language" className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
