import React, { ReactNode } from 'react';
import baseStyles from 'styles/modal/buyModal.module.css';
import styles from 'styles/modal/receiveModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { ModalListItem } from './ModalListItem';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';

type Props = {
  expandSidebar: boolean;
  onclose: () => void;
  onclickAddress: () => void;
  onclickCreate: () => void;
};

export const ReceiveModal: React.FC<Props> = (props) => {
  const { expandSidebar, onclose, onclickAddress, onclickCreate } = props;

  return (
    <div className={expandSidebar ? baseStyles.overlay2 : baseStyles.overlay}>
      <div className={`${baseStyles.container} ${styles.container}`}>
        <div className={baseStyles.closeIcon} onClick={onclose}>
          <IoCloseOutline className={baseStyles.icon} />
        </div>
        <div className={`${baseStyles.content} ${styles.content}`}>
          <h4 className={baseStyles.h4}>Choose a receive address</h4>
          <ModalListItem
            onclick={onclickAddress}
            iconWrapperColor='#f8a137'
            icon={<BsCurrencyBitcoin className={`${baseStyles.icon2} ${styles.rotateIcon}`} />}
            title={'TruexGold'}
            desc={'3gjskhfvdshvfivjjfjvsovvdbnvsnvopsdvnspojvnnvdnl47ryurrurhfiruf575789r4rj4944toit4u'}
            copyText={'Copy'}
            descStyle={styles.desc}
          />
          <ModalListItem
            onclick={onclickCreate}
            iconWrapperColor='#000'
            icon={<AiOutlinePlus size={30} className={baseStyles.icon2} />}
            desc={'Create receive address'}
            descStyle={styles.createText}
          />
        </div>
      </div>
    </div>
  );
};
