import { coingeckoAPI } from "api/coingeckoAPI";

export class chartService {
    public static async getChart(days:number) {
      try {
        return await coingeckoAPI.get(`/coins/bitcoin/market_chart?vs_currency=usd&days=${days}"`);
      } catch (e: any) {
        console.log(e.response);
        return e.response;
      }
    }
}
