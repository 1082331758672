import React from "react";
import styles from 'styles/coins/coinCard.module.css';


type Props = {
  coinName: string;
  coinAbbrv: string;
  coinSymbol: any;
  coinPrice: string;
};

export const CoinCard:React.FC<Props> = (props) => {
  const { coinName, coinAbbrv, coinSymbol } = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardWrapper}>
        <div className={styles.coinDesc}>
          <h5>{coinAbbrv}</h5>
          <div className={styles.coinSymbolWrapper}>
            <img className={styles.symbol} src={coinSymbol} alt={`${coinName}-symbol`} />
          </div>
        </div>

        <div className={styles.coinInfo}>
          <h5>{coinName}</h5>
        </div>
      </div>
    </div>
  );
};

export const ActiveCoinCard:React.FC<Props> = (props) => {
  const { coinName, coinAbbrv, coinSymbol, coinPrice } = props;

  return (
    <div className={styles.activeCardContainer}>
      <div className={styles.activeCardBg}>
        <div className={styles.activeCardWrapper}>
          <div className={styles.activeCoinDesc}>
            <h5>{coinAbbrv}</h5>
            <div className={styles.activeCoinSymbolWrapper}>
              <img className={styles.symbol} src={coinSymbol} alt={`${coinName}-symbol`} />
            </div>
          </div>

          <div className={styles.activeCoinInfo}>
            <h5>{coinName}</h5>
            <p>{coinPrice}</p>
          </div>
        </div>
      </div>
    </div>
  );
};


