import React, { ChangeEvent, useRef, useState } from 'react';
import baseStyles from 'styles/dashboard/enterCardDetails.module.css';
import pinStyles from 'styles/dashboard/transactionPin.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { SuccessfulModal } from 'components/modal/SuccessfulModal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import enterPin from 'images/dashboard/enterPin.svg';

export const TransactionPin: React.FC = () => {
  const navigate = useNavigate();
  const [showSuccessfulModal, setShowSuccessfulModal] = useState<boolean>(false);
  const showSidebar: boolean = useOutletContext();
  const handleSuccessfulModal = () => {
    setShowSuccessfulModal(false);
    navigate('/dashboard/home');
  };

  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
      icon={enterPin}
        title={'Enter your Truexgold Pin'}
        buttonText={'Confirm'}
        onclick={() => setShowSuccessfulModal(true)}
        linkText2={'Forgot PIN?'}
        linkPath2={'../auth/create_pin'}
      >
        <div style={{ marginTop: '20px' }} className={baseStyles.content}>
          <div className={`${baseStyles.inputWrapper} ${pinStyles.inputWrapper}`}>
            <div className={pinStyles.otpWrapper}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(ref) => (otpFields.current[index] = ref)}
                  className={pinStyles.input}
                />
              ))}
            </div>
          </div>
        </div>
      </PrimaryPageLayout>

      {showSuccessfulModal && (
        <SuccessfulModal
          expandSidebar={showSidebar}
          onclose={handleSuccessfulModal}
          text={`Your transaction was successful`}
        />
      )}
    </div>
  );
};

export default TransactionPin;
