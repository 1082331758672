import React from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/confirmDetails.module.css";

const ConfirmDetails: React.FC = () => {
  const details = [
    { id: 1, label: "First and middle names", value: "Jane Doe" },
    { id: 2, label: "Last name", value: "Jane" },
    { id: 3, label: "Date of birth", value: "2001-02-20" },
    { id: 4, label: "Sex", value: "Female" },
    {
      id: 5,
      label: "Are you a Nigerian citizen",
      value: "Yes, I am a citizen",
    },
    { id: 6, label: "National identity number(NIN)", value: "0123456789" },
  ];

  return (
    <div className={`${baseStyles.authContainer} ${styles.authContainer}`}>
      <h2>Are your details correct?</h2>
      <p className={baseStyles.text}>
        You cannot edit this later and incorrect details may cause delays
      </p>

    <form className={`${baseStyles.form} ${styles.form}`}>
      <div className={styles.detailsContainer}>
        {details.map((detail, index) => (
          <div key={index.toString()} className={styles.detailsWrapper}>
            <p className={styles.infoText}>
              {detail.label}:&nbsp;
              <span className={baseStyles.colouredText}>{detail.value}</span>
            </p>
          </div>
        ))}
      </div>

      <div className={styles.textWrapper}>
        <label className={styles.container}>
          All my details are correct and I acknowledge I have read and
          understood TruexGold’s &nbsp;
          <a href="/legal/terms" className={baseStyles.colouredText}>
            Terms of Use
          </a>
          <input type="checkbox" />
          <span className={styles.checkmark}></span>
        </label>
      </div>

      <div className={` ${styles.buttonContainer}`}>
        <a href="/auth/personal_details">Edit Info</a>
        <a href="/auth/confirmation">Next</a>
      </div>
      </form>
    </div>
  );
};

export default ConfirmDetails;
