import React from "react";
// import { Outlet } from "react-router-dom";

const P2P = () => {
  return (
    <p
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 100,
      }}
    >
      P2P Page
      {/* <Outlet /> */}
    </p>
  );
};
export default P2P;
