import React from 'react';
import styles from 'styles/dashboard/redeemVoucherIndex.module.css';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import { PrimaryInput } from 'components/input/PrimaryInput';
import emailIcon from 'images/dashboard/emailIcon.svg';

export const CheckEmail: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <PrimaryPageLayout
      icon={emailIcon}
        title={'Check your email'}
        desc={'Follow the link that was sent to exampl@gmail.com and authorize this action.'}
        linkText={'Confirm'}
        linkPath={'/dashboard/home'}
        linkText2={'Resend Link?'}
        linkPath2={'/dashboard/home'}
      >
      </PrimaryPageLayout>
    </div>
  );
};

export default CheckEmail;
