import React, { useState, useRef, ChangeEvent, KeyboardEvent } from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/verifyEmail.module.css";


const ForgotPasswordVerifyEmail = () => {
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };

  return (
    <div className={baseStyles.authContainer}>
      <h2>Check your Email</h2>
      <p className={baseStyles.text}>Enter the 4 digit code we sent to *********@gmail.com</p>
      <div className={baseStyles.form}>
        <div className={styles.otpWrapper}>
            {otp.map((digit, index) => (
                <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(ref) => (otpFields.current[index] = ref)}
                className={styles.input}
                />
            ))}
        </div>

        <div className={baseStyles.button}>
          <a href="/auth/create_new_password">Next</a>
        </div>

          <div className={styles.resend}>
            <button>Resend Email</button>
            <a href="/auth/verify_phone">Try Another Way</a>
          </div>
        </div>
    </div>
  );
};
export default ForgotPasswordVerifyEmail;
