import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from 'components/header/MainHeader';
import { Footer } from 'components/footer/Footer';
import Overlay from '../../Overlay';
import { userService } from 'services/userService';
import { User } from 'interfaces/User';
import { verifyUserContactInfo } from 'utils/verificationUtils';

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [useremail, setUseremail] = useState<string>('');
  const [userphone, setUserphone] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    const tokenExpiration = localStorage.getItem('TOKEN_EXPIRATION');
    const exemptRoutes = ['/auth/signup_security_verification', '/auth/signup_verify_email'];
    if (!exemptRoutes.includes(location.pathname)) {
      if (accessToken && tokenExpiration) {
        const expirationTime = parseInt(tokenExpiration);
        if (Date.now() < expirationTime) {
          const fetchUserData = async () => {
            try {
              setShowOverlay(true);
              const response = await userService.userDetails();
              const fetchedUser = response.data;
              setUser(fetchedUser);
              if (response.data && !response.data.is_pinned) {
                navigate('/auth/create_pin');
              } else {
                await verifyUserContactInfo({
                  user: fetchedUser,
                  setShowOverlay,
                  navigate,
                  setUseremail,
                  setUserphone,
                  setErrorText,
                });
              }
            } catch (err) {
              setShowOverlay(false);
            } finally {
              setShowOverlay(false);
            }
          };
          fetchUserData().then((r) => {});
        }
      }
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <Header />
      <div style={{ width: '100%' }}>
        <Outlet context={user} />
      </div>
      {showOverlay && <Overlay />}
      <Footer />
    </>
  );
};
export default MainLayout;
