import React from 'react';
import baseStyles from 'styles/modal/buyModal.module.css';
import styles from 'styles/modal/transactionReceiptModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import thumbsUp from 'images/dashboard/thumbsUp.svg';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/stringUtils';

type Props = {
  expandSidebar: boolean;
  onclose: () => void;
};

export const TransactionReceiptModal: React.FC<Props> = (props) => {
  const { expandSidebar, onclose } = props;
  const receipt = {
    id: 1,
    receiptNo: '023648y93rgu3gru3yr37r',
    date: dayjs().format('DD-MMM-YYYY HH:mm:ss A'),
    transactionId: 'p37937rhdufsoi0982',
    amount: 100000,
    status: 'Successful',
  };

  return (
    <div className={expandSidebar ? baseStyles.overlay2 : baseStyles.overlay}>
      <div className={baseStyles.container}>
        <div className={baseStyles.closeIcon} onClick={onclose}>
          <IoCloseOutline className={baseStyles.icon} />
        </div>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h4>Transaction Receipt</h4>
            <p>{dayjs().format('dddd, MMMM DD, YYYY')}</p>
          </div>
          <img src={thumbsUp} alt="thumbs-up" className={styles.success} />

          <div className={styles.receiptDetails}>
            <div className={styles.details}>
              <p>Receipt no:&nbsp;</p>
              <p>{receipt.receiptNo}</p>
            </div>
            <div className={styles.details}>
              <p>Transaction Date:&nbsp;</p>
              <p>{receipt.date}</p>
            </div>
            <div className={styles.details}>
              <p>Transaction ID:&nbsp;</p>
              <p>{receipt.transactionId}</p>
            </div>
            <div className={styles.details}>
              <p>Amount:&nbsp;{formatCurrency(receipt.amount)}</p>
            </div>
            <div className={styles.details}>
              <p>Status:&nbsp;{receipt.status}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
