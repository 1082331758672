import React, { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import styles from "styles/input/dropdownInput.module.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "#ffffff !important",
            padding: "10px !important",
            margin: "5px 0 3px",
          },
          "& .MuiInputLabel-root": {
            color: "#ffffff !important",
            fontFamily: `'Poppins', sans-serif`,
            letterSpacing: '-.3px'
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ffffff !important",
              height: '58px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          },
          },
          "&:hover fieldset": {
            borderColor: "#ffffff !important",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#ffffff !important",
          },
          '& .MuiSelect-icon': {
            color: '#ffffff',
          },
          '& .MuiSelect-nativeInput': {
            color: '#ffffff',
            height: '5px'
          }
        },
      },
    },
  },
});

type Props = {
  data: { id: string, value: string}[] | null;
  inputStyle?: any;
}

export const Dropdown: React.FC<Props & TextFieldProps> = ({ data, inputStyle, ...props }) => {

  return (
    <div className={styles.inputWrapper}>
      <ThemeProvider theme={theme}>
        <TextField
        // @ts-ignore
          variant="outlined"
          margin="normal"
          select
          inputProps={{
            autoComplete: "off",
          }}
          SelectProps={{
            MenuProps: {
              classes: { paper: `${styles.select} ${inputStyle}` }
            },
          }}
          fullWidth
          {...props}
          >
          { data && (
            <>
            {data.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {inputStyle && <div className={inputStyle}></div>}
              {option.value}
            </MenuItem>
          ))}
          </>
          )}
        </TextField>
      </ThemeProvider>
    </div>
  );
};
