import React, { useEffect, useState } from "react";
import { tickerService } from "services/tickerService";
import styles from "styles/header/priceHeader.module.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { formatTickerPrice } from "utils/stringUtils";
import { TickerData } from "interfaces/TickerData";

type Props = {
  expandSidebar?: boolean;
};

export const PricechartHeader: React.FC<Props> = ({ expandSidebar }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tickerData, setTickerData] = useState<TickerData | null>(null);

  useEffect(() => {
    getTickerData();
  }, []);

  const getTickerData = async () => {
    setLoading(true);
    const req = await tickerService.getTickerData();
    const res = req.data;
    setTickerData(res);
    setLoading(false);
  };

  const getArrowIcon = (change: number) => {
    if (change > 0) {
      return <IoMdArrowDropdown color={"red"} className={styles.arrowDown} />;
    } else if (change < 0) {
      return <IoMdArrowDropup color={"green"} className={styles.arrowUp} />;
    } else {
      return <IoMdArrowDropup color={"orange"} className={styles.arrowUp} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tickerContainer}>
        {tickerData && (
          <div className={styles.tickerWrapper}>
            <div className={styles.ticker}>
              <p>BTC ${formatTickerPrice(tickerData.bitcoin.usd)}</p>
              <p>{getArrowIcon(tickerData.bitcoin.usd_24h_change)}</p>
            </div>
            <div className={styles.ticker}>
              <p>ETH ${formatTickerPrice(tickerData.ethereum.usd)}</p>
              <p>{getArrowIcon(tickerData.ethereum.usd_24h_change)}</p>
            </div>
            <div className={styles.ticker}>
              <p>LTC ${formatTickerPrice(tickerData.litecoin.usd)}</p>
              <p>{getArrowIcon(tickerData.litecoin.usd_24h_change)}</p>
            </div>
            <div className={styles.ticker}>
              <p>DASH ${formatTickerPrice(tickerData.dash.usd)}</p>
              <p>{getArrowIcon(tickerData.dash.usd_24h_change)}</p>
            </div>
          </div>
        )}
        {!tickerData && (
          <p className={expandSidebar ? styles.p : styles.p2}>
            Loading Ticker Data...
          </p>
        )}
      </div>
    </div>
  );
};
