import React from "react";
import styles from 'styles/dashboard/help.module.css';
import merchantTransaction from 'images/dashboard/merchantTransactions.svg';

export const MerchantTransaction:React.FC = () => {
  return (
   <div className={styles.container}>
    <img src={merchantTransaction} alt="help" />
   </div>
  );
};

export default MerchantTransaction;