import React, { useState } from "react";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/personalDetails.module.css";
import { PrimaryInput } from "components/input/PrimaryInput";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown } from "components/input/Dropdown";

const PersonalDetails: React.FC = () => {
  const [characterCount, setCharacterCount] = useState(0);
  const maxCharacterCount = 11;
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setCharacterCount(newValue.length);
  };

  const genders = [
    { id: "1", value: "Male" },
    { id: "2", value: "Female" },
  ];

  const answers = [
    { id: "1", value: "Yes" },
    { id: "2", value: "No" },
  ];

  return (
    <div className={`${baseStyles.authContainer} ${styles.authContainer}`}>
      <h2>Let’s set you up in Nigeria</h2>

      <form className={`${baseStyles.form} ${styles.form}`}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <PrimaryInput label="First name and middle names" />
            </div>
            <p>Name as they appear on your ID</p>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <PrimaryInput label="Last name" />
            </div>
            <p>Name as they appear on your ID</p>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <PrimaryInput
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={genders} label="Sex" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <Dropdown data={answers} label="Are you a Nigerian citizen?" />
              <div className={styles.iconWrapper}>
                <IoIosArrowDown className={styles.icon} />
              </div>
            </div>
          </div>

          <div className={styles.detailsWrapper}>
            <div className={styles.inputWrapper}>
              <PrimaryInput
                label="National identity number"
                value={inputValue}
                inputProps={{
                  maxLength: 11,
                }}
                onChange={handleInputChange}
              />
            </div>
            <p>
              Your National Identity Number - {characterCount}/
              {maxCharacterCount}
            </p>
          </div>
        </div>

        <div className={`${baseStyles.button} ${styles.button}`}>
          <a href="/auth/confirm_details">Next</a>
        </div>
      </form>
    </div>
  );
};

export default PersonalDetails;
