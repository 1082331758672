import React from "react";
import TextField from "@mui/material/TextField/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../../styles/input/primaryInput.module.css";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "#ffffff !important",
            fontFamily: `'Poppins', sans-serif !important`,
            height: "40px",
            padding: "0 10px",
            margin: "5px 0 8px",
            flexDirection: 'row',
          },
          "& .MuiInputLabel-root": {
            color: "#ffffff !important",
            fontFamily: `'Poppins', sans-serif !important`,
            letterSpacing: '-.8px',
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              color: "#ffffff !important",
              borderColor: "#ffffff !important",
            },
            "& input": {
              color: "white",
              "&:-webkit-autofill": {
                // WebkitBoxShadow: "0 0 0 20px #070630 inset",
                // width: '90%',
                background: 'none !important',
                backgroundColor: '#00000000 !important'
              },
            },
          },
          "&:hover fieldset": {
            borderColor: "#ffffff !important",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#ffffff !important",
          },
          "&.MuiIconButton-root": {
            color: '#fff'
          }
        },
      },
    },
  },
});

type Props = {
  textFieldStyle?: any;
  textFieldWrapperStyle?: any
  textFieldVariant?: "outlined" | "standard" | "filled" | undefined
};

export const PrimaryInput: React.FC<Props & TextFieldProps> = ({
  textFieldStyle, textFieldWrapperStyle, textFieldVariant,
  ...props
}) => {
  return (
    <div className={textFieldWrapperStyle}>
      <ThemeProvider theme={theme}>
        <TextField
          variant={textFieldVariant ? textFieldVariant : "outlined"}
          margin="normal"
          fullWidth
          {...props}
          className={`${styles.input} ${textFieldStyle}`}
        />
      </ThemeProvider>
    </div>
  );
};
