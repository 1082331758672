import React, { useState } from 'react';
import styles from 'styles/dashboard/buyCoin.module.css';
import { PrimaryBg } from './PrimaryBg';
import { PrimaryInput } from 'components/input/PrimaryInput';

type Props = {
  coinName?: string;
  coinPrice: number;
};

export const BuyCoin: React.FC<Props> = (props) => {
  const { coinName, coinPrice } = props;
  const exchangeRate = coinPrice * 850;
  const [amount, setAmount] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleCoinValue = (amount: string) => {
    const coinValue = Number(amount) / exchangeRate;
    return coinValue.toFixed(8);
  };

  const handleBuy = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (!amount) {
      setError('Please input an amount');
      return;
    }
  }

  return (
    <form onSubmit={handleBuy} className={styles.container}>
      <PrimaryBg bgStyle={styles.buyWrapper}>
        <div className={styles.buyHeading}>
          <h5>How much {coinName ? coinName : 'Bitcoin'} do you want to buy?</h5>
          <div className={styles.optionsWrapper}>
            <div className={styles.options} onClick={() => setAmount('5000')}>
              <p>NGN 5,000</p>
            </div>
            <div className={styles.options} onClick={() => setAmount('10000')}>
              <p>NGN 10,000</p>
            </div>
            <div className={styles.options}>
              <p>Other</p>
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="amount" className={styles.label}>
              Amount
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              className={styles.input}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.converted}>
          <p>BTC {handleCoinValue(amount || '5000')}</p>
        </div>
      </PrimaryBg>
      <button type="submit" className={styles.button}>
        <p>Buy</p>
      </button>
    </form>
  );
};
