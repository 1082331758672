import React from 'react';
import { PrimaryPageLayout } from 'components/dashboard/PrimaryPageLayout';
import repeatBuy from 'images/dashboard/repeatBuy.svg';

export const RepeatBuyIndex: React.FC = () => {
  return (
    <div style={{ width: '100%'}}>
      <PrimaryPageLayout
        title={"Set up a repeat buy"}
        desc={'At your convenience, buy bitcoin repeatedly on a monthly, weekly, or daily basis. Regardless of how much the price fluctuates, you gradually increase your investment over time.'}
        icon={repeatBuy}
        linkText={'Next'}
        linkPath={'/dashboard/repeat_buy'}
        descStyle={'800px'}
      ></PrimaryPageLayout>
    </div>
  );
};

export default RepeatBuyIndex;
