import React from "react";
import styles from "../../styles/features/feature2.module.css";

export const Feature2 = () => {
  return (
    <section className={styles.feature2section}>
      <div data-aos="fade-right" data-aos-duration="1500" className={styles.feature2Image}></div>
      <div className={styles.feature2Text}>
        <h2 data-aos="fade-up" data-aos-duration="1000">Effortlessly buy multiple cryptocurrencies in one purchase</h2>
        <br />
        <br />
        <p data-aos="fade-up" data-aos-duration="1500">
          With TruexGold, you can acquire a variety of cryptocurrencies in a
          single transaction. <br />
          <br />
          Choose among TruexGold's most popular currencies, spread your
          investment evenly over all of the coins we provide, or find what works
          best for you and create your own, all for a lower price.
        </p>
        <br />
        <div data-aos="fade-up" data-aos-duration="1500" className={styles.controlTextWrapper}>
          <span className={styles.listStyleGreen}></span>
          <span className={styles.controlText}>You’re in control</span>
        </div>
      </div>
    </section>
  );
};
