import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import 'animate.css';
import styles from 'styles/dashboard/dashboardlayout.module.css';
import { PricechartHeader } from 'components/header/PricechartHeader';
import { DashboardSidebar } from 'components/dashboard/DashboardSidebar';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import { DashboardHeader } from 'components/dashboard/DashboardHeader';
import Overlay from '../../Overlay';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (!token) {
      navigate('/auth/login');
    } else {
      setAuthenticated(true);
    }
  }, [navigate]);

  if (!authenticated) {
    return null; // Render nothing until authentication status is determined
  }

  const handleExpandedSidebar = (newValue: boolean | ((prevState: boolean) => boolean)) => {
    setShowSidebar(newValue);
  };

  return (
    <div className={styles.container}>
      <div className={showSidebar ? styles.menu2 : styles.menu} onClick={() => setShowSidebar(!showSidebar)}>
        <IoMenuOutline className={showSidebar ? styles.menuIcon : styles.menuIcon2} />
        <IoCloseOutline className={showSidebar ? styles.closeIcon : styles.closeIcon2} />
      </div>

      <div className={showSidebar ? styles.sideNav : styles.sideNavClose}>
        <DashboardSidebar expandSidebar={showSidebar} handleExpandedSidebar={handleExpandedSidebar} />
      </div>

      <div className={showSidebar ? styles.pageContent : styles.pageContent2}>
        <div className={showSidebar ? styles.header : styles.header2}>
          <DashboardHeader expandSidebar={showSidebar} />
          <div className={styles.ticker}>
            <PricechartHeader expandSidebar={showSidebar} />
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.outletBg1}></div>
          <div className={styles.outletBg2}></div>
          <div className={styles.outlet}>
            <Outlet context={showSidebar} />
          </div>
        </div>
      </div>
      {showOverlay && <Overlay />}
    </div>
  );
};
export default DashboardLayout;
