import React, { useEffect, useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import { globalService } from 'services/globalService';
import { CountryDropdown } from 'components/input/CountryDropdown';
import { userService } from 'services/userService';
import Overlay from '../../Overlay';
import { User } from 'interfaces/User';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { verifyUserContactInfo } from 'utils/verificationUtils';

const CountrySetup: React.FC = () => {
  const [countries, setCountries] = useState<{ name: string; code: string }[]>([]);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const navigate = useNavigate();
  const user: User = useOutletContext<User>();
  const [useremail, setUseremail] = useState<string>('');
  const [userphone, setUserphone] = useState<string>('');
  const [usercountry, setUserCountry] = useState<string>('');

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await globalService.getCountries();
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);
  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCountry(event.target.value);
  };
  const handleSubmit = async () => {
    setErrorText(null);
    setShowOverlay(true);
    try {
      const response = await userService.updateUser({ ...user, country: usercountry });
      const resp = response?.data;
      if (resp && resp.data && resp.data.success) {
        if (user) {
          await verifyUserContactInfo({
            user,
            setShowOverlay,
            navigate,
            setUseremail,
            setUserphone,
            setErrorText,
          });
        } else {
          //notify.error('User does not exist');
        }
      } else if (resp.message) {
        setErrorText(resp?.message);
      } else {
        setErrorText('Error updating country details.');
      }
    } catch (error) {
      console.error('Failed to set country', error);
      setErrorText('Failed to set country');
    }

    setShowOverlay(false);
  };
  if (!user) {
    return <div className={baseStyles.authContainer}>Loading...</div>; // or any loading spinner
  }
  return (
    <div className={baseStyles.authContainer}>
      <h2>Country of Residence</h2>
      <p className={baseStyles.text}>
        You’ll need a&nbsp;<span className={baseStyles.colouredText}>valid address</span>&nbsp;for your country of
        residence
      </p>
      <form
        className={baseStyles.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <CountryDropdown data={countries} onChange={handleCountryChange} />
        {errorText && <p className={baseStyles.errorText}>{errorText}</p>}
        <div className={baseStyles.button} onClick={handleSubmit}>
          Next
        </div>
      </form>
      {showOverlay && <Overlay />}
    </div>
  );
};
export default CountrySetup;
