import React, { useEffect, useState } from 'react';
import styles from 'styles/dashboard/walletTab.module.css';
import { WalletCard } from './WalletCard';
import { formatCurrency } from 'utils/stringUtils';

type Props = {
  data: any;
  expandSidebar?: boolean;
};

export const WalletTab: React.FC<Props> = ({ data, expandSidebar }) => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const getPortfolioShare = (value: number) => {
    return (value * 100) / data.totalValue;
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1024); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tabData = [
    {
      id: 0,
      label: 'Total',
      component: (
        <WalletCard
          bgColor={'#CEA600'}
          textColor={'#8B7F50'}
          title={'Total Crypto'}
          changeBg={'#FBE591'}
          change={'+0.24'}
          graph={data.coins.btc.graph}
          value={formatCurrency(data.totalValue)}
          expandSidebar={expandSidebar}
          key={1}
        />
      ),
    },
    {
      id: 1,
      label: data.coins.btc.shortName,
      component: (
        <WalletCard
          bgColor={'#FDF9E5'}
          textColor={'#8B7F50'}
          changeBg={'#FBE591'}
          change={data.coins.btc.change}
          descColor={'#C1AF6A'}
          walletName={data.coins.btc.name}
          walletSymbol={data.coins.btc.shortName}
          graph={data.coins.btc.graph}
          value={formatCurrency(data.coins.btc.value)}
          share={getPortfolioShare(data.coins.btc.value)}
          expandSidebar={expandSidebar}
          key={2}
        />
      ),
    },
    {
      id: 2,
      label: data.coins.eth.shortName,
      component: (
        <WalletCard
          bgColor={'#DAF7E8'}
          textColor={'#5D9378'}
          changeBg={'#A9D1BD'}
          change={data.coins.eth.change}
          descColor={'#83A393'}
          walletName={data.coins.eth.name}
          walletSymbol={data.coins.eth.shortName}
          graph={data.coins.eth.graph}
          value={formatCurrency(data.coins.eth.value)}
          share={getPortfolioShare(data.coins.eth.value)}
          expandSidebar={expandSidebar}
          key={3}
        />
      ),
    },
    {
      id: 3,
      label: data.coins.ltc.shortName,
      component: (
        <WalletCard
          bgColor={'#FFE1E1'}
          textColor={'#A36B6B'}
          changeBg={'#FFB6B6'}
          change={data.coins.ltc.change}
          descColor={'#B78181'}
          walletName={data.coins.ltc.name}
          walletSymbol={data.coins.ltc.shortName}
          graph={data.coins.ltc.graph}
          value={formatCurrency(data.coins.ltc.value)}
          share={getPortfolioShare(data.coins.ltc.value)}
          expandSidebar={expandSidebar}
          key={4}
        />
      ),
    },
  ];

  return (
    <div className={styles.tabContainer}>
      {isSmallScreen ? (
        <div className={styles.tabWrapper}>
          {/* Tab buttons */}

          <div className={styles.tab}>
            {tabData.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={activeTab === index ? styles.activeTabLabel : styles.tabLabel}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Active tab content */}
          <div className={styles.tabContent}>{tabData[activeTab].component}</div>
        </div>
      ) : (
        <div className={expandSidebar ? styles.walletsWrapper2 : styles.walletsWrapper}>
          {/* {tabData.map((tab, index) => (
            <>{tab.component}</>
          ))} */}
          <WalletCard
            bgColor={'#CEA600'}
            textColor={'#8B7F50'}
            title={'Total Crypto'}
            changeBg={'#FBE591'}
            change={'+0.24'}
            graph={data.coins.btc.graph}
            value={formatCurrency(data.totalValue)}
            expandSidebar={expandSidebar}
          />
          <WalletCard
            bgColor={'#FDF9E5'}
            textColor={'#8B7F50'}
            changeBg={'#FBE591'}
            change={data.coins.btc.change}
            descColor={'#C1AF6A'}
            walletName={data.coins.btc.name}
            walletSymbol={data.coins.btc.shortName}
            graph={data.coins.btc.graph}
            value={formatCurrency(data.coins.btc.value)}
            share={getPortfolioShare(data.coins.btc.value)}
            expandSidebar={expandSidebar}
            key={2}
          />
          <WalletCard
            bgColor={'#DAF7E8'}
            textColor={'#5D9378'}
            changeBg={'#A9D1BD'}
            change={data.coins.eth.change}
            descColor={'#83A393'}
            walletName={data.coins.eth.name}
            walletSymbol={data.coins.eth.shortName}
            graph={data.coins.eth.graph}
            value={formatCurrency(data.coins.eth.value)}
            share={getPortfolioShare(data.coins.eth.value)}
            expandSidebar={expandSidebar}
            key={3}
          />
          <WalletCard
            bgColor={'#FFE1E1'}
            textColor={'#A36B6B'}
            changeBg={'#FFB6B6'}
            change={data.coins.ltc.change}
            descColor={'#B78181'}
            walletName={data.coins.ltc.name}
            walletSymbol={data.coins.ltc.shortName}
            graph={data.coins.ltc.graph}
            value={formatCurrency(data.coins.ltc.value)}
            share={getPortfolioShare(data.coins.ltc.value)}
            expandSidebar={expandSidebar}
            key={4}
          />
        </div>
      )}
    </div>
  );
};
