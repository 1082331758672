import React, {useRef} from "react";
import PropTypes from "prop-types";
import SnackbarComponent from "./SnackbarComponent";
import {SnackbarProvider} from "notistack";

function NotistackProvider({ children, ...props }) {
    const notistackRef = useRef();

    return (
        <SnackbarProvider
            {...props}
            ref={notistackRef}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            Components={{
                info: SnackbarComponent,
                success: SnackbarComponent,
                warning: SnackbarComponent,
                error: SnackbarComponent
            }}
            maxSnack={1}
            autoHideDuration={5000}
            dense={true}
            preventDuplicate
            variant="info">

            {children}
        </SnackbarProvider>
    );
}

NotistackProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default NotistackProvider;
