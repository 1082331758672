import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import baseStyles from 'styles/auth/login.module.css';
import axios from 'axios';
import { User } from 'interfaces/User';
import Overlay from '../../Overlay';
import { notify } from 'utils/index';
import { verifyUserContactInfo } from 'utils/verificationUtils';

//import '@hcaptcha/types';
declare global {
  interface Window {
    initGeetest: (userConfig: any, callback: Function) => void;
  }
}
const SignupSecurityVerification = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [challenge, setChallenge] = useState<string>('');
  const [gt, setGt] = useState<string>('');
  const [offline, setOffline] = useState<string>('');
  const [useremail, setUseremail] = useState<string>('');
  const [userphone, setUserphone] = useState<string>('');

  const user: User = useOutletContext<User>();

  useEffect(() => {
    if (!user) {
      // Handle the case where user is not available yet
      return;
    }
    // Load Geetest script
    const script = document.createElement('script');
    script.src = 'https://static.geetest.com/static/js/gt.0.4.9.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Initialize Geetest after script load
    script.onload = () => {
      axios
        .post('https://api.truexgold.app/api/v1/auth/geetest-register')
        .then((response) => {
          const challenge = response.data.challenge;
          const gt = response.data.gt;
          const success = response.data.success;
          setChallenge(challenge);
          setGt(gt);
          setOffline(offline);

          // Initialize Geetest widget
          const initGeetest = window.initGeetest;
          initGeetest(
            {
              lang: 'en',
              gt: gt,
              challenge: challenge,
              product: 'popup',
              offline: !success,
              new_captcha: true,
              width: '100%',
              https: true,
            },
            function (captchaObj: any) {
              captchaObj.appendTo('#geetest-captcha');
              captchaObj
                .onReady(function () {})
                .onSuccess(function () {
                  const result = captchaObj.getValidate();
                  validateGeetest(result);
                });
            }
          );
        })
        .catch((error) => {
          console.error('Error fetching Geetest challenge:', error);
          // Handle error
        });
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);
  const validateGeetest = (result: any) => {
    setShowOverlay(true);
    axios
      .post('https://api.truexgold.app/api/v1/auth/geetest-validate', {
        geetest_challenge: result.geetest_challenge,
        geetest_validate: result.geetest_validate,
        geetest_seccode: result.geetest_seccode,
      })
      .then((response) => {
        // Handle validation response
        const validationResult = response.data.result;
        if (validationResult === 'success') {
          console.log('User Object:', user);
          const fetchUserData = async () => {
            try {
              if (user) {
                await verifyUserContactInfo({
                  user,
                  setShowOverlay,
                  navigate,
                  setUseremail,
                  setUserphone,
                  setErrorText,
                });
              } else {
                //notify.error('User does not exist');
              }
            } catch (err) {
              // @ts-ignore
              console.log('error geetest done' + err.toString());
              console.log('error step reached');
              setShowOverlay(false);
            } finally {
              console.log('finally step reached');
            }
          };
          fetchUserData().then((r) => {});
        } else {
          // Geetest validation failed
          setError(true);
          setErrorText(response.data.msg);
          notify.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error('Error validating Geetest response:', error);
        setError(true);
        setErrorText('Error validating response. Please try again.');
      });
  };
  return (
    <div className={baseStyles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Security Verification'}</h2>
      <div id="geetest-captcha"></div>

      {showOverlay && <Overlay />}
    </div>
  );
};

export default SignupSecurityVerification;
