import { Hero } from "components/products/Exchange/Hero";
import { Pricechart } from "components/products/Exchange/Pricechart";
import { ExchangeFeatures } from "components/products/Exchange/ExchangeFeatures";
import { Benefits } from "components/products/Exchange/Benefits";
import styles from "styles/exchange/exchange.module.css";
import ScrollToTop from "components/ScrollToTop";

const Exchange = () => {
  return (
    <ScrollToTop>
      <section className={styles.main}>
        <Hero />
        <Pricechart />
        <ExchangeFeatures />
        <Benefits />
      </section>
    </ScrollToTop>
  );
};
export default Exchange;
