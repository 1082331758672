import React from "react";
import styles from "../../styles/features/feature5.module.css";

export const Feature5 = () => {
 const feature5Outline = [
   {
     heading: "Discover the world of cryptocurrency",
     desc: "Visit our Explore section to learn everything you need to know about cryptocurrency, no matter your level of experience - from basic guides to the most recent news and market updates.",
   },
   {
     heading: "Stay ahead of the market",
     desc: "Set up a Price Alert and you'll never miss a major market movement, with the notifications you want sent directly to your phone",
   },
   {
     heading: "Trade on the go",
     desc: "Deposit, withdraw, and trade 24/7 on our mobile apps for Android and iOS, so you can experience professional-level crypto exchange features on the move.",
   },
 ];

  return (
    <section className={styles.feature5section}>
      <div className={styles.feature5Heading}>
        <h2 data-aos="fade-down" data-aos-duration="1000">Stay Up to date</h2>
        <p data-aos="fade-up" data-aos-duration="500" className={styles.headingSubTextWhite}>
          What’s new? What’s popular? Who’s gaining? Keep up to date with all of
          your favorite coins' news in one location and trade instantly.
        </p>
      </div>
      <div className={styles.feature5TextWrapper}>
        <div className={styles.feature5container}>
          {feature5Outline.map((item, index) => (
            <div
            data-aos="fade-right" data-aos-duration="1000"
              key={index.toString()}
              className={styles.feature5articleWrapper}
            >
              <span className={styles.feature5listStyle}></span>
              <div className={styles.feature5Text}>
                <p className={styles.feature5articleHeading}>
                  {item.heading}: </p>
                <span className={styles.feature5articleDescText}> 
                    {item.desc}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className={styles.feature5Image}></div>
      </div>
    </section>
  );
};
