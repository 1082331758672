import React from "react";
import styles from "styles/company/team.module.css";


type Props = {
  image: any;
  name: string;
  role: string; 
  width?: string;
  height?: string;
  nameStyle?: any;
  roleStyle?: any; 
  imageStyle?: any;
  wrapperStyle?: any;
};

export const NameCard:React.FC<Props> = ({image, name, role, nameStyle, roleStyle, imageStyle, wrapperStyle}) => {
  return (
    <div className={wrapperStyle}>
      <img className={imageStyle} src={image} alt="Team-member" />
      <div className={styles.name}>
        <h4 className={nameStyle}>{name}</h4>
        <p className={roleStyle}>{role}</p>
      </div>
    </div>
  );
};
