import React from "react";
import inputStyles from "styles/settings/inputStyles.module.css";
import baseStyles from "styles/settings/profile.module.css";
import styles from "styles/settings/security.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsCaretDownFill } from "react-icons/bs";

export const  PhoneVerification:React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={`${baseStyles.container} ${styles.container}`}>
      <h3 className={inputStyles.h3}>Phone Verification</h3>

      <div className={styles.content}>
      <div className={inputStyles.inputContainer}>
          <div className={inputStyles.inputField}>
            <div className={styles.inputText}>
              <span className={styles.inputSpan}>
              <BsCaretDownFill size={10} />&nbsp;
              <input defaultValue={'+234'} className={`${inputStyles.input} ${styles.input}`} />
              </span> 
              <span>&nbsp;|&nbsp;</span>
              <input className={`${inputStyles.input} ${styles.input}`} />
            </div>
            <span style={{ border: '1px solid #fff', backgroundColor: '#ffffff00'}} className={styles.iconWrapper} onClick={() => navigate('/dashboard/settings/security/2FA/phone/otp')}><IoIosArrowForward className={inputStyles.icon} /></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;