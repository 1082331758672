import React from "react";
import { Link } from "react-router-dom";
import styles from "styles/header/dashboardHeader.module.css";
import "animate.css";
import { IoSettingsOutline } from "react-icons/io5";
import { BsFillBellFill } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";

type Props = {
  expandSidebar: boolean;
}

export const MerchantHeader: React.FC<Props> = ({ expandSidebar }) => {
  const notificationCount = 109;

  return (
    <header className={expandSidebar ? styles.header : styles.header2}>
      <div className={styles.menuWrapper}>
        <p className={styles.nameText}>TruexGold</p>
        <div className={styles.line}></div>
        <div className={styles.utilities}>
          <Link
            to={"/dashboard/notifications"}
            className={`${styles.bellWrapper} ${styles.link}`}
          >
            <BsFillBellFill className={styles.icon} />
            <div
              className={
                notificationCount > 100
                  ? styles.notification2
                  : styles.notification
              }
            >
              <p className={styles.text}>
                {notificationCount > 100 ? "100+" : notificationCount}
              </p>
            </div>
          </Link>
          <Link to={"/merchant/merchant_settings"} className={styles.link}>
            <IoSettingsOutline className={styles.icon} />
          </Link>
          <Link to={"/dashboard/logout"} className={styles.link}>
            <MdOutlineLogout className={styles.icon} />
          </Link>
        </div>
      </div>
    </header>
  );
};
