import React from "react";
import styles from "styles/rewards/rewardsEarn.module.css";
import "animate.css";
import { Link } from "react-router-dom";
import image from 'images/reward1.svg';

const RewardsEarn = () => {
  return (
    <section className={styles.wrapper}>
          <h3 className={`${styles.bold} ${"animate__animated animate__zoomIn"}`}>
          Get rewarded with TruexGold</h3>
          <div className={`${styles.rewardsImage} ${"animate__animated animate__slideInRight"}`}> 
            <img className={styles.img} src={image} alt="reward-earn" />
          </div>     
          <div className={styles.info}>   
            <p className="animate__animated animate__slideInUp">
              Earn Bitcoin by referring your friends and family to TruexGold, or redeeming TruexGold promo codes.
            </p>
            <p className="animate__animated animate__slideInUp">
              Check back regularly to see how you can earn more.
            </p>
          </div>
          <div className={styles.buttons}>
            <Link to="/products/rewards/rewards-grow" className={`${styles.colouredLink} ${"animate__animated animate__slideInRight"}`}>Next</Link>
            <div className={styles.dotWrapper}>
              <div style={{backgroundColor:'#070630'}} className={styles.dot}></div>
              <div className={styles.dot}></div>
            </div>
          </div>
    </section>
  );
};

export default RewardsEarn;
