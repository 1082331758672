import styles from "styles/company/company.module.css";
import { Overview } from "components/company/Overview";
import { Team } from "components/company/Team";
import { Divider } from "components/company/Divider";
import { Intro } from "components/company/Intro";
import ScrollToTop from "components/ScrollToTop";

const Company = () => {
  return (
    <ScrollToTop>
      <div className={styles.aboutWrapper}>
        <div className={styles.aboutHeader}>
          <Intro />
        </div>
        <main className={styles.main}>
          <Divider />
          <Overview />
          <div className={styles.divider}>
            <Divider />
          </div>
          <Team />
        </main>
      </div>
    </ScrollToTop>
  );
};
export default Company;
