import React from 'react';
import { useLocation } from 'react-router-dom';
import { TraderInfo } from 'components/dashboard/TraderInfo';

export const BuyId: React.FC = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <TraderInfo data={data} step1={'Transfer payment'} step2={'Waiting for crypto release'} step3={'Completed'}  />
  );
};

export default BuyId;
