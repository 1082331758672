import React from "react";
import inputStyles from "styles/settings/inputStyles.module.css";
import baseStyles from "styles/settings/profile.module.css";
import styles from "styles/settings/security.module.css";
import { IoIosArrowForward } from "react-icons/io";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const Security:React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={baseStyles.container}>
      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Password</label>
        <div className={`${inputStyles.inputField} ${styles.inputField}`}>
          <input type="text" value={`Last updated on ${dayjs().format('DD MMM YYYY')}`} className={inputStyles.input} />
        </div>
      </div>

      <div className={inputStyles.inputContainer} onClick={() => navigate('/dashboard/settings/security/2FA')}>
        <label className={inputStyles.label}>Two-factor authentication</label>
        <div className={inputStyles.inputField}>
          <input value={'Disabled'} className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>

      <div className={inputStyles.inputContainer}>
        <label className={inputStyles.label}>Social account</label>
        <div className={inputStyles.inputField}>
          <input value={'none'} className={inputStyles.input} />
          <IoIosArrowForward className={inputStyles.icon} />
        </div>
      </div>
    </div>
  );
};

export default Security;