import React from "react";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import baseStyles from "styles/auth/login.module.css";
import styles from "styles/auth/confirmPhone.module.css";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PrimaryInput } from "components/input/PrimaryInput";


const ConfirmPhone = () => {
  return (
    <div className={baseStyles.authContainer}>
      <h2>Confirm Phone</h2>
      <p className={baseStyles.text}>Enter the number you use to login to your Truexgold account</p>
      <form className={baseStyles.form}>
          <PrimaryInput 
          label="Phone" 
          type="phone" 
          />
        <div className={baseStyles.button}>
          <a href="/auth/verify_phone">Next</a>
        </div>

        {/* <div className={baseStyles.textWrapper}>
          <p>Don't have an account?<a href="/auth/signup" className={baseStyles.colouredText}> Sign up</a></p>
          <div className={baseStyles.emailLogin}>
            <FcGoogle />
            <a href="https:/www.gmail.com/login">Login using Gmail</a>
          </div>
        </div> */}
      </form>
    </div>
  );
};
export default ConfirmPhone;
