import React from "react";
import styles from "styles/settings/profile.module.css";
import {useOutletContext} from 'react-router-dom';
import {PrimaryInput} from "components/input/PrimaryInput";
import InputAdornment from '@mui/material/InputAdornment';
import {IoIosArrowDown} from "react-icons/io";
import {User} from 'interfaces/User';

export const Profile:React.FC = () => {

    const user: User = useOutletContext<User>();
    if (!user) {
        return <div>Loading...</div>; // or any loading spinner
    }
  return (
    <div className={styles.container}>
        <PrimaryInput value={user.username} placeholder="Username" />
        <PrimaryInput value={user.profile.full_name}  placeholder="Full name" />
      <PrimaryInput value={user.email}  placeholder="Email" />
        <PrimaryInput value={user.country_name}   placeholder="Country" InputProps={{endAdornment: <InputAdornment position="end"><IoIosArrowDown color={'#fff'} /></InputAdornment>,
          }} />
      <div className={styles.splitInput}>
        <PrimaryInput value={user.country} placeholder="+234"  textFieldWrapperStyle={styles.input1} />
        <PrimaryInput value={user.phone}  placeholder="080xxxxxxxx" textFieldWrapperStyle={styles.input2} />
      </div>

    </div>
  );
};

export default Profile;
