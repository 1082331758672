import styles from "styles/exchange/benefits.module.css";
import { BenefitCard } from 'components/products/Exchange/BenefitCard';
import coinsImg from 'images/coinsImg.svg';
import speedImg from 'images/speedImg.svg';
import rewardImg from 'images/rewardImg.svg';
import coinBox from 'images/coinBox.svg';

export const Benefits = () => {

  return (
    <div className={styles.benefitsContainer}>
      <BenefitCard 
        img={coinsImg} 
        heading={"Liquidity"} 
        desc={"Our liquidity assures the tightest spreads, allowing your crypto trades to be executed as near to the price you wish as possible, maximizing your earnings. Our low costs, high volume, and large number of active traders all contribute to our high liquidity."} imgAnim={"fade-right"} 
        headingAnim={"fade-up"} 
        descAnim={"fade-up"} 
        wrapperStyle={styles.directionRowReverse}
      />

      <BenefitCard 
        img={speedImg} 
        heading={"Speed"} 
        desc={"Trades can be executed in microseconds without affecting reliability or security. Our low-latency trading platform provides you with all of the tools you need to capitalize on market opportunities whenever and wherever you are."} 
        imgAnim={"fade-up"} 
        headingAnim={"fade-right"} 
        descAnim={"fade-up"} 
        wrapperStyle={styles.directionRow}
      />

      <BenefitCard 
        img={rewardImg} 
        heading={"Reward"} 
        desc={"Enjoy an easy-to-use interface designed for smooth, quick trading. TruexGold is designed for simplicity, with simple order placing and high-quality charting tools to give you the best chance of success - regardless of your level of experience."} 
        imgAnim={"fade-right"} 
        headingAnim={"fade-up"} 
        descAnim={"fade-up"} 
        wrapperStyle={styles.directionRowReverse}
      />

      <BenefitCard 
        img={coinBox} 
        heading={"Trade on the go"} 
        desc={"Deposit, withdraw, and trade 24/7 on our mobile apps for Android and iOS, so you can experience professional-level crypto exchange features on the move."} 
        imgAnim={"fade-up"} 
        headingAnim={"fade-right"} 
        descAnim={"fade-up"} 
        wrapperStyle={styles.directionRow}
      />
    </div>
  );
};
