import { coingeckoAPI } from "api/coingeckoAPI";

export class tickerService {
    public static async getTickerData() {
      try {
        return await coingeckoAPI.get('/simple/price?ids=bitcoin%2Cethereum%2Clitecoin%2Cdash&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=true&include_last_updated_at=true&precision=0');
      } catch (e: any) {
        console.log(e.response);
        return e.response;
      }
    }
}
