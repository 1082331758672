import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the user
type UserType = any;

// Define the context value type
type ContextValueType = {
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  token: string | null;
  setToken: (newToken: string | null) => void;
  notification: string | null;
  setNotification: (newNotification: string | null) => void;
};

const StateContext = createContext<ContextValueType | undefined>(undefined);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [token, setTokenState] = useState<string | null>(localStorage.getItem('ACCESS_TOKEN'));
  const [notification, setNotificationState] = useState<string | null>('');

  const setToken = (newToken: string | null) => {
    setTokenState(newToken);
    if (newToken) {
      localStorage.setItem('ACCESS_TOKEN', newToken);
    } else {
      localStorage.removeItem('ACCESS_TOKEN');
    }
  };

  const setNotification = (message: string | null) => {
    setNotificationState(message || ''); // Make sure to pass an empty string when message is null

    if (message) {
      setTimeout(() => {
        setNotificationState('');
      }, 5000);
    }
  };

  const contextValue: ContextValueType = {
    user,
    setUser,
    token,
    setToken,
    notification,
    setNotification,
  };

  return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};

export const useStateContext = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a ContextProvider');
  }
  return context;
};
