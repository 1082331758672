import React from 'react';
import styles from "styles/buttons/buttons.module.css";
import AppStoreIcon from "images/appStoreIcon.svg";
import PlayStoreIcon from "images/playstoreIcon.svg";

type Props ={
  containerStyle?: any;
  anim?: string;
  button1anim?: string;
  button2anim?: string;
  button1style?: any;
  button2style?: any;
};

export const DownloadButtons:React.FC<Props> = ({ containerStyle, anim, button1anim, button2anim, button1style, button2style  }) => {

    return (
      <div data-aos={anim} data-aos-duration="1500" className={`${styles.buttonContainer} ${containerStyle}`}>
        <div data-aos={button1anim} data-aos-duration="1500" className={`${styles.buttonWrapper} ${button1style}`}>
          <img className={styles.linkIcon} src={PlayStoreIcon} alt=""></img>
          <a className={styles.downloadLinks} href="https://play.google.com/store/games?pli=1" target="_blank" rel="noreferrer">
            <span className={styles.boldText}>GET IT ON </span>
            <br />
            GOOGLE PLAY
          </a>
        </div>
        <div data-aos={button2anim} data-aos-duration="1500" className={`${styles.buttonWrapper} ${button2style}`}>
          <img className={styles.linkIcon} src={AppStoreIcon} alt=""></img>
          <a className={styles.downloadLinks} href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
            Available on the <br />
            <span className={styles.boldText}>Apple Store</span>
          </a>
        </div>
      </div>
    );
};