import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import styles from 'styles/auth/verifyEmail.module.css';
import { User } from 'interfaces/User';
import { useNavigate, useOutletContext } from 'react-router-dom';

const maskPhone = (phone: string) => {
  // Remove all non-numeric characters, including the '+' symbol if present
  const digits = phone.replace(/\D/g, '');

  // Extract the last 10 digits in case the number has a country code
  const localPart = digits.length > 10 ? digits.slice(-10) : digits;

  // Ensure there are at least 4 digits to display; otherwise, return masked localPart
  if (localPart.length <= 4) {
    return '*'.repeat(localPart.length);
  }

  // Mask all but the last 4 digits
  return '*'.repeat(localPart.length - 4) + localPart.slice(-4);
};
const VerifyPhone = () => {
  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);
  const [phone, setPhone] = useState<string>('');
  const navigate = useNavigate();

  const user: User = useOutletContext<User>();
  useEffect(() => {
    if (!user) {
      // Handle the case where user is not available yet
      return;
    }
    // Attempt to get the user's phone number from the context
    if (user.phone) {
      setPhone(user.phone); // Set phone from user context
    } else {
      navigate('/auth/signup_verify_phone');
    }
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };

  return (
    <div className={baseStyles.authContainer}>
      <h2>Check your Text Message</h2>
      <p className={baseStyles.text}>Enter the 4 digit code we sent to {maskPhone(phone)} </p>
      <div className={baseStyles.form}>
        <div className={styles.otpWrapper}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => (otpFields.current[index] = ref)}
              className={styles.input}
            />
          ))}
        </div>

        <div className={baseStyles.button}>
          <a href="/auth/enter_pin">Next</a>
        </div>

        <div className={styles.resend}>
          <button>Resend SMS</button>
          {/*<button style={{ color: 'white' }}>Call me instead</button>*/}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
