import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import baseStyles from 'styles/auth/login.module.css';
import styles from 'styles/auth/verifyEmail.module.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { userService } from 'services/userService';
import { User } from 'interfaces/User';
import Overlay from '../../Overlay';

const CreatePin = () => {
  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const otpFields = useRef<any[]>([]);
  const navigate = useNavigate();
  const user: User = useOutletContext<User>();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [useremail, setUseremail] = useState<string>('');
  const [userphone, setUserphone] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return; // Allow only numerical digits
    }

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });

    if (value !== '') {
      // Move focus to the next input field
      if (index < otpFields.current.length - 1) {
        otpFields.current[index + 1].focus();
      }
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pastedData = e.clipboardData.getData('text');
    if (!/^\d+$/.test(pastedData)) {
      return; // Only allow numerical digits
    }

    const updatedOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }
    setOtp(updatedOtp);

    // Move focus to the last filled input field
    const lastFilledIndex = Math.min(index + pastedData.length - 1, otpFields.current.length - 1);
    otpFields.current[lastFilledIndex].focus();
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input field when Backspace is pressed
      otpFields.current[index - 1].focus();
    }
  };
  const handleSubmit = async () => {
    setErrorText(null);
    setShowOverlay(true);
    const pin = otp.join('');
    try {
      const response = await userService.setPin({ pin: pin });
      const resp = response?.data;
      if (resp && resp.data && resp.data.success) {
        navigate('/dashboard/home');
      } else if (resp.message) {
        setErrorText(resp?.message);
      } else {
        setErrorText('Error setting PIN.');
      }
    } catch (error) {
      console.error('Failed to set PIN', error);
      setErrorText('Failed to set PIN');
      // Handle error (e.g., show a notification to the user)
    }

    setShowOverlay(false);
  };
  if (!user) {
    return <div className={baseStyles.authContainer}>Loading...</div>;
  }
  return (
    <div className={baseStyles.authContainer}>
      <h2 style={{ color: errorText ? 'red' : 'white' }}>{errorText ? errorText : 'Create a pin'}</h2>
      <p className={baseStyles.text}>Create a 4 digit pin to enable easy access into your account.</p>
      <div className={baseStyles.form}>
        <div className={styles.otpWrapper}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(ref) => (otpFields.current[index] = ref)}
              className={styles.input}
            />
          ))}
        </div>

        <div className={baseStyles.button} onClick={handleSubmit}>
          Next
        </div>
      </div>
      {showOverlay && <Overlay />}
    </div>
  );
};
export default CreatePin;
